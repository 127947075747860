<template>
  <div>
    <no-data-component class="mt-7" v-if="images.length <= 0"
                       text="Det finns inga uppladdade bilder att visa"
                       icon="mdi-image-off-outline"></no-data-component>
    <v-container v-else fluid>
      <v-row>
        <v-col
            v-for="image in images"
            :key="image.id"
            :cols="columns"
        >
          <v-hover>
            <template v-slot:default="{ hover }">
              <v-card flat tile @click="imageSelected(image)">
                <v-img
                    :src="getImageUrl(image.fileData)"
                    aspect-ratio="1"
                    class="grey lighten-2"
                >
                  <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                  >
                    <v-slide-y-transition>
                      <v-icon
                          dark
                          size="70"
                          color="success lighten-1"
                          v-if="image.selected"
                      >
                        mdi-checkbox-marked-circle
                      </v-icon>
                    </v-slide-y-transition>
                  </v-row>
                </v-img>
                <v-fade-transition>
                  <v-overlay
                      v-if="hover"
                      absolute
                      color="accent-darken"
                      opacity="0.7"
                  >
                    <span><b>Välj</b></span>
                  </v-overlay>
                </v-fade-transition>
              </v-card>
            </template>
          </v-hover>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>

import NoDataComponent from "../global/NoDataComponent";
import api from "../../api/api";

export default {
  components: {NoDataComponent},
  props: {
    images: Array,
    multiple: Boolean,
    biggerTiles: Boolean
  },
  computed: {
    columns() {
      if(this.biggerTiles)
      {
        if (this.$vuetify.breakpoint.mdAndUp)
        {
          return this.$vuetify.breakpoint.xl ? 4 : (this.$vuetify.breakpoint.lg ? 4 : 5)
        }
        return 6
      }
      else
      {
        if (this.$vuetify.breakpoint.mdAndUp)
        {
          return this.$vuetify.breakpoint.xl ? 2 : (this.$vuetify.breakpoint.lg ? 3 : 4)
        }
        return 6
      }
    }
  },
  methods: {
    getImageUrl(file)
    {
      return api.BASE_URL + file.src
    },
    imageSelected(selectedImage)
    {
      if (!this.multiple)
      {
        this.images.forEach((image) =>
        {
          image.selected = false
        })
      }

      selectedImage.selected = !selectedImage.selected
      this.$emit("imageSelected", selectedImage)
    },
  }
}
</script>
