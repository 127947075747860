<template>
    <div class="text-center">
        <avatar-admin-component editable :size="128" :avatar-src="userAvatar" @avatarClick="fileUploadDialog = true" @avatarEditClick="imageEditDialog = true"></avatar-admin-component>
        <v-dialog v-model="fileUploadDialog" max-width="800px">
            <v-card>
                <v-card-title>
                    Ladda upp avatar
                    <v-spacer></v-spacer>
                    <v-icon color="primary">mdi-cloud-upload-outline</v-icon>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text class="mt-3">
                    <file-upload-component @fileUploaded="avatarUploaded"
                                           acceptedFileTypes="image/*" :max-filesize="10"></file-upload-component>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text @click="fileUploadDialog = false">Stäng</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <image-edit-dialog-component @imageSaved="avatarEdited" v-if="userAvatar && userAvatar.length > 0" :dialog.sync="imageEditDialog" :image="value.fileData"></image-edit-dialog-component>
    </div>
</template>

<script>
    import api from "../../api/api";
    import FileUploadComponent from "../global/file/FileUploadComponent";
    import AvatarAdminComponent from "../global/AvatarAdminComponent";
    import ImageEditDialogComponent from "../global/ImageEditDialogComponent";

    export default {
        components: {ImageEditDialogComponent, AvatarAdminComponent, FileUploadComponent},
        data: () => ({
            pickingAvatar: false,
            fileUploadDialog: false,
            imageEditDialog: false
        }),
        computed: {
            userAvatar()
            {
                if (this.value.fileData && this.value.fileData.src)
                {
                    return api.BASE_URL + this.value.fileData.src
                }
                return ""
            },
        },
        props: {
            value: Object
        },
        methods: {
            async saveUser()
            {
                this.$emit('saved', this.value)
            },
            avatarUploaded(response) // when image is uploaded we update the user object
            {
                let fileData = response[0]
                this.value.fileData = fileData
                this.fileUploadDialog = false
                this.saveUser()
            },
            avatarEdited(file) // when image is uploaded we update the user object
            {
                this.value.fileData = file
                this.saveUser()
            }
        }
    }
</script>
