<template>
    <div>
        <v-subheader class="pl-0">Grundläggande information</v-subheader>
        <v-text-field
                :counter="100"
                :rules="[v => !!v || 'Du måste ange ett namn']"
                @keyup.enter="saveBookingObject"
                autocomplete="off"
                autofocus
                class="mb-3"
                label="Namn"
                @keyup="unsaved = true"
                required
                data-cy="nameField"
                v-model="bookingObject.name"
        ></v-text-field>
        <v-text-field
                :rules="[v => !!v || 'Du måste ange en tid']"
                @keyup.enter="saveBookingObject"
                autocomplete="off"
                label="Standard incheckningstid"
                required
                @keyup="unsaved = true"
                type="time"
                v-model="bookingObject.defaultCheckInTime"
        ></v-text-field>
        <v-text-field
                :rules="[v => !!v || 'Du måste ange en tid']"
                @keyup.enter="saveBookingObject"
                autocomplete="off"
                class="mb-3"
                @keyup="unsaved = true"
                label="Standard utcheckningstid"
                required
                type="time"
                v-model="bookingObject.defaultCheckOutTime"
        ></v-text-field>
        <v-textarea
                auto-grow
                data-cy="descriptionField"
                label="Beskrivning"
                outlined
                v-model="bookingObject.description"
        ></v-textarea>
        <booking-object-attributes-component
                :booking-object.sync="bookingObject"
                :opened-index="addressContent"
                flat
                data-cy="attributesPanel"
                @change="unsaved = true"
                class="mb-3"
                outlined></booking-object-attributes-component>
        <address-component data-cy="bookingObjectAddressPanel"
                           @keyup="unsaved = true" :address.sync="bookingObject.address" :opened-index="addressContent" @keyUpEnter="saveBookingObject" flat
                           outlined></address-component>
    </div>
</template>

<script>
import AddressComponent from "../../global/AddressComponent";
import BookingObjectAttributesComponent from "../../attribute/BookingObjectAttributesComponent";

export default {
        components: {
            BookingObjectAttributesComponent, AddressComponent
        },
        data: () => ({}),
        props: {
            bookingObject: Object,
            addressContent: Number,
            loading: Boolean
        },
        computed: {
          unsaved: {
            get()
            {
              return this.unsavedChanges
            },
            set(val)
            {
              this.$emit('update:unsavedChanges', val)
            }
          },
        },
        methods: {
            saveBookingObject()
            {
                this.$emit('saveBookingObject')
            },
        }
    }
</script>

<style scoped>
</style>
