<template>
    <vue-dropzone :class="{'elevation-3': !flat}" ref="fileUploadDropzone" id="dropzone" :options="dropzoneOptions"
                  @vdropzone-removed-file="fileRemoved" @vdropzone-success="fileUploaded" @vdropzone-error="fileError"></vue-dropzone>
</template>

<script>
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import api from "../../../api/api";

export default {
        computed: {
            dropzoneOptions()
            {
                return {
                    url: api.BASE_URL + api.API + api.ENDPOINTS.FILES,
                    withCredentials: true,
                    thumbnailWidth: 150,
                    maxFilesize: this.maxFilesize,
                    maxFiles: this.maxfiles,
                    acceptedFiles: this.acceptedFileTypes,
                    dictDefaultMessage: "<i aria-hidden=\"true\" class=\"v-icon material-icons v-icon--center theme--light\">cloud_queue</i> <br> Klicka eller dra-och-släpp filer här för att ladda upp",
                    dictFallbackMessage: "Din webbläsare stödjer inte filuppladdning. Vänligen byt till en nyare webbläsare",
                    dictFallbackText: "Använd knappen för att ladda upp filer",
                    dictFileTooBig: "Den valda filen är för stor ({{filesize}}MB). Max filstorlek: {{maxFilesize}}MB.",
                    dictInvalidFileType: "Den valda filtypen stödjs inte",
                    dictCancelUpload: "Avbryt",
                    dictCancelUploadConfirmation: "Är du säker på att du vill avbryta uppladdningen?",
                    dictRemoveFile: "Ta bort",
                    dictResponseError: "Kunde inte ladda upp filen! Felkod: {{statusCode}}",
                    dictMaxFilesExceeded: "Du kan inte ladda upp fler filer. Max antal filer: {{maxFiles}}",
                    addRemoveLinks: this.addRemoveLinks
                }
            }
        },
        components: {
            vueDropzone: vue2Dropzone
        },
        methods: {
            fileUploaded(file, response)
            {
                this.$emit('fileUploaded', response, file)
            },
            fileRemoved(file)
            {
                this.$emit('fileRemoved', file)
            },
            fileError(file, message, xhr)
            {
                if (xhr.status === 401)
                { // Unauthorised
                    location.href = api.LOGIN_REDIRECT_URL
                }
                else
                {
                    this.$store.commit('setSnackbar', {
                        color: "error",
                        text: "Något gick fel när filen skulle laddas upp: " + message,
                        timeout: 8000
                    })
                }
            }
        },
        props: {
            maxfiles: Number,
            maxFilesize: Number,
            acceptedFileTypes: String,
            addRemoveLinks: Boolean,
            flat: Boolean,
        },
        name: "FileUploadComponent"
    }
</script>

<style scoped>
    .vue-dropzone:hover {
        background-color: #ebebef;
        cursor: pointer;
    }
</style>
