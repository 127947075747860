<template>
  <div>
    <v-card
        class="elevation-6"
    >
      <v-card-title class="secondary">
        <v-icon
            left
            dark
        >
          edit_attributes
        </v-icon>
        <span class="title textfont-weight-light white--text">Attribut</span>
        <v-spacer></v-spacer>
        <v-speed-dial
            v-model="addNewFabIsOpen"
            direction="bottom"
            transition="slide-y-transition"
        >
          <template v-slot:activator>
            <v-btn :class="{'highlight-pulse': attributeGroups.length <= 0 && !addNewFabIsOpen}" small fab
                   color="primary">
              <v-icon>{{ addNewFabIsOpen ? 'mdi-close' : 'mdi-plus' }}</v-icon>
            </v-btn>
          </template>
          <div>
            <v-tooltip left>
              <template v-slot:activator="{ on }">
                <v-btn
                    fab
                    dark
                    small
                    color="accent"
                    v-on="on"
                    @click="openAttributeDialog(0)"
                    class="force-on-top"
                >
                  <v-icon>edit_attributes</v-icon>
                </v-btn>
              </template>
              <span>Skapa ett nytt attribut</span>
            </v-tooltip>
          </div>
          <div>
            <v-tooltip left>
              <template v-slot:activator="{ on }">
                <v-btn
                    fab
                    dark
                    small
                    v-on="on"
                    color="accent-darken"
                    class="force-on-top"
                    @click="openAttributeGroupDialog(0)"
                >
                  <v-icon>create_new_folder</v-icon>
                </v-btn>
              </template>
              <span>Skapa en ny attributgrupp</span>
            </v-tooltip>
          </div>
        </v-speed-dial>
      </v-card-title>
      <v-card-text>
        <v-list two-line subheader v-if="attributeGroups.length > 0">
          <div v-for="group in attributeGroups" :key="group.name + '_' + group.id">
            <v-subheader>
              {{ group.name }}
              <v-spacer></v-spacer>
              <v-speed-dial
                  v-model="group.fab"
                  direction="left"
                  transition="slide-x-reverse-transition"
              >
                <template v-slot:activator>
                  <v-btn icon>
                    <v-icon color="grey">{{ group.fab ? 'close' : 'more_vert' }}</v-icon>
                  </v-btn>
                </template>
                <div>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <div v-on="on"> <!-- Wrapped in div so tooltip works when disabled -->
                        <v-btn
                            fab
                            small
                            color="error"
                            class="force-on-top"
                            @click="confirmDelete(group, true)"
                            :disabled="group.attributes.length > 0"
                        >
                          <v-icon>delete</v-icon>
                        </v-btn>
                      </div>
                    </template>
                    <span>{{
                        group.attributes.length > 0 ? "Endast tomma grupper kan raderas" : "Radera denna attributgrupp"
                      }}</span>
                  </v-tooltip>
                </div>
                <div>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn
                          fab
                          small
                          color="primary"
                          v-on="on"
                          class="force-on-top"
                          @click="openAttributeGroupDialog(group.id)"
                      >
                        <v-icon>edit</v-icon>
                      </v-btn>
                    </template>
                    <span>Redigera gruppen</span>
                  </v-tooltip>
                </div>
              </v-speed-dial>
            </v-subheader>
            <v-divider></v-divider>
            <v-list-item @click="itemClicked($event, attribute)" v-for="attribute in group.attributes"
                         :key="attribute.name + '_' + attribute.id">
              <v-list-item-avatar v-if="attribute.icon && attribute.icon.length > 0">
                <v-icon>{{ attribute.icon }}</v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>{{ attribute.name }}</v-list-item-title>
                <v-list-item-subtitle>{{ attribute.description }}
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action style="justify-content: center;" class="pl-1">
                <v-icon color="grey">{{ showEdit && selectedAttribute.id === attribute.id ? 'close' : 'more_horiz' }}</v-icon>
              </v-list-item-action>
            </v-list-item>
            <div v-if="group.attributes.length <= 0">
              <p class="grey--text mt-2 ml-2">Det finns inga attribut inlagda i denna grupp</p>
            </div>
          </div>
        </v-list>
        <no-data-component v-else text="Du har inte skapat några attribut än. Använd
            plus-knappen längst upp till höger för att göra det" icon="arrow_upward" pulse></no-data-component>
      </v-card-text>
    </v-card>
    <attribute-dialog-component @saved="getGroupedAttributes" :attribute-id="selectedAttributeId"
                                :dialog-is-open.sync="attributeDialog"></attribute-dialog-component>
    <attribute-group-dialog-component @saved="getGroupedAttributes" :attribute-group-id="selectedAttributeGroupId"
                                      :dialog-is-open.sync="attributeGroupDialog"></attribute-group-dialog-component>
    <attribute-edit-menu-component :attribute="selectedAttribute" :x-pos="menuX" :y-pos="menuY" :value.sync="showEdit" @edit="openAttributeDialog"
                                   @delete="confirmDelete"></attribute-edit-menu-component>
  </div>
</template>

<script>

import NoDataComponent from "../global/NoDataComponent";
import AttributeDialogComponent from "./AttributeDialogComponent";
import AttributeGroupDialogComponent from "./AttributeGroupDialogComponent";
import api from "../../api/api"
import AttributeEditMenuComponent from "@/components/attribute/AttributeEditMenuComponent";

export default {
  components: {AttributeEditMenuComponent, AttributeGroupDialogComponent, AttributeDialogComponent, NoDataComponent},
  data: () => ({
    attributeDialog: false,
    attributeGroupDialog: false,
    attributeGroups: [],
    selectedAttributeId: 0,
    selectedAttribute: null,
    selectedAttributeGroupId: 0,
    addNewFabIsOpen: false,
    showEdit: false,
    menuX: 0,
    menuY: 0,
  }),
  async mounted()
  {
    this.getGroupedAttributes();
  },
  methods: {
    openAttributeDialog(id)
    {
      this.selectedAttributeId = id
      this.attributeDialog = true
    },
    openAttributeGroupDialog(id)
    {
      this.selectedAttributeGroupId = id
      this.attributeGroupDialog = true
    },
    async getGroupedAttributes()
    {
      this.$store.commit('setAppIsLoading', true)
      let serverResponse = await api.getFromEndpoint(api.ENDPOINTS.ATTRIBUTEGROUPS)
      if (serverResponse)
      {
        this.attributeGroups = serverResponse
      }
      this.$store.commit('setAppIsLoading', false)
    },
    confirmDelete(item, isGroup)
    {
      this.$store.commit('setDialog', {
        active: true,
        closeBtnText: "Avbryt",
        actionBtnText: "Ta bort",
        title: "Bekräfta borttagning",
        text: "Är du säker på att du vill ta bort " + item.name + "?",
        actionClick: () =>
        {
          this.deleteRequest(item, isGroup)
        }
      })
    },
    async deleteRequest(item, isGroup)
    {
      this.$store.commit('setAppIsLoading', true)
      let endpoint = isGroup ? api.ENDPOINTS.ATTRIBUTEGROUPS : api.ENDPOINTS.ATTRIBUTES
      await api.deleteReqToEndpoint(endpoint + "/" + item.id)
      this.$store.commit('setAppIsLoading', false)
      this.$store.commit('setSnackbar', {color: "primary", text: `${item.name} borttagen`})
      this.getGroupedAttributes()
    },
    itemClicked(ev, item) {
      ev.preventDefault()
      this.selectedAttribute = item
      this.menuX = ev.clientX
      this.menuY = ev.clientY
      this.showEdit = false
      this.$nextTick(() => {
        window.setTimeout(() => {
          this.showEdit = true
        }, 30)
      })
    },
  }
}
</script>

