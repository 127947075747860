import { render, staticRenderFns } from "./CustomerAutocompleteComponent.vue?vue&type=template&id=323b7897&"
import script from "./CustomerAutocompleteComponent.vue?vue&type=script&lang=js&"
export * from "./CustomerAutocompleteComponent.vue?vue&type=script&lang=js&"
import style0 from "./CustomerAutocompleteComponent.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VChip } from 'vuetify/lib/components/VChip';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
installComponents(component, {VAutocomplete,VBtn,VChip,VDialog,VIcon,VListItemAvatar,VListItemContent,VListItemSubtitle,VListItemTitle})
