<template>
    <v-container fluid>
        <v-layout justify-center>
            <v-flex lg6 xs12>
                <transition appear mode="out-in" name="slide-x-transition">
                    <uthyrd-loader-component loading-text="Laddar..."
                                             v-if="loading"></uthyrd-loader-component>
                    <page-not-found-component :show-home-btn="false" v-else-if="customer && customer.detailsProvidedByCustomer"></page-not-found-component>
                    <v-alert class="elevation-6" prominent type="error" v-else-if="error.msg">
                        <h3>Något gick fel</h3>
                        <p>{{error.msg}}</p>
                    </v-alert>
                    <v-alert class="elevation-6" prominent type="success" v-else-if="success.msg">
                        <template v-slot:prepend>
                            <v-btn class="mr-6 ml-2 highlight-pulse" icon>
                                <v-icon x-large>mdi-check-circle</v-icon>
                            </v-btn>
                        </template>
                        <h3>{{success.title}}</h3>
                        <p>{{success.msg}}</p>
                    </v-alert>
                    <div v-else-if="customer">
                        <v-card
                                class="mx-auto elevation-10"
                                :loading="saving"
                                :disabled="saving"
                        >
                            <v-toolbar flat>
                                <logo-component class="ml-2" color="#8380b6" height="40px"
                                                width="40px"></logo-component>
                            </v-toolbar>
                            <v-card-title>{{title}}</v-card-title>
                            <v-card-subtitle class="pt-3 mb-3">
                                Ange dina kontaktuppgifter nedan
                            </v-card-subtitle>
                            <v-card-text style="min-height: 480px;">
                                <v-form ref="form" v-model="validForm">
                                    <external-customer-component all-inputs :init-customer="false"
                                                                 :user-account-id="userAccount.id"
                                                                 :value.sync="customer"
                                                                 flat
                                                                 skip-email-check
                                                                 v-if="userAccount && customer"></external-customer-component>
                                </v-form>
                            </v-card-text>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn
                                        @click="saveCustomerDetails"
                                        color="primary"
                                        outlined
                                >
                                    Spara
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                        <div class="text-center">
                            <p class="mt-3 grey--text subtitle-2 text--darken-1">&copy; Uthyrd 2021</p>
                            <div>
                                <v-icon class="mr-1" color="grey" small>mdi-email-outline</v-icon>
                                <a :href="`mailto:${userAccount.applicationEmailAddress}`" class="grey--text caption"
                                   v-if="userAccount">{{userAccount.applicationEmailAddress}}</a>
                            </div>
                            <div>
                                <v-icon class="mr-1" color="grey" small>mdi-web</v-icon>
                                <a class="grey--text caption" href="https://uthyrd.se">Uthyrd.se</a>
                            </div>
                        </div>
                    </div>
                </transition>
            </v-flex>
        </v-layout>
        <v-snackbar
                :color="snackbar.color"
                :timeout="snackbar.timeout"
                v-model="snackbar.active"
        >
            <p>
                <span class="font-weight-bold" v-if="snackbar.title">{{snackbar.title}} <br></span>
                {{ snackbar.text }}
            </p>
            <template v-slot:action>
                <v-btn
                        @click="snackbar.active = false"
                        dark
                        text
                >
                    Stäng
                </v-btn>
            </template>
        </v-snackbar>
    </v-container>
</template>

<script>
import UthyrdLoaderComponent from "../../components/global/UthyrdLoaderComponent";
import {getQueryParamFromUrl} from "../../util/functions";
import LogoComponent from "../../components/global/LogoComponent";
import Axios from "axios";
import api from "../../api/api";
import ExternalCustomerComponent from "../../components/externalbooking/ExternalCustomerComponent";
import PageNotFoundComponent from "../../components/errors/PageNotFoundComponent";

export default {
        components: {
            PageNotFoundComponent,
            ExternalCustomerComponent,
            LogoComponent, UthyrdLoaderComponent
        },
        data: () => ({
            loading: false,
            saving: false,
            userAccount: null,
            customer: null,
            validForm: false,
            error: {},
            success: {},
            uuid: "",
            snackbar: {
                active: false,
                title: "",
                text: "",
                color: "error",
                timeout: 8000
            }
        }),
        computed: {
            title()
            {
                if (this.customer && this.customer.firstname)
                {
                    return "Hej " + this.customer.firstname + "!"
                }
                return "Hej!"
            }
        },
        async mounted()
        {
            let uuid = getQueryParamFromUrl("uuid")
            this.uuid = uuid
            if (!uuid)
            {
                this.error.msg = "Ogiltig URL"
                return
            }
            // await since we want loading to be true until both are completed
            this.loading = true
            await this.getCustomerFromUuid(uuid)
            this.loading = false
        },
        methods: {
            async getCustomerFromUuid(uuid)
            {
                try
                {
                    let response = await Axios.get(api.BASE_URL + api.API + api.ENDPOINTS.CUSTOMERS + "/" + api.ENDPOINTS.UUID + "/" + uuid,
                        {
                            headers: {
                                [api.HEADERS.ContentType]: [api.HEADERS.ApplicationJson]
                            }
                        })
                    let data = response.data
                    if (data)
                    {
                        this.customer = data.customer
                        this.userAccount = data.userAccount
                    }
                }
                catch (error)
                {
                    // Treat network errors without responses as 500s.
                    const status = error.response ? error.response.status : 500
                    if (status === 401 || status === 403 || status === 404)
                    {
                        // Unauthorised or forbidden
                        this.error.msg = "Ogiltig URL"
                    }
                    else
                    {
                        this.error.msg = "Kunde inte kommunicera med servern. Vänligen kontrollera dina nätverksinställningar"
                    }
                }
            },
            async saveCustomerDetails()
            {
                this.$refs.form.validate()
                if (this.validForm)
                {
                    this.saving = true

                    let response = await api.putToEndpoint(api.ENDPOINTS.CUSTOMERS + "/" + api.ENDPOINTS.UUID + "/" + this.customer.uuid, this.customer)
                    if(response)
                    {
                        this.showSuccess(this.customer)
                    }
                    else {
                        this.error.msg = "Kunde inte spara uppgifterna. Vänligen ladda om sidan och försök igen. Om problemet kvarstår kontakta oss på support@uthyrd.se"
                    }
                    this.saving = false

                }
            },
            showSuccess(customer)
            {
                this.success = {
                    title: "Uppgifter sparade",
                    msg: "Tack " + customer.firstname + "! Dina uppgifter har nu sparats."
                }
            },
            setSnackbar(title, text, color)
            {
                // hide snackbar incase it is already showing
                this.snackbar.active = false

                // then we set the snackbar with proper values after a small delay
                window.setTimeout(() =>
                {
                    this.snackbar = {active: true, color: color, timeout: 8000, title: title, text: text}
                }, 100)
            }
        }
    }
</script>

<style scoped>
    .align-middle {
        vertical-align: middle;
    }

    .toolbar-logo {
        position: absolute;
        right: 20px;
    }
</style>
