<template>
  <div>
    <v-card flat :loading="loading" :disabled="loading">
      <v-toolbar flat>
        <v-toolbar-title>Webbplatser</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-tooltip bottom>
          <template v-slot:activator="{ on:onTooltip }">
            <v-menu u v-model="addNew" bottom left nudge-bottom="5" offset-y transition="slide-y-transition">
              <template v-slot:activator="{ on:onMenu }">
                <v-btn v-on="{ ...onMenu, ...onTooltip }"
                       color="primary"
                       outlined
                >
                  <v-icon>{{ addNew ? 'mdi-close' : 'mdi-plus' }}</v-icon>
                </v-btn>
              </template>
              <v-list class="pa-0">
                <v-list-item @click="templateSelectDialog = true">
                  <v-list-item-title>Skapa ny från mall</v-list-item-title>
                  <v-list-item-action>
                    <v-icon color="primary">mdi-content-duplicate</v-icon>
                  </v-list-item-action>
                </v-list-item>
                <v-divider></v-divider>
                <v-list-item @click="newWebsite">
                  <v-list-item-title>Skapa ny från grunden</v-list-item-title>
                  <v-list-item-action>
                    <v-icon color="accent">mdi-view-grid-plus-outline</v-icon>
                  </v-list-item-action>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
          <span>Skapa en ny presentationssida</span>
        </v-tooltip>
      </v-toolbar>
      <v-divider class="mb-3"></v-divider>
      <div v-if="loading" class="my-6 text-center">
        Laddar...
      </div>
      <v-list v-else-if="websites.length > 0" two-line>
        <website-list-item-component @refresh="getWebsitesFromBookingObject" @duplicate="duplicateWebsite"
                                     :booking-object="bookingObject" :key="'website_' + website.id"
                                     v-for="website in websites" :website="website"></website-list-item-component>
      </v-list>
      <no-data-component class="mt-10" v-else icon="arrow_upward" pulse text="Du har inte skapat några presentationssidor än. Använd
            plus-knappen längst upp till höger för att göra det"></no-data-component>
    </v-card>
    <new-website-dialog-component
        :from-template="fromTemplate"
        :booking-object="bookingObject"
        :copy-from="copyFrom"
        :dialog.sync="newWebsiteDialog">
    </new-website-dialog-component>
    <website-template-picker-dialog-component
        @submit="duplicateWebsite"
        :dialog.sync="templateSelectDialog">
    </website-template-picker-dialog-component>
  </div>
</template>

<script>

import api from "../../../../api/api";
import NoDataComponent from "@/components/global/NoDataComponent";
import WebsiteListItemComponent from "@/components/website/WebsiteListItemComponent";
import NewWebsiteDialogComponent from "@/components/website/NewWebsiteDialogComponent";
import WebsiteTemplatePickerDialogComponent from "@/components/website/template/WebsiteTemplatePickerDialogComponent";

export default {
  components: {
    WebsiteTemplatePickerDialogComponent,
    NewWebsiteDialogComponent, WebsiteListItemComponent, NoDataComponent
  },
  data: () => ({
    websites: [],
    loading: false,
    addNew: false,
    newWebsiteDialog: false,
    templateSelectDialog: false,
    copyFrom: null,
    fromTemplate: false
  }),
  props: {
    bookingObject: Object,
  },
  computed: {},
  async mounted() {
    this.getWebsitesFromBookingObject()
  },
  methods: {
    async getWebsitesFromBookingObject() {
      this.loading = true
      let websites = await api.getFromEndpoint(api.ENDPOINTS.BOOKINGOBJECTS + "/" + this.bookingObject.id + "/" + api.ENDPOINTS.WEBSITES)
      if (websites) {
        this.websites = websites
      }
      this.loading = false
    },
    newWebsite() {
      this.fromTemplate = false
      this.copyFrom = null
      this.newWebsiteDialog = true
    },
    duplicateWebsite(websiteId, fromTemplate) {
      this.copyFrom = websiteId
      this.fromTemplate = fromTemplate
      this.newWebsiteDialog = true
    }
  }
}
</script>
