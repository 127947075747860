<template>
    <v-slide-x-transition mode="out-in" appear>
        <v-container fluid fill-height v-if="tokenIsValid">
            <v-layout align-center justify-center>
                <v-flex xs12 sm8 md4>
                    <v-slide-x-transition mode="out-in" appear>
                        <v-card key="passwordUpdated" v-if="passwordUpdated" class="elevation-12">
                            <v-toolbar dark color="primary">
                                <v-toolbar-title>
                                    <logo-component class="align-middle" color="white" width="36px"
                                                    height="36px"></logo-component>
                                </v-toolbar-title>
                            </v-toolbar>
                            <v-card-text>
                                <v-alert
                                        :value="true"
                                        color="success"
                                        icon="check_circle"
                                        outlined
                                >
                                    Ditt lösenord har nu uppdaterats
                                </v-alert>
                            </v-card-text>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="primary" href="/" :loading="loading">Till Uthyrd
                                    <v-icon right>send</v-icon>
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                        <v-card key="updatePassword" v-else class="elevation-12">
                            <v-toolbar dark color="primary">
                                <v-toolbar-title>
                                    <logo-component class="align-middle" color="white" width="36px"
                                                    height="36px"></logo-component>
                                </v-toolbar-title>
                            </v-toolbar>
                            <v-card-text>
                                <v-form @submit.prevent>
                                    <v-text-field @keyup.enter="updatePassword" v-model="password" id="password"
                                                  prepend-icon="lock"
                                                  required
                                                  autocomplete="current-password"
                                                  name="password" label="Nytt lösenord"
                                                  type="password"></v-text-field>
                                    <v-text-field @keyup.enter="updatePassword" v-model="passwordRepeat"
                                                  id="passwordConfirm"
                                                  prepend-icon="lock"
                                                  autocomplete="current-password"
                                                  required
                                                  name="passwordRepeat" label="Bekräfta lösenordet"
                                                  type="password"></v-text-field>
                                    <p class="red--text text-center">
                                        {{message}}</p>
                                </v-form>
                            </v-card-text>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="primary" @click="updatePassword" :loading="loading">Uppdatera lösenord
                                    <v-icon right>send</v-icon>
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-slide-x-transition>
                </v-flex>
            </v-layout>
        </v-container>
        <page-not-found-component routerless v-else-if="!loading"></page-not-found-component>
    </v-slide-x-transition>
</template>

<script>
    import LogoComponent from "../../components/global/LogoComponent";
    import api from "../../api/api";
    import Axios from "axios";
    import PageNotFoundComponent from "../../components/errors/PageNotFoundComponent";

    export default {
        components: {PageNotFoundComponent, LogoComponent},
        data: () => ({
            passwordUpdated: false,
            tokenIsValid: false,
            loading: true,
            error: false,
            message: "",
            password: "",
            passwordRepeat: ""
        }),
        async mounted()
        {
            // Check to see if the token is valid
            // custom API call here to handle unauthorized
            try
            {
                let response = await Axios.get(api.BASE_URL + api.API + api.ENDPOINTS.ACCOUNTLOGINS + "/" + this.authToken)
                let authResponse = response.data
                if (authResponse)
                {
                    this.tokenIsValid = true
                }
            }
            catch (error)
            {
                // Treat network errors without responses as 500s.
                const status = error.response ? error.response.status : 500
                if (status === 401)
                { // Unauthorised
                    this.tokenIsValid = false
                }
                else
                {
                    this.message = "Kunde inte kommunicera med servern. Vänligen kontrollera dina nätverksinställningar"
                }
            }

            this.loading = false
        },
        computed: {
            authToken()
            {
                return this.$route.params.token
            },
            passwordsMatch()
            {
                return this.password === this.passwordRepeat
            }
        },
        methods: {
            async updatePassword()
            {
                if (!this.passwordsMatch)
                {
                    this.message = "Lösenorden matchar inte. Ange samma lösenord i båda textfälten."
                    return
                }
                this.loading = true
                this.message = ""
                // custom API call here to handle unauthorized when email or password is wrong
                try
                {
                    let response = await Axios.put(api.BASE_URL + api.API + api.ENDPOINTS.ACCOUNTLOGINS + "/" + this.authToken, {"password": this.password})
                    let authResponse = response.data
                    if (authResponse)
                    {
                        this.passwordUpdated = true
                    }
                }
                catch (error)
                {
                    this.message = "Kunde inte uppdatera ditt lösenord. Vänligen kontrollera att lösenordet är minst 6 tecken och att länken fortfarande är giltig."
                }
                this.loading = false
            }
        }
    }
</script>

<style scoped>
    .align-middle {
        vertical-align: middle;
    }
</style>
