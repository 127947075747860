<template>
  <v-dialog persistent v-model="dialogProp" scrollable max-width="800px">
    <v-card :loading="loading">
      <v-card-title>
        {{ multiple ? 'Välj filer' : 'Välj fil' }}
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <no-data-component class="mt-7" v-if="files.length <= 0"
                           text="Det finns inga uppladdade filer att visa"
                           icon="mdi-folder-alert-outline"></no-data-component>
        <v-container v-else fluid>
          <v-list>
            <v-list-item-group v-model="selectedFiles" value="selected" :multiple="multiple"
                               color="primary">
              <file-list-item-component v-for="file in files"
                                        :key="file.id"
                                        :file="file"
                                        @fileClicked="fileClicked"
              ></file-list-item-component>
            </v-list-item-group>
          </v-list>
        </v-container>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="dialogProp = false">Stäng</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import FileListItemComponent from "./FileListItemComponent";
import NoDataComponent from "../NoDataComponent";
import api from "../../../api/api";
import filetypes from "../../../models/filetypes";

export default {
  components: {FileListItemComponent, NoDataComponent},
  data: () => ({
    fileTypes: filetypes.TYPES,
    selectedFiles: []
  }),
  props: {
    files: Array,
    loading: Boolean,
    dialog: Boolean,
    closeOnClick: Boolean,
    multiple: Boolean,
    value: [Array, Object],
  },
  computed: {
    dialogProp: {
      get: function ()
      {
        return this.dialog;
      },
      set: function (newValue)
      {
        this.$emit('update:dialog', newValue)
      }
    },
  },
  methods: {
    getFileUrl(file)
    {
      return api.BASE_URL + file.src
    },
    fileClicked(selectedFile)
    {
      if (!this.multiple)// if only one can be chosen
      {
        // set the value
        this.value = selectedFile
      }
      selectedFile.selected = !selectedFile.selected

      if (selectedFile.selected) // file was just selected
      {
        // add to value array
        this.value.push(selectedFile)
      }
      else
      {
        // if there is an id we try to find index to splice with that
        let index
        if (selectedFile.id)
        {
          index = this.value.findIndex((item) => item.id === selectedFile.id)
        }
        else // otherwise we try to match the name
        {
          index = this.value.findIndex((item) => item.name === selectedFile.name)
        }
        this.value.splice(index, 1)
      }
      if (this.closeOnClick)
      {
        this.dialogProp = false
      }
    },
    mapSelected(selectedIds)
    {
      this.selectedFiles = []
      selectedIds.forEach((id) =>
      {
        let index = this.files.findIndex((file) => file.id === id)
        if (index >= 0)
        {
          this.selectedFiles.push(index)
        }
      })
    }
  }
}
</script>
