<template>
    <v-container fill-height fluid>
        <v-layout align-center justify-center>
            <v-flex xl4 md6 sm8 xs12>
                <v-slide-x-transition appear mode="out-in">
                    <v-alert class="elevation-6" prominent type="error" v-if="unconfirmedEmail">
                        <h3>E-postadress ej bekräftad</h3>
                        <p>Du har ännu inte bekräftat din e-postadress. Ett mail har skickats med en länk som du kan
                            använda för att göra detta. Hittar du inte mailet? Kolla din skräppost</p>
                        <v-btn @click="unconfirmedEmail = false" class="float-right" color="white" outlined>Tillbaka
                            till inloggning
                        </v-btn>
                    </v-alert>
                    <v-card class="elevation-12" key="login" v-else-if="!forgotPassword">
                        <v-toolbar color="primary" dark>
                            <v-toolbar-title>
                                <span>Logga in</span>
                                <span @click="location.href = 'https://uthyrd.se'" class="toolbar-logo">
                                    <logo-component class="align-middle" color="white" height="36px"
                                                    width="36px"></logo-component>
                                </span>
                            </v-toolbar-title>
                        </v-toolbar>
                        <v-card-text>
                            <v-form
                                    @submit.prevent
                                    ref="form"
                                    v-model="validForm"
                            >
                                <v-text-field :rules="emailRules" @keyup.enter="doLogin" autofocus
                                              label="E-post"
                                              name="email"
                                              data-cy="emailField"
                                              prepend-icon="mdi-account-outline"
                                              ref="emailInput"
                                              type="text"
                                              v-model="loginCredentials.email"></v-text-field>
                                <password-input-component
                                        :rules="requiredRules"
                                        :value.sync="loginCredentials.password"
                                        cy-tag="passwordField"
                                        @keyupEnter="doLogin"
                                        icon="mdi-lock-outline"
                                        label="Lösenord"
                                ></password-input-component>
                                <p class="text-center red--text">{{errorMsg}}</p>
                                <div class="text-center">
                                  <a data-cy="forgotPasswordBtn" :disabled="loading" @click="forgotPassword = true"
                                  >Glömt
                                    lösenordet?
                                  </a>
                                </div>
                                <div class="text-center mt-4">
                                  <a data-cy="registerBtn" :disabled="loading" :href="signupUrl">Inget konto?
                                    Registrera dig</a>
                                </div>
                            </v-form>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn data-cy="loginBtn" :loading="loading" @click="doLogin" color="primary">Logga in
                                <v-icon right>mdi-send</v-icon>
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                    <v-card class="elevation-12" key="reset" v-else>
                        <v-toolbar color="primary" dark>
                            <v-toolbar-title>
                                <span>Återställ lösenord</span>
                                <span class="toolbar-logo">
                                    <logo-component class="align-middle" color="white" height="36px"
                                                    width="36px"></logo-component>
                                </span>
                            </v-toolbar-title>
                        </v-toolbar>
                        <v-card-text>
                            <v-form @submit.prevent>
                                <v-text-field :rules="emailRules" autofocus label="Epost"
                                              name="email"
                                              prepend-icon="mdi-account-outline"
                                              type="text"
                                              v-model="loginCredentials.email"></v-text-field>
                            </v-form>
                        </v-card-text>
                        <v-card-actions>
                            <v-btn @click="forgotPassword = false" icon>
                                <v-icon>mdi-chevron-left</v-icon>
                            </v-btn>
                            <v-spacer></v-spacer>
                            <reset-password-button :email="loginCredentials.email"
                                                   color="primary"></reset-password-button>
                        </v-card-actions>
                    </v-card>
                </v-slide-x-transition>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
    import LogoComponent from "../../components/global/LogoComponent";
    import api from "../../api/api";
    import Axios from "axios";
    import ResetPasswordButton from "../../components/global/ResetPasswordButton";
    import PasswordInputComponent from "../../components/inputs/PasswordInputComponent";
    import constants from "@/util/constants";

    export default {
        components: {PasswordInputComponent, ResetPasswordButton, LogoComponent},
        data: () => ({
            loginCredentials: {
                email: "",
                password: ""
            },
            validForm: false,
            unconfirmedEmail: false,
            forgotPassword: false,
            loading: false,
            errorMsg: "",
            emailRules: [
                v => !!v || 'Du måste ange en e-postadress',
                v => /.+@.+/.test(v) || 'Din angivna e-postadress är inte giltig'
            ],
            requiredRules: [
                v => !!v || 'Obligatoriskt fält'
            ]
        }),
        computed: {
          signupUrl() {
            return  (process.env.NODE_ENV === 'production' ?  constants.BASIC_PROD_URL : constants.BASIC_DEV_URL) + "/signup"
          }
        },
        methods: {
            async doLogin()
            {
                this.$refs.form.validate()
                if (this.validForm)
                {
                    this.loading = true
                    this.errorMsg = ""
                    // custom API call here to handle unauthorized when email or password is wrong
                    try
                    {
                        let response = await Axios.post(api.BASE_URL + api.API + api.ENDPOINTS.AUTH, this.loginCredentials,
                            {
                                withCredentials: true
                            })
                        let authResponse = response.data
                        if (authResponse)
                        {
                            this.$store.commit('updateUser', authResponse)
                            this.$store.commit('setAppIsLoading', false)
                            location.href = "/"
                        }
                    }
                    catch (error)
                    {
                        // Treat network errors without responses as 500s.
                        const status = error.response ? error.response.status : 500
                        if (status === 401 || status === 404)
                        { // Unauthorised
                            this.errorMsg = "Felaktig e-postadress eller lösenord. Vänligen kontrollera dina inloggningsuppgifter"
                            this.$refs.emailInput.focus()
                        }
                        else if (status === 403)
                        { // Forbidden - unconfirmed emailaddress
                            this.unconfirmedEmail = true
                        }
                        else
                        {
                            this.errorMsg = "Kunde inte kommunicera med servern. Vänligen kontrollera dina nätverksinställningar"
                        }
                    }
                    this.loading = false
                }
            }
        }
    }
</script>

<style scoped>
    .align-middle {
        vertical-align: middle;
    }

    .toolbar-logo {
        position: absolute;
        right: 20px;
    }
</style>
