const types = {
    BOOKED_AT_DATE: "BOOKED_AT_DATE",
    CHECK_IN_DATE: "CHECK_IN_DATE",
    CHECK_OUT_DATE: "CHECK_OUT_DATE",
    DUE_DATE: "DUE_DATE",
}
const typesString = {
    BOOKED_AT_DATE: "Uthyrningsdatum",
    CHECK_IN_DATE: "Incheckningsdatum",
    CHECK_OUT_DATE: "Utcheckningsdatum",
    DUE_DATE: "Förfallodatum"
}
const dueDateTypesArray = [
    {text: "Uthyrningsdatum", value: types.BOOKED_AT_DATE},
    {text: "Incheckningsdatum", value: types.CHECK_IN_DATE},
    {text: "Utcheckningsdatum", value: types.CHECK_OUT_DATE},
]

const scheduleDateTypesArray = [
    {text: "Uthyrningsdatum", value: types.BOOKED_AT_DATE},
    {text: "Incheckningsdatum", value: types.CHECK_IN_DATE},
    {text: "Utcheckningsdatum", value: types.CHECK_OUT_DATE},
    {text: "Förfallodatum", value: types.DUE_DATE}
]

export default {
    types,
    dueDateTypesArray,
    scheduleDateTypesArray,
    typesString
}
