<template>
  <div>
    <v-subheader class="pl-0">
      <v-icon
          color="primary"
          left
      >
        mdi-bell-outline
      </v-icon>
      <v-subheader>Notifikationsinställningar</v-subheader>
    </v-subheader>
    <div>
      <v-expansion-panels :value="openedIndex" flat>
        <v-expansion-panel v-for="notification in notificationSettings" :key="`${notification.type}_panel`"
                           class="outlined mt-5">
          <v-expansion-panel-header>
                <span>
                  <v-icon color="success" v-if="isEveryValueActive(notification)" class="mr-2">mdi-checkbox-marked-outline</v-icon>
                  <v-icon color="primary" v-else-if="isAnyValueActive(notification)" class="mr-2">mdi-checkbox-intermediate</v-icon>
                  <v-icon color="grey" v-else class="mr-2">mdi-checkbox-blank-outline</v-icon>
                  <v-icon left>{{ notification.icon }}</v-icon>
                  {{ notification.description }}
                </span>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="elevation-0 no-padding">
            <v-list flat subheader>
              <v-list-item
                  v-if="notification.emailEnabled !== undefined"
                  :key="`${notification.type}_email`"
                  :input-value="notification.emailEnabled"
                  active-class="primary--text"
                  @click="toggleValue(notification, 'emailEnabled')"
              >
                <v-list-item-content>
                  <v-list-item-title>Per epost</v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  <v-checkbox
                      v-model="notification.emailEnabled"
                      readonly
                  ></v-checkbox>
                </v-list-item-action>
              </v-list-item>
              <v-divider></v-divider>
              <v-list-item
                  v-if="notification.pushEnabled !== undefined"
                  :key="`${notification.type}_push`"
                  :input-value="notification.pushEnabled"
                  active-class="primary--text"
                  three-line
                  @click="toggleValue(notification, 'pushEnabled')"
              >
                <v-list-item-content>
                  <v-list-item-title>Som push-notifikation</v-list-item-title>
                  <v-list-item-subtitle>
                            <span v-if="pushEnabled">
                                Aktivera och registrera din enhet. Acceptera popupen som tillåter Uthyrd
                                att skicka notifikationer till din enhet även när du inte aktivt använder
                                appen.
                            </span>
                    <span v-else>
                                <v-icon class="mr-2" color="error">mdi-alert-outline</v-icon>
                                Push-notifikationer är blockerade eller stödjs inte på denna enhet. Se över dina inställningar genom att trycka på låset bredvid adressfältet i din webbläsare -> och välj att tillåta notifikationer
                            </span>
                  </v-list-item-subtitle>
                  <div v-if="notification.pushEnabled">
                    <v-btn :disabled="(!pushEnabled || pushGranted) && pushConfirmed"
                           class="mt-12 mt-sm-8 mt-lg-6"
                           color="primary" outlined small
                           width="220"
                           @click.stop.native="askForNotificationPermission(notification)">
                      {{ registerBtnText }}
                      <v-icon v-if="pushGranted && pushConfirmed" color="success">mdi-check</v-icon>
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn v-if="pushEnabled && pushGranted"
                           class="mt-3 mb-2" color="accent"
                           outlined
                           small width="220" @click.stop.native="sendTestNotification(notification)">
                      Testa push-notifikation
                    </v-btn>
                  </div>
                </v-list-item-content>
                <v-list-item-action>
                  <v-checkbox
                      v-model="notification.pushEnabled"
                      readonly
                  ></v-checkbox>
                </v-list-item-action>
              </v-list-item>
              <v-divider></v-divider>
              <v-list-item
                  v-if="notification.appEnabled !== undefined"
                  :key="`${notification.type}_app`"
                  :input-value="notification.appEnabled"
                  active-class="primary--text"
                  @click="toggleValue(notification, 'appEnabled')"
              >
                <v-list-item-content>
                  <v-list-item-title>Som lokal notifikation i Uthyrd</v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  <v-checkbox
                      v-model="notification.appEnabled"
                      readonly
                  ></v-checkbox>
                </v-list-item-action>
              </v-list-item>
            </v-list>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
    <v-progress-linear :active="loading" indeterminate></v-progress-linear>
  </div>
</template>

<script>

import api from "../../api/api";

const pushServerPublicKey = "BJctbrzg6uOiBNmTUvg1PsF8lYpo0Kdz-o0cC9SZK_PgFAwfl7fJhTrPd1Ze-Bs8fZ_ElqvLCOpZkdRwWRp5XAI";

export default {
  components: {},
  data: () => ({
    loading: false,
    pushEnabled: false,
    pushConfirmed: true,
    pushGranted: false,
    notificationSettings: [],
    timeout: null,
    openedIndex: -1
  }),
  computed: {
    isPushNotificationSupported()
    {
      return "serviceWorker" in navigator && "PushManager" in window;
    },
    registerBtnText()
    {
      if (this.pushGranted && this.pushConfirmed)
      {
        return 'Enhet registrerad'
      }
      else if (this.pushGranted && !this.pushConfirmed)
      {
        return 'Registrera om denna enhet'
      }
      else
      {
        return 'Registrera denna enhet'
      }
    }
  },
  async mounted()
  {
    this.loading = true
    this.pushEnabled = Notification.permission !== 'denied' && this.isPushNotificationSupported
    this.pushGranted = Notification.permission === 'granted' && this.isPushNotificationSupported
    await this.getNotificationSettings()
    this.loading = false
  },
  methods: {
    async getNotificationSettings()
    {
      if (!this.notificationSettings || this.notificationSettings.length <= 0)
      {
        let settings = await api.getFromEndpoint(api.ENDPOINTS.USERACCOUNTS + "/" + this.$store.state.user.id + "/" + api.ENDPOINTS.NOTIFICATIONSETTINGS)
        if (settings)
        {
          this.notificationSettings = settings
        }
      }
    },
    async askForNotificationPermission(notificationSetting)
    {
      if (!this.pushConfirmed)
      {
        this.registerServiceWorker(notificationSetting)
      }
      else if (Notification.permission === 'default' && this.isPushNotificationSupported)
      {
        this.$store.commit('setGlobalOverlay', {show: true, opacity: 0.8})
        let response = await window.Notification.requestPermission();
        this.pushEnabled = response !== 'denied'
        notificationSetting.push = response === 'granted'
        this.pushGranted = response === 'granted'
        this.$store.commit('setGlobalOverlay', {show: false, opacity: 0.8})
        if (response === 'granted')
        {
          this.registerServiceWorker(notificationSetting)
        }
      }
    },
    registerServiceWorker(notificationSetting)
    {
      navigator.serviceWorker.register("/sw.js").then(async () =>
      {
        let subscription = await this.createPushSubscription()
        await this.savePushSubscription(subscription)
        this.sendTestNotification(notificationSetting)
      });
    },
    async createPushSubscription()
    {
      //wait for service worker installation to be ready, and then
      return await navigator.serviceWorker.ready.then(async function (serviceWorker)
      {
        // subscribe and return the subscription
        return await serviceWorker.pushManager
            .subscribe({
              userVisibleOnly: true,
              applicationServerKey: pushServerPublicKey
            })
            .then(function (subscription)
            {
              return subscription;
            });
      });
    },
    async savePushSubscription(subscription)
    {
      this.loading = true
      this.pushSubscription = {userAccountId: this.$store.state.user.id, subscription: subscription}
      this.pushSubscription = await api.postToEndpoint(api.ENDPOINTS.PUSHSUBSCRIPTION, this.pushSubscription)
      this.loading = false
    },
    async sendTestNotification(notificationSetting)
    {
      this.loading = true
      let notification = {
        type: notificationSetting.type,
        title: "En notifikation!",
        message: "Såhär ser en notifikation ut! Vänligen bekräfta att du har sett detta.",
        actions: [{name: "test", title: "Coolt!"}]
      }
      await api.postToEndpoint(api.ENDPOINTS.NOTIFICATION, notification)
      this.loading = false
      this.showNotificationConfirmDialog()
    },
    showNotificationConfirmDialog()
    {
      this.pushConfirmed = false
      this.$store.commit('setDialog', {
        active: true,
        closeBtnText: "Nej",
        actionBtnText: "Ja",
        title: "Såg du notifikationen?",
        text: "Uthyrd skickade precis en notifikation till dig! Såg du den?",
        actionClick: () =>
        {
          this.pushConfirmed = true
        }
      })
    },
    async saveNotificationSettings()
    {
      this.loading = true
      let response = await api.postToEndpoint(api.ENDPOINTS.NOTIFICATIONSETTINGS, {settings: this.notificationSettings})
      if (response)
      {
        this.notificationSettings = response
        this.$store.commit('setSnackbar', {color: "success", text: "Notifikationsinställningar sparade"})
      }

      this.loading = false

    },
    async toggleValue(object, key)
    {
      object[key] = !object[key]
      clearTimeout(this.timeout);

      // debounce incase user toggles multiple values in short succession
      this.timeout = await setTimeout(async () =>
      {
        this.saveNotificationSettings()
      }, 850)
    },
    isAnyValueActive(notification)
    {
      if (notification.emailEnabled !== undefined && notification.emailEnabled)
      {
        return true
      }
      if (notification.pushEnabled !== undefined && notification.pushEnabled)
      {
        return true
      }
      if (notification.appEnabled !== undefined && notification.appEnabled)
      {
        return true
      }
      return false
    },
    isEveryValueActive(notification)
    {
      return notification.emailEnabled !== undefined && notification.emailEnabled &&
          notification.pushEnabled !== undefined && notification.pushEnabled &&
          notification.appEnabled !== undefined && notification.appEnabled
    }
  }
}
</script>

<style scoped>

</style>
