var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"grid-list-xl":""}},[_c('v-layout',{attrs:{"row":"","wrap":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('v-card',{attrs:{"loading":_vm.loadingSubscriptions,"color":"white"}},[_c('v-toolbar',{attrs:{"color":"secondary","dark":""}},[_c('v-toolbar-title',{staticClass:"text-center"},[_vm._v(" Kalenderprenumerationer ")]),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({class:{'highlight-pulse': _vm.calendarSubscriptionTokens.length <= 0},attrs:{"color":"primary","fab":"","small":""},on:{"click":_vm.newCalendarSubscriptionToken}},on),[_c('v-icon',[_vm._v("add")])],1)]}}])},[_c('span',[_vm._v("Skapa en ny kalenderprenumeration")])])],1),_c('v-card-text',[_c('calendar-subscription-help-dialog-component'),_c('v-list',[_vm._l((_vm.calendarSubscriptionTokens),function(item){return _c('v-list-item',{key:item.token},[_c('v-list-item-icon',{staticClass:"mr-8"},[_c('span',{staticClass:"grey--text text--darken-2"},[_c('span',{staticClass:"font-weight-medium"},[_vm._v("Skapad")]),_c('p',[_vm._v(_vm._s(_vm.$moment(item.createdAt).format('YYYY-MM-DD')))])])]),_c('v-list-item-content',[_c('v-list-item-title',[_c('v-menu',{attrs:{"activator":'#link_' + item.id,"offset-y":""}},[_c('v-list',[_c('v-list-item',{attrs:{"href":_vm.getEndpointUrl(item.token),"target":"_blank"}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-dock-window")])],1),_c('v-list-item-title',[_vm._v("Öppna länk i ditt kalenderprogram")])],1),_c('v-list-item',{on:{"click":function($event){return _vm.copyToClipboard(item.token)}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-content-copy")])],1),_c('v-list-item-title',[_vm._v("Kopiera länk")])],1)],1)],1),_c('v-tooltip',{attrs:{"bottom":"","open-delay":"250"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-chip',_vm._g({staticClass:"clickable",attrs:{"id":'link_' + item.id,"outlined":""}},on),[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-link ")]),_c('span',[_vm._v(_vm._s(_vm.getEndpointUrl(item.token)))])],1)]}}],null,true)},[_c('span',[_vm._v("Klicka för att öppna eller kopiera länken")])])],1)],1),_c('v-list-item-action',{staticClass:"pl-1",staticStyle:{"justify-content":"center"}},[_c('v-speed-dial',{staticClass:"remove-padding",attrs:{"direction":"left","transition":"slide-x-reverse-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',{attrs:{"color":"grey"}},[_vm._v(_vm._s(item.fab ? 'mdi-close': 'mdi-dots-vertical')+" ")])],1)]},proxy:true}],null,true),model:{value:(item.fab),callback:function ($$v) {_vm.$set(item, "fab", $$v)},expression:"item.fab"}},[_c('div',[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"force-on-top",attrs:{"color":"primary","dark":"","fab":"","small":""},on:{"click":function($event){return _vm.selectSubscriptionToken(item)}}},on),[_c('v-icon',[_vm._v("edit")])],1)]}}],null,true)},[_c('span',[_vm._v("Redigera denna kalenderprenumeration")])])],1),_c('div',[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"force-on-top",attrs:{"color":"error","dark":"","fab":"","small":""},on:{"click":function($event){return _vm.confirmDelete(item)}}},on),[_c('v-icon',[_vm._v("delete")])],1)]}}],null,true)},[_c('span',[_vm._v("Radera denna kalenderprenumeration")])])],1)])],1)],1)}),(_vm.calendarSubscriptionTokens.length <= 0)?_c('no-data-component',{attrs:{"icon":"arrow_upward","pulse":"","text":"Du har inte skapat några kalenderprenumerationer än. Använd\n        plus-knappen längst upp till höger för att göra det"}}):_vm._e()],2)],1)],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"600px","persistent":""},model:{value:(_vm.dialogIsOpen),callback:function ($$v) {_vm.dialogIsOpen=$$v},expression:"dialogIsOpen"}},[_c('calendar-subscription-details-component',{attrs:{"shown":_vm.dialogIsOpen,"subscription-token-id":_vm.selectedCalendarSubscriptionToken.id},on:{"cancel":_vm.closeDialog,"saved":_vm.calendarSubscriptionTokenSaved}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }