<template>
  <div style="width: 100%">
    <v-menu
        :close-on-content-click="false"
        max-height="300"
        max-width="300"
        :left="$vuetify.breakpoint.mdAndUp"
        :bottom="$vuetify.breakpoint.smAndDown"
        :offset-x="$vuetify.breakpoint.mdAndUp"
        :offset-y="$vuetify.breakpoint.smAndDown"
        :nudge-left="$vuetify.breakpoint.mdAndUp ? 15 : 0"
        :nudge-bottom="$vuetify.breakpoint.smAndDown ? 10 : 0"
        :transition="$vuetify.breakpoint.mdAndUp ? 'slide-x-reverse-transition' :  'slide-y-transition'"
        v-model="typeMenu"
        activator="#searchField"
    >
      <v-card>
        <v-card-text>
          <v-select :menu-props="{'offset-y': true, transition: 'slide-y-transition'}" outlined label="Sök efter:" hide-details :items="searchTypes"
                    v-model="searchType">
            <template v-slot:selection="data">
              <v-icon left color="primary">{{data.item.icon}}</v-icon>
                {{ data.item.text }}
            </template>
            <template v-slot:item="data">
              <template>
                <v-list-item-icon>
                  <v-icon>{{data.item.icon}}</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ data.item.text }}
                  </v-list-item-title>
                </v-list-item-content>
              </template>
            </template>
          </v-select>
        </v-card-text>
      </v-card>
    </v-menu>
    <v-menu
        :close-on-content-click="true"
        bottom
        class="scrollable"
        max-height="400"
        max-width="650"
        offset-y
        right
        transition="slide-y-transition"
        v-model="searchMenu"
    >
      <!-- eslint-disable -->
      <template v-slot:activator="{ on }">
        <v-text-field
            @focus="handleFocus"
            append-icon="search"
            color="secondary"
            flat
            hide-details
            id="searchField"
            light
            placeholder="Sök (ange minst tre tecken)"
            single-line
            solo
            autocomplete="off"
            type="search"
            v-model="searchQuery"
        ></v-text-field>
        <v-progress-linear bottom :active="loadingSearchResults" color="white" indeterminate></v-progress-linear>
      </template>
      <!-- eslint-enable -->
      <v-list
          dense
          three-line
      >
        <v-list-item
            two-line
            v-if="searchResults.length <= 0"
        >
          <v-list-item-icon>
            <v-icon color="grey">
              mdi-card-search-outline
            </v-icon>
          </v-list-item-icon>
          <v-list-item-title>Din sökning gav inga resultat</v-list-item-title>
        </v-list-item>
        <span
            :key="item.id"
            v-else
            v-for="(item, i) in searchResults"
        >
                <search-result-component :search-result="item"></search-result-component>
                <v-divider v-if="i > 0"/>
                </span>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import searchTypes from "../../models/searchTypes";
import SearchResultComponent from "./SearchResultComponent";
import api from "../../api/api";

export default {
  components: {SearchResultComponent},
  data: () => ({
    loadingSearchResults: false,
    searchResults: [],
    searchType: searchTypes.types.BOOKING_PERIOD,
    searchTypes: searchTypes.typesArray,
    searchMenu: false,
    typeMenu: false,
    searchQuery: "",
    timeout: 0
  }),
  watch: {
    searchQuery(val)
    {
      this.search(val)
    },
    searchType()
    {
      // clear search and results if searchType is changed
      clearTimeout(this.timeout);
      this.searchQuery = "";
      this.searchResults = []
    }
  },
  props: {},
  computed: {},
  methods: {
    async search(val)
    {
      clearTimeout(this.timeout);
      this.searchMenu = false
      if (val && val.length > 2)
      {
        this.timeout = await setTimeout(async () =>
        {
          this.loadingSearchResults = true;
          let result = await api.getFromEndpoint(`${api.ENDPOINTS.SEARCH}/${api.ENDPOINTS.GLOBAL}/${this.searchQuery}`, {
            searchType: this.searchType
          });

          if (result)
          {
            this.searchResults = result
          }
          this.loadingSearchResults = false;
          this.searchMenu = true;
          this.typeMenu = false;
        }, 250)
      }
    },
    handleFocus()
    {
      // show results on input focus if there has previously been a search (not for mobile since searchType is obscured by search results)
      if (this.searchResults.length > 0 && this.$vuetify.breakpoint.mdAndUp)
      {
        setTimeout(() =>
        {
          this.searchMenu = true
        }, 110);
      }
    },
  }
}
</script>

<style scoped>
</style>
