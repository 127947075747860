,
<template>
    <v-container grid-list-xl>
        <v-layout row wrap>
            <v-flex xs12>
                <v-card :loading="loadingCustomers" color="white">
                    <v-toolbar color="secondary" dark>
                        <v-toolbar-title class="text-center">Kundlista</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-text-field
                                append-icon="search"
                                class="mr-5"
                                hide-details
                                label="Sök"
                                single-line
                                v-model="search"
                        ></v-text-field>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-btn :class="{'highlight-pulse': customers.length <= 0}" @click="newCustomer" color="primary" fab
                                       small
                                       v-on="on">
                                    <v-icon>add</v-icon>
                                </v-btn>
                            </template>
                            <span>Skapa en ny kund</span>
                        </v-tooltip>
                    </v-toolbar>
                    <v-card-text>
                        <customer-data-table-component :customers="customers" :search="search" @selectCustomer="selectCustomer"
                                                       no-data-icon="arrow_upward" no-data-pulse
                                                       no-data-text="Du har inte skapat några kunder än. Använd
            plus-knappen längst upp till höger för att göra det"></customer-data-table-component>
                    </v-card-text>
                </v-card>
            </v-flex>
        </v-layout>
        <v-dialog max-width="600px" persistent scrollable v-model="dialogIsOpen">
            <customer-details-card-component :customer-id="selectedCustomer.id" :shown="dialogIsOpen" @cancel="closeDialog"
                                             @saved="customerSaved" cancel-btn
                                             scrollable></customer-details-card-component>
        </v-dialog>
    </v-container>
</template>

<script>

    import api from "../../api/api"
    import CustomerDataTableComponent from "../../components/customer/CustomerDataTableComponent";
    import CustomerDetailsCardComponent from "../../components/customer/CustomerDetailsCardComponent";

    export default {
        components: {CustomerDetailsCardComponent, CustomerDataTableComponent},
        data: () => ({
            loadingCustomers: true,
            customers: [],
            selectedCustomer: {
                id: 0
            },
            dialogIsOpen: false,
            search: ""
        }),
        computed: {
            stateUser()
            {
                return this.$store.state.user
            },
        },
        mounted()
        {
            this.getCustomers()
        },
        methods: {
            async getCustomers()
            {
                this.loadingCustomers = true
                let serverResponse = await api.getFromEndpoint(api.ENDPOINTS.USERACCOUNTS + "/" + this.$store.state.user.id + "/" + api.ENDPOINTS.CUSTOMERS)
                if (serverResponse)
                {
                    this.customers = serverResponse
                    if (this.$route.params.id && this.$route.params.id > 0)
                    {
                        this.selectCustomer({id: this.$route.params.id})
                    }
                }
                this.loadingCustomers = false
            },
            newCustomer()
            {
                this.selectedCustomer = {id: 0}
                this.dialogIsOpen = true
            },
            selectCustomer(customer)
            {
                this.selectedCustomer = customer
                this.dialogIsOpen = true
            },
            closeDialog()
            {
                this.dialogIsOpen = false
            },
            customerSaved()
            {
                this.getCustomers()
                this.closeDialog()
            }
        }
    }
</script>
