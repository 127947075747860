<template>
  <div>
    <v-subheader>
      Betalningshändelser
      <v-spacer></v-spacer>
    </v-subheader>
    <v-data-table
        :headers="headers"
        :items="paymentLogs"
        :loading="loading"
        disable-pagination
        disable-sort
        fixed-header
        hide-default-footer
        height="230px"
    >
      <template v-slot:header.add>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
                @click="paymentLogDialog = true"
                color="primary" outlined small
                v-on="on">
              <v-icon>add</v-icon>
            </v-btn>
          </template>
          <span>Registrera en betalningshändelse</span>
        </v-tooltip>
      </template>
      <template v-slot:item.createdAt="data">
        {{ data.item.createdAt | calendarFormat($moment) }}
      </template>
      <template v-slot:item.type="data">
        {{ data.item.type | typeString(paymentLogTypes) }}
      </template>
      <template v-slot:no-data>
        <no-data-component class="mt-12" icon="mdi-credit-card-off-outline"
                           text="Det finns inga betalningshändelser att visa för denna period"></no-data-component>
      </template>
      <template v-slot:footer>
        <v-layout row wrap>
          <v-divider></v-divider>
        </v-layout>
        <v-layout row wrap>
          <v-flex xs12>
            <v-chip :color="balanceColor" :input-value="currentBalanceIsZero" filter label>
              <b>Kvar att betala:</b> <span class="ml-1">{{ bookingPeriod.currentBalance }}</span>
            </v-chip>
          </v-flex>
        </v-layout>
      </template>
    </v-data-table>
    <v-dialog max-width="800px" persistent v-model="paymentLogDialog">
      <v-card :loading="loadingDialog" :disabled="loadingDialog">
        <v-card-title>
          Registrera ny betalningshändelse
          <v-spacer></v-spacer>
          <v-icon color="primary">mdi-credit-card-plus-outline</v-icon>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="mt-3">
          <v-form ref="paymentLogForm" v-model="logValid">
            <v-select return-object label="Typ av händelse" v-model="newLog.type" :items="selectableTypes">
            </v-select>
            <v-text-field
                :rules="[v => !!v && v > 0 || 'Du måste ange ett belopp som är över 0']"
                autocomplete="off"
                label="Belopp"
                required
                min="1"
                suffix="SEK"
                type="number"
                v-model="newLog.amount"
            ></v-text-field> <!-- TODO - i18n for currency -->
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn @click="paymentLogDialog = false" text>Avbryt</v-btn>
          <v-spacer></v-spacer>
          <v-btn @click="saveNewLog" color="primary" text>Spara</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import NoDataComponent from "../../global/NoDataComponent";
import api from "../../../api/api";
import paymentLogTypes from "../../../models/paymentLogTypes";

export default {
  components: {NoDataComponent},
  data: () => ({
    headers: [
      {text: 'Datum', value: 'createdAt'},
      {text: 'Typ', value: 'type'},
      {text: 'Belopp', value: 'amount'},
      {text: 'Att betala', value: 'balance'},
      {text: '', value: 'add', align: 'right'}
    ],
    loading: false,
    loadingDialog: false,
    sendPaymentLogNotification: true,
    logValid: false,
    paymentLogs: [],
    paymentLogTypes: paymentLogTypes.TYPES,
    selectableTypes: [paymentLogTypes.TYPES.PAYMENT_REFUNDED, paymentLogTypes.TYPES.PAYMENT_REGISTERED],
    newLog: {amount: 0, type: paymentLogTypes.TYPES.PAYMENT_REGISTERED},
    paymentLogDialog: false,
  }),
  props: {
    bookingPeriod: Object,
    saving: Boolean,
    unsavedChanged: Boolean,
  },
  filters: {
    typeString(type, paymentLogTypes)
    {
      return paymentLogTypes[type].text
    },
    calendarFormat(date, moment)
    {
      return moment(date).calendar()
    },
  },
  mounted()
  {
    this.getPaymentLogsFromBookingPeriod()
  },
  computed: {
    unsavedChanges: {
      get()
      {
        return this.unsavedChanged
      },
      set(val)
      {
        this.$emit('update:unsavedChanged', val)
      }
    },
    currentBalanceIsZero()
    {
      return this.bookingPeriod.currentBalance === 0
    },
    balanceColor()
    {
      return this.currentBalanceIsZero ? 'success' : 'error'
    },
  },
  watch: {
    saving(val)
    { // if bookingPeriod is saved we need to refresh the logs to reflect eventual changes
      if (!val)
      {
        this.getPaymentLogsFromBookingPeriod()
      }
    }
  },
  methods: {
    async getPaymentLogsFromBookingPeriod()
    {
      this.loading = true
      let serverResponse = await api.getFromEndpoint(api.ENDPOINTS.BOOKINGPERIODS + "/" + this.bookingPeriod.id + "/" + api.ENDPOINTS.PAYMENTLOGS)
      if (serverResponse)
      {
        this.paymentLogs = serverResponse
      }
      this.loading = false
    },
    async saveNewLog()
    {
      this.loadingDialog = true
      let calcAmount = this.newLog.amount
      // for payments we need a negative amount
      if (this.newLog.type === paymentLogTypes.TYPES.PAYMENT_REGISTERED)
      {
        calcAmount = parseInt(calcAmount) * -1
      }
      let paymentLog = {
        bookingPeriodId: this.bookingPeriod.id,
        type: this.newLog.type.value,
        amount: calcAmount,
        balance: parseInt(this.bookingPeriod.currentBalance) + parseInt(calcAmount),
      }
      let serverResponse = await api.postToEndpoint(api.ENDPOINTS.PAYMENTLOGS, paymentLog)
      if (serverResponse)
      {
        this.$store.commit('setSnackbar', {color: "primary", text: "Händelse registrerad"})
        this.getPaymentLogsFromBookingPeriod()
        this.$emit('refreshBookingPeriod')
      }
      this.paymentLogDialog = false
      this.loadingDialog = false
      this.unsavedChanges = true
    }
  }
}
</script>

<style scoped>
tr.no-hover {
  background: none !important;
}
</style>
