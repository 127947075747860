var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"persistent":"","scrollable":"","max-width":"800px"},model:{value:(_vm.dialogProp),callback:function ($$v) {_vm.dialogProp=$$v},expression:"dialogProp"}},[_c('v-card',{attrs:{"loading":_vm.loading}},[_c('v-card-title',[_vm._v(" Redigerar bild ")]),_c('v-divider'),_c('v-card-text',[_c('v-container',{staticClass:"text-center",attrs:{"fluid":""}},[_c('div',{staticClass:"mb-6"},[_c('croppa',{attrs:{"initial-image":_vm.imageSrc,"canvas-color":'primary',"disable-rotation":false,"disable-click-to-choose":true,"prevent-white-space":true,"show-remove-button":false,"width":300,"height":300,"show-loading":true,"zoom-speed":8},model:{value:(_vm.croppa),callback:function ($$v) {_vm.croppa=$$v},expression:"croppa"}}),_c('p',{staticClass:"mt-4 pb-0 grey--text"},[_vm._v("Skrolla eller nyp skärmen för att zooma eller använd knapparna nedan.")])],1),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"elevation-2 white mr-10",attrs:{"fab":"","small":""},on:{"click":_vm.rotateLeft}},on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-rotate-left")])],1)]}}])},[_c('span',[_vm._v("Rotera vänster")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"elevation-2 white mr-4",attrs:{"fab":"","small":""},on:{"click":_vm.zoomOut}},on),[_c('v-icon',{attrs:{"color":"secondary"}},[_vm._v("mdi-magnify-minus-outline")])],1)]}}])},[_c('span',[_vm._v("Zooma ut")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"elevation-2 white ml-4",attrs:{"fab":"","small":""},on:{"click":_vm.zoomIn}},on),[_c('v-icon',{attrs:{"color":"secondary"}},[_vm._v("mdi-magnify-plus-outline")])],1)]}}])},[_c('span',[_vm._v("Zooma in")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"elevation-2 white ml-10",attrs:{"fab":"","small":""},on:{"click":_vm.rotateRight}},on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-rotate-right")])],1)]}}])},[_c('span',[_vm._v("Rotera höger")])])],1)],1),_c('v-divider'),_c('v-card-actions',[_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.dialogProp = false}}},[_vm._v("Avbryt")]),_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":_vm.imageSaved}},[_vm._v("Spara")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }