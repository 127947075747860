<template>
    <v-list>
        <v-list-item @click="startTour" v-if="showTourBtn">
            <v-list-item-title>Visa genomgång av nuvarande vy</v-list-item-title>
            <v-list-item-icon>
                <v-icon color="accent">mdi-teach</v-icon>
            </v-list-item-icon>
        </v-list-item>
        <v-list-item target="_blank" href="https://headwayapp.co/uthyrd-updates">
            <v-list-item-title>Vad är nytt?</v-list-item-title>
            <v-list-item-icon>
                <v-icon color="primary">mdi-bullhorn-outline</v-icon>
            </v-list-item-icon>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item dense class="mt-2" @click="showHelpWidget">
            <v-list-item-content>
                <v-list-item-title><b>Behöver du hjälp? Klicka här!</b></v-list-item-title>
                <v-list-item-subtitle>{{supportEmail}}</v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-icon>
                <v-icon color="info">mdi-comment-question-outline</v-icon>
            </v-list-item-icon>
        </v-list-item>
    </v-list>
</template>

<script>
    export default {
        props: {
            showTourBtn: Boolean,
        },
        data: () => ({
            supportEmail: "support@uthyrd.se"
        }),
        methods: {
            startTour()
            {
                this.$emit('startTour')
            },
            showHelpWidget() {
                window.FreshworksWidget('open');
            }
        }
    }
</script>
