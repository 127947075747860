<template>
    <span>
        <v-tooltip bottom>
            <template v-slot:activator="{ on }">
                <v-avatar size="24" v-on="on" color="white">
                    <v-icon dark small :color="statusObj.iconColor">{{statusObj.icon}}</v-icon>
                </v-avatar>
            </template>
            <span>{{statusObj.text}}</span>
        </v-tooltip>
    </span>
</template>

<script>
    import messageStatuses from "../../../models/messageStatuses";

    export default {
        data: () => ({
            messageStatuses: messageStatuses.STATUSES
        }),
        props: {
            status: String
        },
        computed: {
            statusObj()
            {
                return this.messageStatuses[this.status]
            }
        }
    }
</script>

