var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-list',{attrs:{"two-line":""}},[(_vm.customers.length <= 0)?_c('no-data-component',{attrs:{"text":_vm.noDataText,"icon":_vm.noDataIcon,"pulse":_vm.noDataPulse}}):_vm._e(),_vm._l((_vm.customers),function(item){return _c('v-list-item',{key:item.id,attrs:{"dense":_vm.dense}},[_c('v-list-item-avatar',[_c('initials-avatar-component',{staticClass:"mr-2",attrs:{"firstname":item.firstname,"imgSrc":_vm.getImageUrl(item),"size":30,"lastname":item.lastname}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(((item.firstname) + " " + (item.lastname))))]),_c('v-list-item-subtitle',[(item.email.length > 0)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","color":"primary","disabled":_vm.disableMessageButton},on:{"click":function($event){return _vm.$emit('customerMailClick', item.email)}}},on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-message-text-outline")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.email))])]):_vm._e(),(item.phone.length > 0)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","color":"primary","href":("tel:" + (item.phone))},on:{"click":function($event){$event.stopPropagation();}}},on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-phone")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.phone))])]):_vm._e(),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","to":{name: 'customers', params:{'id': item.id}},"color":"accent"}},on),[_c('v-icon',{attrs:{"color":"accent"}},[_vm._v("mdi-account-edit-outline")])],1)]}}],null,true)},[_c('span',[_vm._v("Gå till kunddetaljer")])])],1)],1),(_vm.showFavorite)?_c('v-list-item-icon',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"disabled":_vm.favoritesDisabled,"color":item.mainContact ? 'primary':'grey',"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.setCustomerAsMainContact(item)}}},on),[_c('v-icon',[_vm._v(_vm._s(item.mainContact ? "mdi-heart" : "mdi-heart-outline"))])],1)]}}],null,true)},[_c('span',[_vm._v("Markera som huvudkund")])])],1):_vm._e()],1)})],2)}
var staticRenderFns = []

export { render, staticRenderFns }