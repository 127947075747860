import { render, staticRenderFns } from "./HomeView.vue?vue&type=template&id=c00dc6fa&"
import script from "./HomeView.vue?vue&type=script&lang=js&"
export * from "./HomeView.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBadge } from 'vuetify/lib/components/VBadge';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VItem } from 'vuetify/lib/components/VItemGroup';
import { VItemGroup } from 'vuetify/lib/components/VItemGroup';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VWindow } from 'vuetify/lib/components/VWindow';
import { VWindowItem } from 'vuetify/lib/components/VWindow';
installComponents(component, {VBadge,VBtn,VContainer,VFlex,VIcon,VItem,VItemGroup,VLayout,VProgressLinear,VWindow,VWindowItem})
