<template>
  <v-card color="white" :loading="loading">
    <v-card-title class="secondary">
      <v-icon
          left
          dark
      >
        mdi-link
      </v-icon>
      <span class="title textfont-weight-light white--text">{{ title }}</span>
    </v-card-title>
    <v-card-text>
      <v-container grid-list-xl>
        <v-layout row wrap>
          <v-flex xs12>
            <v-form
                ref="form"
                v-model="validForm"

            >
              <v-text-field
                  v-if="calendarSubscriptionToken.token"
                  v-model="calendarSubscriptionToken.token"
                  label="Nyckel"
                  required
                  autofocus
                  autocomplete="off"
                  readonly
              ></v-text-field>
              <booking-object-select-component
                  multiple
                  required
                  :return-object="false"
                  :value.sync="calendarSubscriptionToken.options"
              ></booking-object-select-component>
              <booking-period-status-select-component multiple chips
                                                      :value.sync="calendarSubscriptionToken.options"></booking-period-status-select-component>
            </v-form>
          </v-flex>
        </v-layout>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-btn text @click="cancel">
        Avbryt
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn text @click="saveCalendarSubcriptionToken"
             color="primary">Spara
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import api from "../../api/api";
import BookingPeriodStatusSelectComponent from "../bookingperiod/BookingPeriodStatusSelectComponent";
import BookingObjectSelectComponent from "../bookingobject/BookingObjectSelectComponent";

export default {
  components: {BookingObjectSelectComponent, BookingPeriodStatusSelectComponent},
  data: () => ({
    validForm: true,
    loading: false,
    calendarSubscriptionToken: {
      token: null,
      options: {
        statuses: [0, 1, 2],
        bookingObjects: null
      },
      userAccountId: null
    }
  }),
  props: {
    subscriptionTokenId: Number,
    shown: Boolean,
  },
  computed: {
    stateUser() {
      return this.$store.state.user
    },
    title() {
      return this.calendarSubscriptionToken.id ? "Redigerar kalenderprenumeration" : "Ny kalenderprenumeration"
    }
  },
  watch: {
    shown() {
      if (this.shown) {
        this.setInitSubscriptionToken()
      }
    }
  },
  async mounted() {
    this.setInitSubscriptionToken()
  },
  methods: {
    async setInitSubscriptionToken() {
      if (this.subscriptionTokenId && this.subscriptionTokenId > 0) {
        this.loading = true
        let serverResponse = await api.getFromEndpoint(api.ENDPOINTS.CALENDARSUBSCRIPTIONTOKENS + "/" + this.subscriptionTokenId)
        if (serverResponse) {
          Object.assign(this.calendarSubscriptionToken, serverResponse);
        }
        this.loading = false
      } else {
        this.calendarSubscriptionToken = {
          token: null,
          options: {
            statuses: [0, 1, 2],
            bookingObjects: null
          },
          userAccount: null,
        }
      }
      this.calendarSubscriptionToken.userAccountId = this.stateUser.id

    },
    async saveCalendarSubcriptionToken() {
      this.$refs.form.validate()
      if (this.validForm) {
        this.$store.commit('setAppIsLoading', true)
        let saveText = "Kalenderprenumeration skapad"
        this.loading = true
        if (this.calendarSubscriptionToken.id && this.calendarSubscriptionToken.id > 0) {
          saveText = "Kalenderprenumeration uppdaterad"
          this.calendarSubscriptionToken = await api.putToEndpoint(api.ENDPOINTS.CALENDARSUBSCRIPTIONTOKENS + "/" + this.calendarSubscriptionToken.id, this.calendarSubscriptionToken)
        } else {
          this.calendarSubscriptionToken = await api.postToEndpoint(api.ENDPOINTS.CALENDARSUBSCRIPTIONTOKEN, this.calendarSubscriptionToken)
        }
        this.loading = false
        this.$store.commit('setAppIsLoading', false)
        if (this.calendarSubscriptionToken != null) {
          this.$store.commit('setSnackbar', {color: "success", text: saveText})
        }
        this.$emit('saved', this.calendarSubscriptionToken)

      }
    },
    cancel() {
      this.$emit('cancel')
    },
  }
}
</script>

<style scoped>

</style>
