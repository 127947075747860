<template>
    <div>
        <no-data-component v-if="files.length <= 0" text="Det finns inga uppladdade filer att visa" icon="mdi-folder-alert-outline"></no-data-component>
        <v-list v-else dense subheader max-height="450px" style="overflow-y: auto;">
            <transition-group name="slide-x-transition" mode="out-in">
                <file-item-component
                        v-for="file in files"
                        :key="file.id + '_' + file.name"
                        :file="file" @fileUpdated="fileUpdated"
                        @fileDeleted="fileDeleted"
                        :multiple="multiple"
                        :show-actions="showActions"></file-item-component>
            </transition-group>
        </v-list>
    </div>
</template>

<script>
import FileItemComponent from "./FileItemComponent";
import NoDataComponent from "../NoDataComponent";

export default {
        components: {NoDataComponent, FileItemComponent, },
        data: () => ({}),
        props: {
            files: Array,
            showActions: Boolean,
            multiple: Boolean,
        },
        methods: {
            fileDeleted(deletedFile)
            {
                let index = this.files.findIndex((file) => file.fileData.filename === deletedFile.fileData.filename)
                this.files.splice(index, 1)
                this.$emit('fileDeleted', deletedFile)
            },
            fileUpdated(updatedFile, fileName)
            {
                let fi = this.files.find((file) => file.fileData.filename === updatedFile.fileData.filename)
                fi.name = fileName
                this.$emit('fileUpdated', updatedFile, fileName)
            },
        }
    }
</script>
