<template>
    <v-list-item
            :tag="showActions ? 'li': 'a'"
            :download="attachment.name"
            :href="showActions ? null : getFileUrl(attachment.fileData)"
            target="_blank"
    >
        <v-list-item-icon>
            <v-icon :color="light ? 'white': 'primary'">{{fileTypes.properties[fileData.filetype].icon}}</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
            <v-list-item-title>
                <v-slide-x-transition mode="out-in">
                    <span v-if="!editingAttachment">{{attachment.name}}</span>
                    <v-text-field
                            v-model="attachmentName"
                            label="Namn"
                            :rules="[v => !!v || 'Du måste ange ett namn']"
                            required
                            autocomplete="off"
                            @keyup.enter="editAttachment"
                            v-else
                            autofocus
                    ></v-text-field>
                </v-slide-x-transition>
            </v-list-item-title>
            <v-list-item-subtitle><b>Filtyp: </b> {{fileData.extension}}</v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-avatar v-if="fileData.filetype === fileTypes.IMAGE" tile>
            <v-img :src="getFileUrl(attachment.fileData)"></v-img>
        </v-list-item-avatar>
        <v-list-item-action v-if="showActions">
            <v-tooltip left>
                <template v-slot:activator="{ on }">
                    <v-btn v-on="on" :color="editingAttachment ? 'success':'primary'" @click="editAttachment" icon
                           dark>
                        <v-icon>{{editingAttachment ? 'mdi-check':'mdi-file-edit-outline'}}</v-icon>
                    </v-btn>
                </template>
                <span>Redigera filnamnet</span>
            </v-tooltip>
            <v-tooltip left>
                <template v-slot:activator="{ on }">
                    <v-btn color="error" v-on="on" @click="attachmentDeleted" icon dark>
                        <v-icon>mdi-delete-outline</v-icon>
                    </v-btn>
                </template>
                <span>Ta bort bifogad fil</span>
            </v-tooltip>
        </v-list-item-action>
    </v-list-item>
</template>

<script>
    import filetypes from "../../../../models/filetypes";
    import api from "../../../../api/api";

    export default {
        components: {},
        data: () => ({
            fileTypes: filetypes.TYPES,
            editingAttachment: false,
            attachmentName: ""
        }),
        props: {
            attachment: Object,
            showActions: Boolean,
            light: Boolean
        },
        computed: {
            fileData()
            {
                return this.attachment.fileData
            }
        },
        methods: {
            getFileUrl(file)
            {
                return api.BASE_URL + file.src
            },
            editAttachment()
            {
                if (this.editingAttachment)
                {
                    this.attachmentUpdated()
                    this.editingAttachment = false
                }
                else
                {
                    this.attachmentName = this.attachment.name
                    this.editingAttachment = true
                }
            },
            attachmentUpdated()
            {
                this.$emit("attachmentUpdated", this.attachment, this.attachmentName)
            },
            attachmentDeleted()
            {
                this.$emit("attachmentDeleted", this.attachment)
            }
        }
    }
</script>
<style scoped>
    .v-list-item__action, .v-list-item__avatar, .v-list-item__icon {
        display: inline;
    }
</style>
