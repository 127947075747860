const STATUSES = {
    NOT_SENT: {
        value: 'NOT_SENT',
        text: "Ej skickad",
        color: "grey"
    },
    SENT: {
        value: 'SENT',
        text: "Förfrågan skickad",
        color: "info",
        templateTitle: "Hej [kundens förnamn]!",
        templateText: "" +
            "Du har fått en betalningsförfrågan som avser [namn på uthyrningsobjekt], [namn på period] [från- och tilldatum] \n" +
            "\n" +
            "Det här är en förfrågan om inbetalning på det belopp som står angivet nedan.\n" +
            "\n" +
            "[Betalningsinformation för uthyrningsobjektet]"
    },
    REMINDER_SENT: {
        value: 'REMINDER_SENT',
        text: "Påminnelse skickad",
        color: "accent",
        templateTitle: "Hej [kundens förnamn]!",
        templateText: "" +
            "Du har fått en påminnelse på en betalning som avser [namn på uthyrningsobjekt], [namn på period] [från- och tilldatum] \n" +
            "\n" +
            "Det här är en påminnelse på att vi inte har registrerat någon inbetalning för beloppet som står angivet nedan.\n" +
            "\n" +
            "[Betalningsinformation för uthyrningsobjektet]"
    },
    PAYMENT_DUE: {
        value: 'PAYMENT_DUE',
        text: "Förfallen",
        color: "error"
    },
    PAID: {
        value: 'PAID',
        text: "Betald",
        color: "success",
        templateTitle: "Inbetalning mottagen och registrerad!",
        templateText: "" +
            "Detta avser [namn på uthyrningsobjekt], [namn på period] [från- och tilldatum] \n" +
            "\n" +
            "Vi vill med detta meddelande bekräfta att vi har mottagit och registrerat en inbetalning från dig på beloppet nedan"
    },
    REFUNDED: {
        value: 'REFUNDED',
        text: "Återbetald",
        color: "primary",
        templateTitle: "Hej [kundens förnamn]!",
        templateText: "" +
            "Detta avser [namn på uthyrningsobjekt], [namn på period] [från- och tilldatum] \n" +
            "\n" +
            "Vi vill meddela dig om att vi har registrerat en återbetalning till dig på beloppet som står angivet nedan."
    },
    CANCELLED: {
        value: 'CANCELLED',
        text: "Inställd",
        color: "grey",
        templateTitle: "Hej [kundens förnamn]!",
        templateText: "" +
            "Detta avser [namn på uthyrningsobjekt], [namn på period] [från- och tilldatum] \n" +
            "\n" +
            "Vi vill med detta meddela dig om att en förfrågan på inbetalning har nu registrerats som inställd. Ni behöver därför inte betala in beloppet som anges nedan"
    },
    SHOULD_BE_REFUNDED: {
        value: 'SHOULD_BE_REFUNDED',
        text: "Ska återbetalas",
        color: "warning"
    },
}
const STATUSES_ARRAY = [
    {
        value: 'NOT_SENT',
        text: "Ej skickad",
        color: "grey"
    },
    {
        value: 'SENT',
        text: "Förfrågan skickad",
        color: "info"
    },
    {
        value: 'REMINDER_SENT',
        text: "Påminnelse skickad",
        color: "accent"
    },
    {
        value: 'PAYMENT_DUE',
        text: "Förfallen",
        color: "error"
    },
    {
        value: 'PAID',
        text: "Betald",
        color: "success",
    },
    {
        value: 'REFUNDED',
        text: "Återbetald",
        color: "primary"
    },
    {
        value: 'CANCELLED',
        text: "Inställd",
        color: "grey"
    },
    {
        value: 'SHOULD_BE_REFUNDED',
        text: "Ska återbetalas",
        color: "warning"
    },
]

export default {
    STATUSES,
    STATUSES_ARRAY
}
