<template>
    <div>
        <v-hover>
            <template v-slot:default="{ hover }">
                <v-avatar
                        :size="size"
                        class="mt-2 mb-6 clickable avatar-outline"
                        v-ripple
                        @click="avatarClick"
                        :tile="tile"
                >
                    <v-img
                            v-if="avatarSrc && avatarSrc.length > 0"
                            lazy-src="https://picsum.photos/10/6"
                            :src="avatarSrc" alt="avatar">
                        <template v-slot:placeholder>
                            <v-row
                                    class="fill-height ma-0"
                                    align="center"
                                    justify="center"
                            >
                                <v-progress-circular indeterminate
                                                     color="primary lighten-5"></v-progress-circular>
                            </v-row>
                        </template>
                    </v-img>
                    <v-icon :color="color" v-ripple @click="avatarClick" dark large v-else>mdi-camera-plus-outline
                    </v-icon>
                    <v-fade-transition>
                        <v-overlay
                                v-if="hover"
                                absolute
                                color="accent-darken"
                                opacity="0.9"
                        >
                            <span><b>{{helpText}}</b></span>
                        </v-overlay>
                    </v-fade-transition>
                </v-avatar>
            </template>
        </v-hover>
        <v-tooltip bottom>
            <template v-slot:activator="{ on }">
                <v-btn absolute @click="avatarEditClick" fab small class="elevation-2 white"
                       v-on="on"
                       v-if="editable && avatarSrc && avatarSrc.length > 0">
                    <v-icon color="accent-darken">mdi-crop-rotate</v-icon>
                </v-btn>
            </template>
            <span>Rotera eller beskär bild</span>
        </v-tooltip>
    </div>
</template>

<script>

    export default {
        components: {},
        props: {
            editable: Boolean,
            tile: Boolean,
            text: String,
            avatarSrc: String,
            size: {type: Number, default: 128,},
            color: {type: String, default: "primary"}
        },
        methods: {
            avatarClick()
            {
                this.$emit("avatarClick")
            },
            avatarEditClick()
            {
                this.$emit("avatarEditClick")
            }
        },
        computed: {
            helpText() {
                let value = this.avatarSrc && this.avatarSrc.length > 0 ? "Byt bild" : "Välj en bild"
                return this.text ? this.text : value
            }
        }
    }
</script>
