<template>
    <div>
        <p class="text-center mt-4">
            <v-icon color="grey" size="22px" :class="{'pulse-up': pulse}">{{icon}}</v-icon>
        </p>
        <p class="text-center grey--text mb-4 pa-1">{{text}}</p>
    </div>
</template>

<script>
    export default {
        props: {text: String, pulse: Boolean, icon: String}
    }
</script>

<style scoped>

</style>