var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"clickable-table",attrs:{"headers":_vm.headers,"items":_vm.customers,"search":_vm.search,"disable-pagination":"","hide-default-footer":"","fixed-header":"","no-results-text":("Din sökning på " + _vm.search + " gav inget resultat")},on:{"click:row":_vm.selectCustomer},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('no-data-component',{attrs:{"text":_vm.noDataText,"icon":_vm.noDataIcon,"pulse":_vm.noDataPulse}})]},proxy:true},{key:"item.avatar",fn:function(ref){
var item = ref.item;
return [_c('initials-avatar-component',{attrs:{"imgSrc":_vm.getImageUrl(item),"size":32,"firstname":item.firstname,"lastname":item.lastname}})]}},{key:"item.email",fn:function(ref){
var item = ref.item;
return [(item.email.length > 0)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","color":"secondary","to":{name: 'conversationsOverview', params: {customerId: item.id, newConversation: true}}}},on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-message-text-outline")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.email))])]):_vm._e()]}},{key:"item.phone",fn:function(ref){
var item = ref.item;
return [(item.phone.length > 0)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","color":"secondary","href":("tel:" + (item.phone))},on:{"click":function($event){$event.stopPropagation();}}},on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-phone")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.phone))])]):_vm._e()]}},(_vm.showFavorite)?{key:"item.favourite",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":item.mainContact ? 'primary':'grey',"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.setCustomerAsMainContact(item)}}},on),[_c('v-icon',[_vm._v(_vm._s(item.mainContact ? "mdi-heart" : "mdi-heart-outline"))])],1)]}}],null,true)},[_c('span',[_vm._v("Markera som huvudkund")])])],1)]}}:null],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }