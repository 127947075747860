<template>
  <v-card :flat="flat" :loading="loading" color="white">
    <v-card-text :class="{'px-0': flat, 'pt-0': true}">
      <div>
        <v-subheader class="caption">Obligatoriska fält</v-subheader>
        <v-text-field
            v-model="customer.email"
            :counter="100"
            :rules="emailRules"
            autocomplete="email"
            label="E-post"
            prepend-icon="mdi-email-outline"
            solo
            type="email"
        ></v-text-field>
        <v-slide-x-transition mode="out-in">
          <v-card v-if="customer && customer.id && !manualInput && !skipEmailCheck" outlined>
            <v-list-item dense>
              <v-list-item-avatar v-if="customer.fileData">
                <v-img
                    :src="getImageUrl(customer)"
                    alt="customer_avatar"
                ></v-img>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-card-title class="pl-4 pb-1 pt-2 subtitle-1 font-weight-medium"
                              style="word-break: break-word">
                  Hej {{ customer.firstname }}, kul att se dig igen!
                </v-card-title>
                <v-card-text class="pb-0" style="line-height: 1.6">Då du bokat genom Uthyrd tidigare så
                  behöver du inte ange mer än din e-postadress ✌️
                </v-card-text>
              </v-list-item-content>
            </v-list-item>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" text @click="setManualInput">
                {{ $vuetify.breakpoint.mobile ? 'Ange uppgifter på nytt' : 'Jag vill ange mina uppgifter på nytt' }}
              </v-btn>
            </v-card-actions>
          </v-card>
          <div v-else>
            <v-text-field
                v-model="customer.firstname"
                :counter="100"
                :rules="[v => !!v || 'Du måste ange ett förnamn']"
                autocomplete="fname"
                label="Förnamn"
                prepend-icon="mdi-account-outline"
                required
                solo
            ></v-text-field>
            <v-text-field
                v-model="customer.lastname"
                :counter="100"
                :rules="[v => !!v || 'Du måste ange ett efternamn']"
                autocomplete="lname"
                label="Efternamn"
                prepend-icon="icon"
                required
                solo
                @focus="showExtendedDetails = true"
            ></v-text-field>
            <v-slide-y-transition>
              <div v-show="showExtendedDetails" class="mt-3">
                <v-divider></v-divider>
                <v-subheader class="caption mt-3">Följande uppgifter är ej obligatoriska men snabbar på
                  bokningsprocessen om du väljer att fylla i de.
                </v-subheader>
                <v-text-field
                    v-model="customer.phone"
                    :counter="50"
                    autocomplete="tel"
                    class="mb-3"
                    label="Telefonnummer"
                    prepend-icon="mdi-phone-outline"
                    type="tel"
                ></v-text-field>
                <social-security-number-text-field-component
                    v-model="customer"
                    prepend-icon="mdi-account-lock-outline"
                ></social-security-number-text-field-component>
                <address-component :address.sync="customer.address" :opened-index="addressContent"
                                   class="mt-3 mb-2"
                                   flat outlined
                                   prepend-icon></address-component>
              </div>
            </v-slide-y-transition>
          </div>
        </v-slide-x-transition>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import AddressComponent from "../global/AddressComponent";
import SocialSecurityNumberTextFieldComponent from "../global/SocialSecurityNumberTextFieldComponent";
import Axios from "axios";
import api from "../../api/api";
import {getImageUrl} from "../../util/functions";

export default {
  components: {
    SocialSecurityNumberTextFieldComponent,
    AddressComponent,
  },
  data: () => ({
    loading: false,
    addressContent: 1,
    showExtendedDetails: false,
    timerId: null,
    manualInput: false,
    emailRules: [
      v => !!v && /.+@.+/.test(v) || 'Den angivna e-postadressen är inte giltig'
    ],
  }),
  props: {
    userAccountId: Number,
    value: Object,
    flat: Boolean,
    skipEmailCheck: Boolean,
    initCustomer: {type: Boolean, default: true},
    allInputs: {type: Boolean, default: false}

  },
  computed: {
    fullname()
    {
      return this.customer.firstname + " " + this.customer.lastname
    },
    customer: {
      get()
      {
        return this.value
      },
      set(val)
      {
        this.$emit('update:value', val)
      }
    },
  },
  watch: {
    'customer.email'(val)
    {
      if (!this.skipEmailCheck && val.indexOf("@") > 0 && !this.manualInput)
      {
        clearTimeout(this.timerId)
        this.timerId = setTimeout(async () =>
        {
          this.getCustomerFromEmail(val)
        }, 250)
      }
    }
  },
  async mounted()
  {
    if (this.initCustomer)
    {
      this.setInitCustomer("", "", "")
    }
    this.showExtendedDetails = this.allInputs
  },
  methods: {
    setInitCustomer(firstname, email)
    {
      this.customer = {
        firstname: firstname,
        active: 1,
        id: null,
        lastname: "",
        email: email,
        phone: "",
        fileData: null,
        address: {
          address1: "",
          address2: "",
          address3: "",
          postcode: "",
          city: "",
          country: "Sverige",
        }
      }
      this.addressContent = 1
      this.customer.userAccountId = this.userAccountId
    },
    async getCustomerFromEmail(email)
    {
      try
      {
        let response = await Axios.get(api.BASE_URL + api.API + api.ENDPOINTS.USERACCOUNTS + "/" + this.userAccountId + "/" + api.ENDPOINTS.CUSTOMERS + "/" + api.ENDPOINTS.EMAIL + "/" + email,
            {
              headers: {
                [api.HEADERS.ContentType]: [api.HEADERS.ApplicationJson]
              }
            })
        let data = response.data
        if (data)
        {
          this.customer = data
        }
      }
      catch (error)
      {
        // just ignore errors
      }
    },
    getImageUrl(item)
    {
      return getImageUrl(item)
    },
    setManualInput()
    {
      let email = this.customer.email
      let firstname = this.customer.firstname
      this.manualInput = true
      this.showExtendedDetails = true

      this.setInitCustomer(firstname, email)
    }
  }
}
</script>
