<template>
  <v-card :loading="loading" :disabled="loading">
    <v-card-text>
      <v-container grid-list-xl>
        <v-layout row wrap>
          <v-flex xs12>
            <h3 data-cy="bookingObjectSetupHeader">Härnäst behöver du lägga in minst ett uthyrningsobjekt som du vill hyra ut (det går även att
              lägga in fler senare)</h3>
          </v-flex>
          <v-flex xs12 v-if="user">
            <v-slide-x-transition mode="out-in">
              <booking-objects-overview flat setup @newBookingObject="newBookingObject"
                                        @editBookingObject="editBookingObject"
                                        v-if="overview"></booking-objects-overview>
              <booking-object-details-component flat setup :edit-id="editId" @goBack="goBack"
                                                v-else></booking-object-details-component>
            </v-slide-x-transition>
          </v-flex>
        </v-layout>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-btn
          @click="$emit('previousStep')"
      >
        <v-icon class="mr-2">mdi-chevron-left</v-icon>
        Tillbaka
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn  data-cy="step3NextBtn" @click="nexStep" color="secondary">Nästa
        <v-icon class="ml-2">mdi-chevron-right</v-icon>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import api from "../../api/api";
import BookingObjectsOverview from "../../views/bookingobjects/BookingObjectsOverview";
import BookingObjectDetailsComponent from "../bookingobject/BookingObjectDetailsComponent";

export default {
  components: {BookingObjectDetailsComponent, BookingObjectsOverview},
  data: () => ({
    loading: false,
    overview: true,
    editId: null,
  }),
  computed: {
    user()
    {
      return this.$store.state.user
    }
  },
  methods: {
    async nexStep()
    {
      if (await this.bookingObjectExists())
      {
        this.$emit('nextStep')
      }
      else
      {
        this.$store.commit('setSnackbar', {
          color: "orange darken-1",
          text: "Du måste lägga in minst ett uthyrningsobjekt innan du kan fortsätta"
        })
      }
    },
    async bookingObjectExists()
    {
      this.loading = true
      let serverResponse = await api.getFromEndpoint(api.ENDPOINTS.USERACCOUNTS + "/" + this.$store.state.user.id + "/" + api.ENDPOINTS.BOOKINGOBJECTS)
      this.loading = false
      return serverResponse && serverResponse.length > 0 && serverResponse[0].id != null
    },
    goBack()
    {
      this.overview = true
    },
    newBookingObject()
    {
      this.overview = false
      this.editId = null
    },
    editBookingObject(bookingObject)
    {
      this.overview = false
      this.editId = bookingObject.id
    }
  }
}
</script>

<style scoped>

</style>
