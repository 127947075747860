const types = {
    BOOKING_PERIOD: "BOOKING_PERIOD",
    CUSTOMER: "CUSTOMER",
    CONVERSATION: "CONVERSATION"
}
const typesArray = [
    {text: "Uthyrningsperioder", value: types.BOOKING_PERIOD, icon: 'mdi-calendar-search'},
    {text: "Kunder", value: types.CUSTOMER, icon: 'mdi-account-outline'},
    {text: "Meddelanden", value: types.CONVERSATION, icon: 'mdi-message-text-outline'}
]

export default {
    types,
    typesArray
}
