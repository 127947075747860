import store from "../store";
import action from "./action";
import api from "../api/api";

var socket = null
var timeout = null
var timeoutSeconds = 35000; // every 35 seconds

// Logs some stuff and initiates keep-alive polling
function onOpen()
{
    keepConnectionAlive()
}

function onMessage(event)
{
    let data = JSON.parse(event.data)
    // TODO - maybe rework difference between Notification and WebSocketMessage a bit
    if (data.action) // if data has an action it's a WebSocketMessage
    {
        action.handleWebSocketMessage(data)
    }
    else if (data.message && data.title) // Otherwise if it has a message and a title it's a notification
    {
        let btnText = data.actions && data.actions.length > 0 ? data.actions[0].title : null
        store.commit('setSnackbar', {
            color: "primary",
            title: data.title,
            text: data.message,
            url: data.url,
            btnText: btnText,
            timeout: 10000 // 10 sec
        })
    }
}

function onClose(event)
{
    if (event.wasClean)
    {
        // eslint-disable-next-line no-console
        console.log(`Websocket - [close] Connection closed cleanly, code=${event.code} reason=${event.reason}`);
    }
    else
    {
        // e.g. server process killed or network down
        // event.code is usually 1006 in this case
        // eslint-disable-next-line no-console
        console.warn('Websocket - [close] Connection died');
    }
    clearTimeout(timeout)
}

function onError(error)
{
    store.commit('setSnackbar', {color: "error", text: "Websocket error: " + error})
}

// Sends a message on a regular interval to keep the connection from timing out
function keepConnectionAlive()
{
    // clear any timeouts that may be running
    clearTimeout(timeout);
    let msg = {msg: "Ping!"}
    socket.send(JSON.stringify(msg));
    timeout = setTimeout(keepConnectionAlive, timeoutSeconds);
}

// Initiates websocket and sets functions
function initiateWebSocketConnection()
{
    let method = process.env.NODE_ENV === 'production' ? 'wss' : 'ws'
    socket = new WebSocket(method + "://" + api.BASE_DOMAIN + "/ws");
    socket.onerror = onError;
    socket.onclose = onClose;
    socket.onmessage = onMessage;
    socket.onopen = onOpen
}


export default {
    initiateWebSocketConnection
}
