<template>
  <v-app>
    <login-view v-if="initialRoute === 'login'"></login-view>
    <reset-password-view v-else-if="initialRoute === 'changePassword'"></reset-password-view>
    <register-view v-else-if="initialRoute === 'registerUser'"></register-view>
    <confirm-email-view v-else-if="initialRoute === 'confirmEmail'"></confirm-email-view>
    <external-calendar-booking-view v-else-if="initialRoute === 'externalBooking'"></external-calendar-booking-view>
    <external-customer-details-view v-else-if="initialRoute === 'externalCustomer'"></external-customer-details-view>
    <website-editor-view v-else-if="initialRoute === 'editWebsite'"></website-editor-view>
    <external-website-view v-else-if="initialRoute === 'externalWebsite'"></external-website-view>
    <app-component v-else></app-component>
    <v-fade-transition>
      <v-overlay absolute :value="overlay.show" :opacity="overlay.opacity"></v-overlay>
    </v-fade-transition>
  </v-app>
</template>

<script>

import AppComponent from "./components/global/AppComponent";
import LoginView from "./views/external/LoginView";
import ResetPasswordView from "./views/external/ChangePasswordView";
import RegisterView from "./views/external/RegisterView";
import ConfirmEmailView from "./views/external/ConfirmEmailView";
import ExternalCalendarBookingView from "./views/external/ExternalCalendarBookingView";
import ExternalCustomerDetailsView from "./views/external/ExternalCustomerDetailsView";
import WebsiteEditorView from "@/views/website/WebsiteEditorView";
import ExternalWebsiteView from "@/views/website/ExternalWebsiteView";
import api from "@/api/api";

export default {
  components: {
    ExternalWebsiteView,
    WebsiteEditorView,
    ExternalCustomerDetailsView,
    ExternalCalendarBookingView,
    ConfirmEmailView, RegisterView, ResetPasswordView, LoginView, AppComponent
  },
  data: () => ({}),
  computed: {
    initialRoute() // is not reactive
    {
      return this.$router.currentRoute.name
    },
    overlay() {
      return this.$store.state.globalOverlay
    }
  }
}
</script>

<style>
.fill-width {
  width: 100%;
}

.highlight-pulse {
  box-shadow: 0 0 0 rgba(255, 228, 234, 0.7);
  animation: highlight-pulse 2s infinite;
}

.highlight-pulse:hover {
  animation: none;
}

@-webkit-keyframes highlight-pulse {
  0% {
    -webkit-box-shadow: 0 0 0 rgba(255, 228, 234, 0.7);
  }
  70% {
    -webkit-box-shadow: 0 0 0 10px rgba(196, 137, 150, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(196, 137, 150, 0);
  }
}

@keyframes highlight-pulse {
  0% {
    -moz-box-shadow: 0 0 0 rgba(255, 228, 234, 0.7);
    box-shadow: 0 0 0 rgba(255, 228, 234, 0.7);
  }
  70% {
    -moz-box-shadow: 0 0 0 10px rgba(196, 137, 150, 0);
    box-shadow: 0 0 0 10px rgba(196, 137, 150, 0);
  }
  100% {
    -moz-box-shadow: 0 0 0 0 rgba(196, 137, 150, 0);
    box-shadow: 0 0 0 0 rgba(196, 137, 150, 0);
  }
}

.highlight-pulse-extended {
  box-shadow: 0 0 0 rgba(255, 228, 234, 0.9);
  animation: highlight-pulse-extended 2s infinite;
}

@-webkit-keyframes highlight-pulse-extended {
  0% {
    -webkit-box-shadow: 0 0 0 rgba(255, 228, 234, 0.9);
  }
  70% {
    -webkit-box-shadow: 0 0 0 40px rgba(196, 137, 150, 0.9);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(196, 137, 150, 0);
  }
}

@keyframes highlight-pulse-extended {
  0% {
    -moz-box-shadow: 0 0 0 rgba(255, 228, 234, 0.9);
    box-shadow: 0 0 0 rgba(255, 228, 234, 0.9);
  }
  70% {
    -moz-box-shadow: 0 0 0 40px rgba(196, 137, 150, 0);
    box-shadow: 0 0 0 40px rgba(196, 137, 150, 0);
  }
  100% {
    -moz-box-shadow: 0 0 0 0 rgba(196, 137, 150, 0);
    box-shadow: 0 0 0 0 rgba(196, 137, 150, 0);
  }
}

.pulse-up {
  -webkit-animation: pulse-up 0.5s cubic-bezier(0.390, 0.575, 0.565, 1.000) infinite alternate both;
  animation: pulse-up 0.5s cubic-bezier(0.390, 0.575, 0.565, 1.000) infinite alternate both;
}

@-webkit-keyframes pulse-up {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
  }
}

@keyframes pulse-up {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
  }
}

</style>
