<template>
  <v-card flat v-if="section && section.data">
    <v-subheader class="pl-0">Välj vilka attribut du vill visa</v-subheader>
    <v-list dense>
      <v-checkbox class="pt-0 mt-0" :value="allMarked" :label="allMarked ? 'Avmarkera alla' : 'Markera alla'"
                  @change="markUnmarkAll"></v-checkbox>
      <v-list-item-group
          color="accent"
          multiple
          v-model="selectedAttributeIds"
      >
        <no-data-component
            v-if="!attributes || attributes.length === 0"
            text="Det finns inga attribut valda för ditt uthyrningsobjekt. Välj några attribut först för att kunna visa de här."
            icon="mdi-tray-alert"></no-data-component>
        <div :key="key" v-for="[key, value] in Object.entries(attributesByGroup)">
          <v-subheader>{{ key }}</v-subheader>
          <v-list-item
              v-for="item in value"
              :key="'attribute_' + item.id"
              :value="item.attribute.id"
          >
            <v-list-item-icon>
              <v-icon>{{ item.attribute.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ item.attribute.name }}</v-list-item-title>
              <v-list-item-subtitle v-if="item.attribute.hasValue === 1">
                {{ item.attribute.valueUnit }}: {{ item.value }}
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-slide-x-transition>
                <v-icon v-if="selectedAttributeIds.findIndex((selected) => selected === item.attribute.id) >= 0">
                  mdi-check
                </v-icon>
              </v-slide-x-transition>
            </v-list-item-action>
          </v-list-item>
        </div>
      </v-list-item-group>
    </v-list>
    <v-alert class="mt-2" type="error" dense border="right" elevation="1" colored-border transition="slide-x-transition"
             :value="!section.data.bookingObjectAttributes || section.data.bookingObjectAttributes.length === 0">
      <span
          class="text-left grey--text text--darken-2">Du måste välja minst ett attribut för att kunna spara sektionen</span>
    </v-alert>
    <v-text-field readonly v-show="false" :rules="requiredRules"
                  v-model="selectedAttributeIds.length"></v-text-field>
    <v-checkbox v-model="section.data.listView" label="Visa som lista"></v-checkbox>
    <v-checkbox v-model="section.data.grouped" label="Gruppera" hint="Om attributen ska visas grupperade med gruppens namn som rubrik"></v-checkbox>
  </v-card>
</template>

<script>

import NoDataComponent from "@/components/global/NoDataComponent";

export default {
  components: {NoDataComponent},
  data: () =>
  {
    return {
      selectedAttributeIds: [],
      requiredRules: [
        v => !!v && v > 0 || 'Obligatoriskt fält'
      ]
    }
  },
  props: {
    value: Object,
    bookingObject: Object,
  },
  computed: {
    section: {
      get()
      {
        return this.value
      },
      set(val)
      {
        this.$emit('update:value', val)
      }
    },
    attributes()
    {
      return this.bookingObject.attributes
    },
    allMarked()
    {
      return this.selectedAttributeIds.length === this.attributes.length
    },
    attributesByGroup()
    {
      return this.attributes.reduce((r, a) =>
      {
        r[a.attribute.attributeGroup.name] = [...r[a.attribute.attributeGroup.name] || [], a];
        return r;
      }, {});
    }
  },
  async mounted()
  {
    this.handleInitData()
  },
  watch: {
    selectedAttributeIds()
    {
      this.section.data.bookingObjectAttributes = []
      this.setSectionAttributesFromSelected()
    },
    'section.data.bookingObjectAttributes':
        {
          handler(val)
          {
            if (val.length > 0 && this.selectedAttributeIds.length === 0)
            {
              this.setSelectedFromSectionData()
            }
          },
          immediate: true
        }
  },
  methods: {
    handleInitData()
    {
      if (!this.section.data)
      {
        this.section.data = {
          bookingObjectAttributes: [],
          listView: false,
          grouped: true
        }
      }
    },
    markUnmarkAll()
    {
      let lengthBeforeClear = this.selectedAttributeIds.length
      this.selectedAttributeIds = []
      if (lengthBeforeClear !== this.attributes.length)
      {
        for (let i = 0; i < this.attributes.length; i++)
        {
          this.selectedAttributeIds.push(this.attributes[i].attribute.id)
        }
      }
    },
    setSectionAttributesFromSelected()
    {
      for (let i = 0; i < this.selectedAttributeIds.length; i++)
      {
        this.section.data.bookingObjectAttributes.push(this.attributes.find((item) => item.attribute.id === this.selectedAttributeIds[i]))
      }
    },
    setSelectedFromSectionData()
    {
      let lengthBeforeClear = this.selectedAttributeIds.length
      this.selectedAttributeIds = []
      let attrs = this.section.data.bookingObjectAttributes

      if (lengthBeforeClear !== attrs.length)
      {
        for (let i = 0; i < attrs.length; i++)
        {
          this.selectedAttributeIds.push(attrs[i].attribute.id)
        }
      }
    }
  }
}
</script>
