<template>
  <v-autocomplete
      :clearable="clearable"
      :disabled="readOnly"
      :items="conversations"
      :loading="loading"
      :multiple="multiple"
      :required="required"
      :rules="rules"
      :search-input.sync="searchQuery"
      @change="change"
      @keyup.enter="keyupEnter"
      item-text="name"
      item-value="id"
      :hide-no-data="((!searchQuery || searchQuery.length === 0) && conversations.length === 0) || loading"
      no-data-text="Din sökning gav inga resultat"
      :placeholder="asyncSearch ? 'Sök efter konversation' : 'Välj konversation'"
      return-object
      v-model="conversationValue"
  >
    <template v-slot:item="data">
      <template>
        <conversation-list-item-component
            :item="data.item"
            disable-navigation
            style="width: 200px"
        >
          <!-- Width is needed here otherwise content might expand too far -->
        </conversation-list-item-component>
      </template>
    </template>
  </v-autocomplete>
</template>

<script>
import api from "../../api/api";
import ConversationListItemComponent from "./ConversationListItemComponent";

export default {
  components: {ConversationListItemComponent},
  data: () =>
  {
    return {
      conversations: [],
      searchQuery: "",
      loading: false,
      baseEndpoint: null,
      timerId: 0
    }
  },
  props: {
    value: Object, // value.conversations must be an array if multiple, otherwise value.conversation should be an object
    readOnly: Boolean,
    required: Boolean,
    multiple: Boolean,
    clearable: Boolean,
    bookingPeriod: Object,
    conversationsFromBookingPeriod: Boolean,
    asyncSearch: Boolean
  },
  computed: {
    rules()
    {
      return this.required ? [v => !!v || 'Du måste välja en konversation'] : []
    },
    conversationValue: {
      get()
      {
        return this.multiple ? this.value.conversations : this.value.conversation
      },
      set(val)
      {
        if (this.multiple)
        {
          this.value.conversations = val
        }
        else
        {
          this.value.conversation = val
        }
      }
    }
  },
  async mounted()
  {
    this.baseEndpoint = this.conversationsFromBookingPeriod ? api.ENDPOINTS.BOOKINGPERIODS + "/" + this.bookingPeriod.id : api.ENDPOINTS.USERACCOUNTS + "/" + this.$store.state.user.id
    if (!this.asyncSearch)
    {
      this.loading = true
      let serverResponse = await api.getFromEndpoint(this.baseEndpoint + "/" + api.ENDPOINTS.CONVERSATIONS + "?includeArchived=false")
      if (serverResponse)
      {
        this.conversations = serverResponse
      }
      this.loading = false
    }
  },
  watch: {
    searchQuery(val)
    {
      if (val && val.length > 0)
      {
        this.searchForConversation(val)
      }
    },
  },
  methods: {
    keyupEnter()
    {
      this.$emit("keyupEnter")
    },
    change(data)
    {
      this.$emit("change", data)
    },
    async searchForConversation(searchQuery)
    {
      clearTimeout(this.timerId)
      this.timerId = setTimeout(async () =>
      {
        this.loading = true
        const fromDate = this.$moment().subtract(1, 'year').format('YYYY-MM-DD')
        let serverResponse = await api.getFromEndpoint(this.baseEndpoint + "/" + api.ENDPOINTS.CONVERSATIONS + "?includeArchived=true&query=" + searchQuery + "&fromDate=" + fromDate)
        if (serverResponse)
        {
          this.conversations = serverResponse
        }
        this.loading = false
      }, 250)
    }
  }
}
</script>
<style scoped>
.truncate {
  max-width: 100px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
