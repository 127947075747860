<template>
    <v-text-field
            :label="label"
            :prepend-icon="icon"
            :type="inputType"
            :data-cy="cyTag"
            autocomplete="current-password"
            @focus="$emit('focus')"
            @keyup.enter="$emit('keyupEnter')"
            @change="$emit('change')"
            @keydown="$emit('keydown')"
            :rules="rules"
            v-model="password">
        <template v-slot:append>
            <v-btn v-if="allowVisible" icon tabindex="-1" @click="passwordVisible = !passwordVisible">
                <v-icon>{{inputIcon}}</v-icon>
            </v-btn>
        </template>
    </v-text-field>
</template>

<script>
    export default {
        props: {
            cyTag: String,
            value: String,
            label: String,
            icon: String,
            rules: Array,
            allowVisible: {
                type: Boolean,
                default: true
            }
        },
        data: () => ({
            passwordVisible: false,
        }),
        computed: {
            password: {
                get()
                {
                    return this.value
                },
                set(val)
                {
                    this.$emit('update:value', val)
                }
            },
            inputType()
            {
                return this.passwordVisible ? 'text' : 'password'
            },
            inputIcon()
            {
                return this.passwordVisible ? 'mdi-eye-off-outline' : 'mdi-eye-outline'
            },
        }
    }
</script>

