<template>
    <v-slide-x-transition mode="out-in">
        <v-alert
                color="success"
                icon="done"
                outlined
                :value="true"
                v-if="resetMailSent"
        >
            Ett mail med instruktioner på hur man byter lösenord har
            skickats till
            din epostadress ({{email}})
        </v-alert>
        <v-alert
                color="error"
                icon="warning"
                outlined
                :value="true"
                v-else-if="error"
        >
            <p>{{errorMsg}}</p>
            <v-btn @click="error = false" outlined color="error">
                Försök igen
            </v-btn>
        </v-alert>
        <v-btn @click="resetPassword()" :loading="loading" v-else
               :disabled="!(email && email.length > 0 && email.includes('@'))"
               :color="color">
            Återställ lösenord
            <v-icon right>mdi-lock-open-outline</v-icon>
        </v-btn>
    </v-slide-x-transition>
</template>

<script>
    import api from "../../api/api";
    import Axios from "axios";

    export default {
        name: "ResetPasswordButton",
        data: () => ({
            resetMailSent: false,
            loading: false,
            errorMsg: "",
            error: false
        }),
        methods: {
            async resetPassword()
            {
                this.error = false
                this.loading = true
                // custom API call here to handle errors
                try
                {
                    let response = await Axios.post(api.BASE_URL + api.API + api.ENDPOINTS.ACCOUNTLOGIN, {"email": this.email})
                    if(response)
                    {
                        this.resetMailSent = true
                        this.loading = false
                    }
                }
                catch (error)
                {
                    this.error = true
                    this.errorMsg = "Kunde inte återställa lösenordet. Den epostadress du har angett är inte registrerad hos Uthyrd eller felaktig."
                    this.resetMailSent = false
                    this.loading = false
                }
            },
        },
        props: {color: String, email: String}
    }
</script>

<style scoped>

</style>
