<template>
  <v-container grid-list-xl>
    <v-layout row wrap>
      <v-flex xs12>
        <transition appear name="slide-x-transition">
          <h2 data-cy="homeWelcomeMessage" v-if="!isWelcomeMessageShown" class="grey--text text-darken-3">{{ title }}</h2>
        </transition>
      </v-flex>
      <v-flex xs12>
        <v-item-group
            v-model="activeItem"
            mandatory
            class="mb-3 d-flex justify-center"
        >
          <v-item :key="item.tooltip" v-for="item in items" v-slot="{ active, toggle }">
            <div
                :class="{'text-center': true, 'mr-2': $vuetify.breakpoint.smAndDown, 'mr-4': $vuetify.breakpoint.mdAndUp}">
              <v-badge
                  color="grey"
                  icon="mdi-lock"
                  overlap
                  :content="item.badgeValue"
                  :value="!active && item.badgeValue"
              >
                <v-btn
                    :input-value="active"
                    fab
                    tile
                    :color="active ? 'primary' : 'white'"
                    @click="toggle"
                    elevation="2"
                >
                  <v-icon :color="active ? 'white' : 'grey'" dark>{{ item.icon }}</v-icon>
                </v-btn>
              </v-badge>
              <p class="pt-3 caption grey--text">{{ item.tooltip }}</p>
            </div>
          </v-item>
        </v-item-group>
        <v-progress-linear
            :value="Math.floor(100 * (currentTime / DEFAULT_COUNTDOWN))"
            background-opacity="0.1"
            style="max-width: 50%; opacity: 0.25"
            class="mt-2 mx-auto"
            color="primary"
            :active="showProgress"
        ></v-progress-linear>
      </v-flex>
      <v-flex
          xs12
          align-self-center
          @mouseover="hovering = true"
          @mouseleave="hovering = false"
      >
        <v-window
            v-model="activeItem"
            vertical
            class="elevation-3"
        >
          <v-window-item eager>
            <event-log-component :count.sync="items[0].badgeValue" :elevation="6" title="Dina senaste händelser"></event-log-component>
          </v-window-item>
          <v-window-item eager>
            <latest-messages-component :count.sync="items[1].badgeValue"></latest-messages-component>
          </v-window-item>
          <v-window-item eager>
            <upcoming-booking-periods-component :count.sync="items[2].badgeValue"></upcoming-booking-periods-component>
          </v-window-item>
          <v-window-item>
            <occupancy-statistics-component></occupancy-statistics-component>
          </v-window-item>
          <v-window-item>
            <income-statistics-component></income-statistics-component>
          </v-window-item>
        </v-window>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import OccupancyStatisticsComponent from "../components/dashboardcomponents/OccupancyStatisticsComponent";
import LatestMessagesComponent from "../components/dashboardcomponents/LatestMessagesComponent";
import UpcomingBookingPeriodsComponent from "../components/dashboardcomponents/UpcomingBookingPeriodsComponent";
import EventLogComponent from "../components/global/eventlog/EventLogComponent";
import IncomeStatisticsComponent from "../components/dashboardcomponents/IncomeStatisticsComponent";


export default {
  components: {
    IncomeStatisticsComponent,
    EventLogComponent,
    UpcomingBookingPeriodsComponent,
    LatestMessagesComponent, OccupancyStatisticsComponent
  },
  data: () => ({
    showProgress: true,
    activeItem: 0,
    currentTime: 0,
    hovering: false,
    DEFAULT_COUNTDOWN: 12 * 1000,
    items: [
      {
        icon: "mdi-timeline",
        tooltip: "Händelser",
        badgeValue: null
      },
      {
        icon: "mdi-message",
        tooltip: "Meddelanden",
        badgeValue: null
      },
      {
        icon: "mdi-calendar",
        tooltip: "Perioder",
        badgeValue: null,
      },
      {
        icon: "mdi-chart-timeline-variant",
        tooltip: "Beläggning",
        badgeValue: null
      },
      {
        icon: "mdi-currency-usd",
        tooltip: "Inkomst",
        badgeValue: null
      }
    ]
  }),
  watch: {
    activeItem()
    {
      this.currentTime = 0
    }
  },
  computed: {
    user()
    {
      return this.$store.state.user
    },
    isWelcomeMessageShown()
    {
      return this.$store.state.welcomeMessageShown
    },
    title()
    {
      return this.getGreetingFromTimePeriod() + ' ' + this.user.firstname + '!'
    }
  },
  beforeRouteLeave(to, from, next)
  {
    // when navigating away from the home view we hide the welcome message
    if (!this.isWelcomeMessageShown)
    {
      this.$store.commit('setWelcomeMessageShown', true)
    }
    next()
  },
  mounted()
  {
    this.cycleCountdown()
  },
  methods: {
    getGreetingFromTimePeriod()
    {
      let greeting = "Hej "
      let currentHour = this.$moment().hour()
      if (this.isHourBetween(6, 9, currentHour))
      {
        greeting = "God morgon "
      }
      else if (this.isHourBetween(9, 11, currentHour))
      {
        greeting = "God förmiddag "
      }
      else if (this.isHourBetween(11, 14, currentHour))
      {
        greeting = "God middag "
      }
      else if (this.isHourBetween(14, 18, currentHour))
      {
        greeting = "God eftermiddag "
      }
      else if (this.isHourBetween(18, 23, currentHour))
      {
        greeting = "God kväll "
      }
      return greeting
    },
    isHourBetween(min, max, hour)
    {
      return hour >= min && hour <= max
    },
    cycleCountdown()
    {
      //Create a timeout every 100 miliseconds
      setTimeout(() =>
      {
        if (!this.hovering)
        {
          //Increment the time counter by 100
          this.currentTime += 100;
        }

        //If our current time is larger than the threshold
        if (this.DEFAULT_COUNTDOWN <= this.currentTime)
        {
          this.showProgress = false
          //Wait 500 miliseconds for the dom to catch up, then reset the counter and cycle
          setTimeout(() =>
          {
            this.cycleActiveItem()
            this.currentTime = 0; // reset the current time
            this.showProgress = true
            this.$nextTick(() => this.cycleCountdown())
          }, 500);
        }
        else
        {
          //Recursively update the progress bar
          this.cycleCountdown();
        }
      }, 100);
    },
    countDownTimer()
    {
      if (this.countdown > 0)
      {
        this.timeout = setTimeout(() =>
        {
          if (!this.hovering)
          {
            this.countdown -= 0.25
            this.setCycleProgressValue()
          }
          this.countDownTimer()
        }, 250)
      }
      else if (this.countdown <= 0)
      {
        clearTimeout(this.timeout)
        this.cycleActiveItem()
        this.setCycleProgressValue()
        this.$nextTick(() =>
        {
          this.countDownTimer()
        })
      }
    },
    cycleActiveItem()
    {
      this.activeItem === this.items.length ? this.activeItem = 0 : this.activeItem++
    },
  }
}
</script>
