const types = {
    USER_ACCOUNT: "USER_ACCOUNT",
    BOOKING_PERIOD: "BOOKING_PERIOD",
    CUSTOMER: "CUSTOMER",
    CONVERSATION: "CONVERSATION",
    RECEIVED_EMAIL_MESSAGE: "RECEIVED_EMAIL_MESSAGE"
}

export default {
    types
}
