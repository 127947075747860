<template>
    <div>
        <v-autocomplete
                label="Ikon"
                :items="icons"
                v-bind:value="value"
                dense
                solo
                clearable
                @change="$emit('input', $event)"
                item-value="icon"
                hint="Mer info om ikonerna finns att hitta här: https://materialdesignicons.com/"
                persistent-hint
                class="mb-2"
        >
            <template slot="selection" slot-scope="data">
                <v-flex xs2>
                    <v-icon>{{data.item.icon}}</v-icon>
                </v-flex>
                <v-flex class='ml-1'>
                    {{ data.item.text }}
                </v-flex>
            </template>
            <template slot="item" slot-scope="data">
                <v-list-item-avatar>
                    <v-icon>{{data.item.icon}}</v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                    <v-list-item-title>
                        {{ data.item.text }}
                    </v-list-item-title>
                </v-list-item-content>
            </template>
        </v-autocomplete>
    </div>
</template>

<script>
    import icons from "../../assets/icons";

    export default {
        computed: {
            icons()
            {
                return icons.icons
            }
        },
        props: ["value"]
    }
</script>

<style scoped>

</style>
