<template>
  <div v-if="section && section.data">
    <v-textarea
        rows="6"
        outlined
        auto-grow
        no-resize
        :rules="requiredRules"
        v-model="section.data.text"
        prepend-icon="mdi-format-text"
        hint="Texten som paragrafen skall innehålla"
        label="Text">
    </v-textarea>
    <v-text-field
        class="mb-2" value="16" v-model="section.data.fontSize" suffix="px" label="Teckenstorlek"
        prepend-icon="mdi-format-size"
        hint="Storlek på texten"
        :rules="requiredRules"
        type="number"></v-text-field>
    <v-subheader class="pl-0">Justering</v-subheader>
    <v-btn-toggle color="accent" dense mandatory class="pl-8" v-model="section.data.justify">
      <v-btn value="left">
        <v-icon>mdi-format-align-left</v-icon>
      </v-btn>
      <v-btn value="center">
        <v-icon>mdi-format-align-center</v-icon>
      </v-btn>
      <v-btn value="right">
        <v-icon>mdi-format-align-right</v-icon>
      </v-btn>
      <v-btn value="justify">
        <v-icon>mdi-format-align-justify</v-icon>
      </v-btn>
    </v-btn-toggle>
    <v-subheader class="pl-0">Stil</v-subheader>
    <v-btn-toggle
        v-model="section.data.fontStyles"
        dense
        color="accent"
        multiple
        class="pl-8"
    >
      <v-btn value="font-weight-bold">
        <v-icon>mdi-format-bold</v-icon>
      </v-btn>

      <v-btn value="font-italic">
        <v-icon>mdi-format-italic</v-icon>
      </v-btn>

      <v-btn value="text-decoration-underline">
        <v-icon>mdi-format-underline</v-icon>
      </v-btn>
    </v-btn-toggle>
    <v-expansion-panels :value="1" flat class="outlined mt-8 mb-5">
      <v-expansion-panel>
        <v-expansion-panel-header>
                <span>
                    Färg
                </span>
        </v-expansion-panel-header>
        <v-expansion-panel-content class="elevation-0">
          <v-color-picker
              dot-size="20"
              mode="hexa"
              class="pl-8"
              v-model="section.data.color"
          ></v-color-picker>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>

export default {
  data: () =>
  {
    return {
      requiredRules: [
        v => !!v || 'Obligatoriskt fält'
      ]
    }
  },
  props: {
    value: Object,
    website: Object,
  },
  computed: {
    section: {
      get()
      {
        return this.value
      },
      set(val)
      {
        this.$emit('update:value', val)
      }
    }
  },
  async mounted()
  {
    this.handleInitData()
  },
  methods: {
    keyupEnter()
    {
      this.$emit("keyupEnter")
    },
    clear()
    {
      this.type = null
      this.$emit("change", this.type)
    },
    handleInitData()
    {
      if (!this.section.data)
      {
        this.section.data = {
          fontSize: 16,
          justify: "left",
          color: "#000000de",
          text: "",
          fontStyles: [],
        }
      }
    }
  }
}
</script>
