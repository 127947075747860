<template>
  <v-menu
      v-model="active"
      :position-x="xPos"
      :position-y="yPos"
      absolute
      offset-y
      transition="slide-y-transition"
      min-width="250"
  >
    <v-list v-if="attribute">
      <v-list-item two-line>
        <v-list-item-content>
          <v-list-item-title class="font-weight-bold">
            {{ attribute.name }}
          </v-list-item-title>
          <v-list-item-subtitle>
            {{ attribute.description }}
          </v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-avatar>
          <v-icon v-if="attribute.icon && attribute.icon.length > 0">{{ attribute.icon }}</v-icon>
        </v-list-item-avatar>
      </v-list-item>
      <v-divider></v-divider>
      <v-list-item @click="$emit('edit', attribute.id)">
        <v-list-item-title>Redigera</v-list-item-title>
        <v-list-item-icon>
          <v-icon color="primary">edit</v-icon>
        </v-list-item-icon>
      </v-list-item>
      <v-list-item @click="$emit('delete', attribute, false)">
        <v-list-item-title>Radera</v-list-item-title>
        <v-list-item-icon>
          <v-icon color="error">mdi-delete-forever-outline</v-icon>
        </v-list-item-icon>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  components: {},
  data: () => ({}),
  props: {
    attribute: Object,
    xPos: Number,
    yPos: Number,
    value: Boolean
  },
  computed: {
    active: {
      get()
      {
        return this.value
      },
      set(val)
      {
        this.$emit('update:value', val)
      }
    },
  }
}
</script>
