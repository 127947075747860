import { render, staticRenderFns } from "./TourAdminComponent.vue?vue&type=template&id=3103b9c1&scoped=true&"
import script from "./TourAdminComponent.vue?vue&type=script&lang=js&"
export * from "./TourAdminComponent.vue?vue&type=script&lang=js&"
import style0 from "./TourAdminComponent.vue?vue&type=style&index=0&id=3103b9c1&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3103b9c1",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBottomSheet } from 'vuetify/lib/components/VBottomSheet';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VFadeTransition } from 'vuetify/lib/components/transitions';
import { VHover } from 'vuetify/lib/components/VHover';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VOverlay } from 'vuetify/lib/components/VOverlay';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VBottomSheet,VBtn,VCard,VCardSubtitle,VCardText,VCardTitle,VDialog,VFadeTransition,VHover,VIcon,VList,VListItem,VListItemIcon,VOverlay,VSpacer})
