<template>
    <v-container fluid fill-height>
        <v-layout align-center justify-center>
            <v-flex xs12 sm8 md6>
                <v-card v-show="loaded" class="elevation-10">
                    <v-img :src="require('../../assets/403.jpg')"></v-img>
                    <v-card-title class="primary--text">
                        <h2>Stop!</h2>
                    </v-card-title>
                    <v-card-text>
                        <v-layout row wrap>
                            <p>Ditt konto har inte behörighet att se denna sida. Vänligen notera att alla försök
                                att
                                komma åt data utan behörighet loggas.</p>
                        </v-layout>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn class="ml-0" color="secondary" v-bind="btnProps">Gå till hem</v-btn>
                    </v-card-actions>
                </v-card>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
    export default {
        data: () => ({
            loaded: false
        }),
        mounted()
        {
            this.loaded = true
        },
        computed: {
            btnProps()
            {
                if (this.routerless)
                {
                    return {
                        href: "/"
                    }
                }
                return {
                    to: "/"
                }
            }
        },
        props: {routerless: Boolean}
    }
</script>

<style scoped>
</style>