const TYPES = {
    PREMIUM: {
        name: 'Premium',
        price: "129kr / månad (Vid betalning årsvis)",
        description: "<p>Premiumkonto för uthyrare! Du har full tillgång till alla funktioner och möjligheter som Uthyrd erbjuder 🤩</p>",
        hasSeats: true
    },
    PREMIUM_TEST: {
        name: 'Prova på premium',
        price: "129kr / månad (Vid betalning årsvis)",
        description: "<p>Ditt konto är det vi kallar för ett prova-på-konto. Under 25 dagar har du full tillgång till alla funktioner som Uthyrd erbjuder.</p>" +
            "<p>Använd tiden i lugn och ro för att testa om Uthyrd passar just dig och dina behov. Vi finns alltid där om du behöver hjälp eller har funderingar, " +
            "släng bara iväg ett mail till oss på <a href='mailto:support@uthyrd.se'>support@uthyrd.se</a> så hjälper vi dig.</p>" +
            "<p>Klicka på knappen nedan till höger för att uppgradera ditt konto när du känner dig redo!</p>",
        hasSeats: false
    },
    BASIC: {
        name: 'Basic',
        price: "0kr / månad",
        description: "<p>Premiumkonto för uthyrare! Du har full tillgång till alla funktioner och möjligheter som Uthyrd erbjuder 🤩</p>",
        hasSeats: false
    },
    PIONEER: {
        name: 'Pionjär',
        price: "0kr / månad",
        description: "<p>Som en av de första att testa Uthyrd har du blivit tilldelad ett konto av typen Pionjär. Denna\n" +
            "              gäller\n" +
            "              tills vidare och vid ändring av detta kommer vi att meddela dig.\n" +
            "            <p/>\n" +
            "            <p>I utbyte förväntar vi oss endast att du utnyttjar så många funktioner du kan och ger oss feedback på hur\n" +
            "              vi\n" +
            "              kan förbättra tjänsten 🌟</p>",
        hasSeats: false
    },
    PARTNER: {
        name: 'Partner',
        price: "0kr / månad",
        description: "<p>Standardkontot för partners! Du har full tillgång till alla funktioner och möjligheter som Uthyrd erbjuder för partners 🤩</p>",
        hasSeats: false
    }
}

export default {
    TYPES
}
