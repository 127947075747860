<template>
  <v-card :disabled="loading" flat>
    <v-select
        :items="types"
        @change="getEmailContent"
        label="Välj E-posttyp"
        v-model="selectedType"
        return-object
    ></v-select>
    <v-row>
      <v-col
          cols="12"
          md="6"
      >
        <v-textarea
            hint="Här kan du skriva den text som ska skickas med i utskicket av din valda typ av e-post"
            label="Egen text"
            no-resize
            required
            rows="5"
            v-model="emailContent.content"
        ></v-textarea>
      </v-col>
      <v-col
          cols="12"
          md="6"
      >
        <v-card>
          <v-card-subtitle>Exempel på hur utskicket ser ut:</v-card-subtitle>
          <v-card-text>
            <h4>Hej (kundens namn)!</h4>
            <p v-if="selectedType.template">{{ selectedType.template }}</p>
            <p class="grey--text" v-if="emailContent.content.length === 0">[Din text här]</p>
            <p v-else style="white-space: pre-line">{{ emailContent.content }}</p>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-spacer></v-spacer>
      <v-btn :disabled="loading" @click="saveEmailContent" class="mr-3" color="primary">Spara</v-btn>
    </v-row>
    <v-progress-linear :active="loading" class="mt-5" indeterminate></v-progress-linear>
  </v-card>
</template>

<script>
import api from "../../api/api";
import EmailContentTypes from "../../models/emailContentTypes";

export default {
  data: () => ({
    types: EmailContentTypes.TYPES_ARRAY,
    selectedType: EmailContentTypes.TYPES.CUSTOMER_DETAILS_REQUEST,
    loading: false,
    emailContent: {
      content: "",
      type: "",
    }
  }),
  computed: {
    stateUser()
    {
      return this.$store.state.user
    },
  },
  mounted()
  {
    this.getEmailContent()
  },
  methods: {
    async getEmailContent()
    {
      let response = await api.getFromEndpoint(api.ENDPOINTS.USERACCOUNTS + "/" + this.stateUser.id + "/" + api.ENDPOINTS.EMAILCONTENT + "/" + api.ENDPOINTS.TYPE + "/" + this.selectedType.value)
      if (response)
      {
        this.emailContent = response
      }
    },
    async saveEmailContent()
    {
      this.loading = true

      this.emailContent.userAccountId = this.stateUser.id
      this.emailContent.type = this.selectedType.value
      this.emailContent = await api.postToEndpoint(api.ENDPOINTS.EMAILCONTENT, this.emailContent)
      if (this.emailContent != null)
      {
        this.$store.commit('setSnackbar', {color: "success", text: "Ändringar sparade!"})
      }
      this.loading = false
    }
  }
}
</script>