<template>
  <v-dialog v-model="dialogProp" eager max-width="800px" persistent scrollable>
    <v-card :disabled="loading" :loading="loading" min-height="450px">
      <v-card-title class="primary--text">
        Välj prenumerationstyp
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-scroll-x-transition mode="out-in">
          <p v-if="loading" class="mt-10 mb-5 text-center grey--text">Hämtar data...</p>
          <div v-else-if="redirecting" class="text-center mt-16 pt-16">
            <v-icon class="mb-2 pulse-up" color="primary" large>mdi-credit-card-settings-outline</v-icon>
            <h3 class="text-center primary--text">Förbereder betalning...</h3>
          </div>
          <div v-else-if="manualHandling" class="text-center mt-16">
            <v-icon class="mb-2" color="error" large>mdi-credit-card-remove-outline</v-icon>
            <h3 class="text-center">Hoppsan! Något gick fel</h3>
            <p class="mt-2">Klicka på knappen nedan för att se över dina kortuppgifter och hantera
              misslyckade betalningar.</p>
            <p>Längst ned under rubriken Faktureringshistorik kan du se tidigare betalningsförsök. Klicka på datumet för
              att betala.</p>
            <h4 class="mt-2">Exempel:</h4>
            <div class="d-flex align-center justify-center container--fluid">
              <v-img :max-width="$vuetify.breakpoint.smAndUp ? '70%' : '100%'"
                     :src="require('../../../assets/failed_payment_example.png')" alt="Failed payment example"></v-img>
            </div>
            <div class="text-center mt-10 mb-8">
              <v-btn color="primary" @click="toStripeCustomerPortal">Hantera tidigare betalningar</v-btn>
            </div>
            <p class="grey--text">Fungerar det ändå inte? <a href="mailto:support@uthyrd.se">Kontakta oss</a> så hjälper
              vi dig!</p>
          </div>
          <v-tabs-items v-else v-model="currentTab">
            <v-form
                ref="form"
                @submit.prevent
            >
              <v-tab-item>
                <account-subscription-prices-component ref="prices" :price-valid.sync="priceValid"
                                                       :subscription.sync="accountSubscription"></account-subscription-prices-component>
              </v-tab-item>
            </v-form>
          </v-tabs-items>
        </v-scroll-x-transition>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-btn :disabled="redirecting" color="error" text @click="cancel">Avbryt</v-btn>
        <v-spacer></v-spacer>
        <v-btn
            :disabled="!priceValid || redirecting || !subscriptionChanged || manualHandling"
            color="primary"
            @click="submit">Gå vidare
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import api from "@/api/api";
import AccountSubscriptionPricesComponent
  from "@/components/useraccount/accountsubscription/AccountSubscriptionPricesComponent";

const stripeKey = process.env.NODE_ENV === 'production' ?
    'pk_live_51I7kSVEb6D3cJUjnwf4NtItz7pB8pvBw5CiOZJB0X4ZFWTy8Or8h8Pqyq0qhvrMqaW3F5GXjaIt5UH3NrqInxkuu00ckehNsFi' :
    'pk_test_51I7kSVEb6D3cJUjn7homtgSbDZvtP4CNAd93YMPI1LtdrlHfDei0xSxR8o8v1jSYBtxYWZiS5VZNVhgsSdpEtX1f00ToWdMc3r'

export default {
  components: {AccountSubscriptionPricesComponent},
  data: () => ({
    loading: false,
    redirecting: false,
    manualHandling: false,
    currentTab: 0,
    priceValid: false,
    dbSubscription: null,
  }),
  props: {
    dialog: Boolean,
    subscription: Object,
  },
  computed: {
    dialogProp: {
      get: function ()
      {
        return this.dialog;
      },
      set: function (newValue)
      {
        this.$emit('update:dialog', newValue)
      }
    },
    accountSubscription: {
      get: function ()
      {
        return this.subscription;
      },
      set: function (newValue)
      {
        this.$emit('update:subscription', newValue)
      }
    },
    hasSubscription()
    {
      return this.dbSubscription && this.dbSubscription.stripePriceId
    },
    subscriptionChanged()
    {
      if (this.hasSubscription)
      {
        return this.dbSubscription.stripePriceId !== this.accountSubscription.stripePriceId || this.dbSubscription.seats !== this.accountSubscription.seats
      }
      return true
    }
  },
  mounted()
  {
    this.dbSubscription = Object.assign({}, this.accountSubscription)
  },
  methods: {
    submit()
    {
      if (this.hasSubscription)
      {
        this.updateSubscription()
      }
      else
      {
        this.createStripeCheckout()
      }
    },
    async createStripeCheckout()
    {
      this.redirecting = true
      let serverResponse = await api.putToEndpoint(api.ENDPOINTS.USERACCOUNTS + "/" + this.$store.state.user.id + "/" + api.ENDPOINTS.STRIPE + "/" + api.ENDPOINTS.CHECKOUT
          + "?priceId=" + this.accountSubscription.stripePriceId
          + "&seats=" + this.accountSubscription.seats)
      if (serverResponse && serverResponse.sessionId)
      {
        // eslint-disable-next-line no-undef
        let stripe = Stripe(stripeKey);
        stripe.redirectToCheckout({
          sessionId: serverResponse.sessionId
        })
            .then((result) =>
            {
              if (result.error)
              {
                alert(result.error.message)
                this.redirecting = false
              }
            });
      }
    },
    async updateSubscription()
    {
      this.redirecting = true
      let serverResponse = await api.putToEndpoint(api.ENDPOINTS.ACCOUNTSUBSCRIPTIONS + "/" + this.dbSubscription.id + "/" + api.ENDPOINTS.STRIPE, this.accountSubscription)
      if (serverResponse)
      {
        if (serverResponse.updateSucceeded)
        {
          this.$store.commit('setSnackbar', {
            color: "success",
            text: "Prenumeration uppdaterad! Sidan laddas strax om..."
          })
          // just reload to make sure everything updates correctly
          location.reload()
        }
        else if (serverResponse.actionRequired)
        {
          this.redirectStripeActionRequired(serverResponse.stripePaymentIntent)
        }
        else if (serverResponse.manualHandlingRequired)
        {
          this.redirecting = false
          this.manualHandling = true
        }
        else
        {
          this.redirecting = false
          this.$store.commit('setSnackbar', {
            color: "error",
            text: serverResponse.message
          })
        }
      }
      else
      {
        this.$store.commit('setSnackbar', {
          color: "error",
          text: "Något gick fel! Kunde inte uppdatera din prenumeration"
        })
      }
    },
    cancel()
    {
      this.accountSubscription.seats = this.dbSubscription.seats
      this.accountSubscription.stripePriceId = this.dbSubscription.stripePriceId
      this.manualHandling = false
      this.$refs.prices.setPriceIndex()
      this.dialogProp = false
    },
    async redirectStripeActionRequired(paymentIntent)
    {
      // eslint-disable-next-line no-undef
      let stripe = Stripe(stripeKey);
      let result = await stripe.confirmCardPayment(paymentIntent, {setup_future_usage: "off_session"})
      if (result.error)
      {
        this.$store.commit('setSnackbar', {
          color: "error",
          text: "Något gick fel! Kunde inte uppdatera din prenumeration. Vänligen försök igen eller kontakta vår support om problemet kvarstår."
        })
      }
      else
      {
        this.$store.commit('setSnackbar', {
          color: "success",
          text: "Prenumeration uppdaterad! Sidan laddas strax om..."
        })
      }
      window.setTimeout(() => location.reload(), 2500)
    },
    toStripeCustomerPortal()
    {
      this.loading = true
      location.href = api.BASE_URL + api.API + api.ENDPOINTS.STRIPE + "/" + api.ENDPOINTS.PORTAL
    },
  }
}
</script>
