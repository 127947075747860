<template>
  <v-card flat :disabled="loading" :loading="loading">
    <v-card
        flat
    >
      <v-card-title>
        <v-icon
            large
            left
            color="primary"
        >
          mdi-chart-timeline-variant
        </v-icon>
        <span class="title textfont-weight-light primary--text">{{ title }}</span>
      </v-card-title>
      <v-sheet
          class="v-sheet--offset mx-auto"
          max-width="calc(100% - 32px)"
      >
        <v-sparkline
            :labels="occupancyStatistics.labels"
            :value="occupancyStatistics.values"
            auto-draw
            color="primary"
            line-width="2"
            padding="16"
        ></v-sparkline>
      </v-sheet>
      <v-card-text class="display-1 text-center font-weight-bold primary--text">
        {{ occupancyStatistics.totalOccupiedPercentage }}
      </v-card-text>
      <v-card-actions>
        <v-list-item class="grow">
          <v-list-item-content>
            <v-list-item-title>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-icon color="accent" class="mr-1" v-on="on">mdi-calendar-month</v-icon>
                </template>
                <span>Totalt antal perioder</span>
              </v-tooltip>
              <span class="subheading mr-2">{{ occupancyStatistics.totalNumberOfBookingPeriods }}</span>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-icon color="accent" class="mr-1" v-on="on">mdi-calendar-check-outline</v-icon>
                </template>
                <span>Uthyrda perioder</span>
              </v-tooltip>
              <span class="subheading mr-2">{{ occupancyStatistics.numberOfOccupiedBookingPeriods }}</span>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-icon color="accent" class="mr-1" v-on="on">mdi-home-city-outline</v-icon>
                </template>
                <span>Antal uthyrningsobjekt</span>
              </v-tooltip>
              <span class="subheading mr-2">{{ occupancyStatistics.numberOfBookingObjects }}</span>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-card-actions>
    </v-card>
  </v-card>
</template>

<script>
import api from "../../api/api";

export default {
  name: "OccupancyStatisticsComponent",
  data: () => ({
    loading: false,
    occupancyStatistics: {
      labels: [],
      values: [],
      totalOccupiedPercentage: "Laddar...",
      numberOfBookingObjects: 0,
      numberOfOccupiedBookingPeriods: 0,
      totalNumberOfBookingPeriods: 0
    }
  }),
  computed: {
    title()
    {
      return "Beläggning - " + this.$moment().year()
    }
  },
  mounted()
  {
    this.getOccupancyStatistics()
  },
  methods: {
    async getOccupancyStatistics()
    {
      this.loading = true
      let serverResponse = await api.getFromEndpoint(api.ENDPOINTS.USERACCOUNTS + "/" + this.$store.state.user.id + "/" + api.ENDPOINTS.STATISTICS + "/" + api.ENDPOINTS.OCCUPANCY)
      if (serverResponse)
      {
        this.occupancyStatistics = serverResponse
        this.setMonthLabelToString(serverResponse)
      }
      this.loading = false
    },
    setMonthLabelToString()
    {
      this.occupancyStatistics.labels = this.occupancyStatistics.labels.map((label) =>
      {
        return this.$moment().month(label).format("MMM")
      })
    }
  }
}
</script>

<style scoped>

</style>
