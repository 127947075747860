<template>
  <div>
    <v-card :disabled="loading" :loading="loading" flat>
      <v-card-text>
        <v-container grid-list-xl>
          <v-layout row wrap>
            <v-flex xs12>
              <h3 data-cy="appEmailHeader">Slutligen så ska du bara välja en Uthyrdmailadress! </h3>
              <v-alert border="left" class="mb-6 mt-8" color="accent"
                       colored-border dense
                       type="info">
                <p>Din <b>Uthyrdmail</b> är den e-postadress du använder för
                  att skicka ut och få in epostmeddelanden in i Uthyrd. De e-postmeddelanden som skickas
                  till
                  denna adress hamnar automatiskt i din inkorg. Efter detta steg går inte denna
                  e-postadress att ändra igen.
                  Välj den därför med omsorg!
                </p>
                <p class="mt-2 grey--text text--darken-1 body-2"><b>OBS!</b> E-postadressen får ej innehålla könsord, svordomar eller annat som kan uppfattas som
                  stötande.</p>
              </v-alert>
              <v-form ref="form" v-model="valid">
                <v-text-field
                    :counter="100"
                    :rules="emailRules"
                    :suffix="mailSuffix"
                    @keyup.enter="finishGuide"
                    data-cy="appEmailField"
                    autocomplete="off"
                    class="mb-4"
                    hint="E-postadressen kommer alltid att sluta på @mail.uthyrd.se. Du ska alltså endast ange början av e-postadressen."
                    label="Uthyrdmail"
                    prepend-icon="mdi-email-variant"
                    required
                    solo
                    type="email"
                    v-model="applicationEmailAddress"
                ></v-text-field>
              </v-form>
            </v-flex>
          </v-layout>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-btn
            @click="$emit('previousStep')"
        >
          <v-icon class="mr-2">mdi-chevron-left</v-icon>
          Tillbaka
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn data-cy="finishSetupBtn" @click="finishGuide" color="primary">
          Slutför guide
          <v-icon class="ml-2">mdi-trophy-outline</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import api from "../../api/api";
import {validateForm} from "../../util/functions";

export default {
  data: () => ({
    loading: false,
    valid: false,
    mailSuffix: "@mail.uthyrd.se",
    applicationEmailAddress: "",
    emailRules: [
      v => !!v || 'Du måste ange en e-postadress',
    ]
  }),
  props: {
    currentStep: Number
  },
  computed: {
    user()
    {
      return this.$store.state.user
    }
  },
  watch: {
    async currentStep(val)
    {
      if (val === 4)
      {
        this.loading = true
        await this.getGeneratedApplicationEmailAddress()
        this.loading = false
      }
    },
    applicationEmailAddress(val)
    {
      if (val)
      {
        this.applicationEmailAddress = this.slugify(val)
      }
    }
  },
  methods: {
    async getGeneratedApplicationEmailAddress()
    {
      let response = await api.getFromEndpoint(api.ENDPOINTS.APPLICATION + "/" + api.ENDPOINTS.EMAIL + "/" + api.ENDPOINTS.USERACCOUNTS + "/" + this.user.id)
      if (response && response.length > 0)
      {
        this.applicationEmailAddress = response
      }
    },
    async checkIfEmailIsAvailable()
    {
      this.applicationEmailAddress = this.applicationEmailAddress.replace("@", "")
      let fullEmail = this.applicationEmailAddress + this.mailSuffix
      let authResponse = await api.getFromEndpoint(api.ENDPOINTS.USERACCOUNTS + "/" + api.ENDPOINTS.APPLICATION + "/" + api.ENDPOINTS.EMAIL + "/" + fullEmail)
      return !(authResponse && authResponse.length > 0);

    },
    async finishGuide()
    {
      let form = this.$refs.form
      if (validateForm(form))
      {
        this.applicationEmailAddress = this.slugify(this.applicationEmailAddress)
        this.loading = true
        if (await this.checkIfEmailIsAvailable())
        {
          let finishedUser = this.user
          finishedUser.applicationEmailAddress = this.applicationEmailAddress + this.mailSuffix
          finishedUser = await api.putToEndpoint(api.ENDPOINTS.USERACCOUNTS + "/" + finishedUser.id, finishedUser)
          if (finishedUser != null)
          {
            this.$store.commit('updateUser', finishedUser)
            this.$store.commit('setSnackbar', {
              color: "success",
              text: "Ditt konto är nu redo! Dags att sätta igång :)"
            })
          }
        }
        else
        {
          this.$store.commit('setSnackbar', {
            color: "error",
            text: "Den angivna e-postadressen är redan tagen. Var god välj en annan e-postadress"
          })
        }
        this.loading = false
      }
    },
    slugify(string) // removes chars unsuitable for an url or emailaddress
    {
      const a = 'àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìıİłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;'
      const b = 'aaaaaaaaaacccddeeeeeeeegghiiiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------'
      const p = new RegExp(a.split('').join('|'), 'g')

      return string.toString().toLowerCase()
          .replace(/\s+/g, '-') // Replace spaces with -
          .replace(p, c => b.charAt(a.indexOf(c))) // Replace special characters
          .replace(/&/g, '-and-') // Replace & with 'and'
          .replace(/[^\w\\-]+/g, '') // Remove all non-word characters
          .replace(/\\-\\-+/g, '-') // Replace multiple - with single -
          .replace(/^-+/, '') // Trim - from start of text
          .replace(/-+$/, '') // Trim - from end of text
    }
  }
}
</script>

<style scoped>

</style>
