<template>
  <v-card flat :disabled="loading">
    <v-select
        :items="paymentRequestStatuses"
        @change="getPaymentRequestText"
        label="Välj status"
        v-model="selectedStatus"
    ></v-select>
    <v-row>
      <v-col
          cols="12"
          md="6"
      >
        <v-textarea
            no-resize
            hint="Här kan du skriva mer text som ska skickas med i utskicket"
            label="Egen text"
            required
            rows="5"
            v-model="paymentRequestText.text"
        ></v-textarea>
      </v-col>
      <v-col
          cols="12"
          md="6"
      >
        <v-card>
          <v-card-subtitle>Exempel på hur utskicket ser ut:</v-card-subtitle>
          <v-card-text>
            <h4 style="white-space: pre-line;">{{ templateTitle }}</h4>
            <p style="white-space: pre-line;">{{ templateText }}</p>
            <br>
            <p style="white-space: pre-line">
              {{ paymentRequestText.text ? paymentRequestText.text : '[Din text här]' }}</p>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-spacer></v-spacer>
      <v-btn class="mr-3" @click="savePaymentRequestText" :disabled="loading" color="primary">Spara</v-btn>
    </v-row>
    <v-progress-linear class="mt-5" indeterminate :active="loading"></v-progress-linear>
  </v-card>
</template>

<script>
import paymentStatuses from "../../../models/paymentStatuses";
import api from "../../../api/api";

export default {
  data: () => ({
    selectedStatus: paymentStatuses.STATUSES.SENT.value,
    loading: false,
    paymentRequestText: {
      text: "",
    }
  }),
  computed: {
    paymentRequestStatuses() // only statuses that send an email to the customer can be selected
    {
      return paymentStatuses.STATUSES_ARRAY.filter(status =>
      {
        return status.value !== paymentStatuses.STATUSES.NOT_SENT.value
            && status.value !== paymentStatuses.STATUSES.SHOULD_BE_REFUNDED.value
            && status.value !== paymentStatuses.STATUSES.PAYMENT_DUE.value
      })
    },
    templateTitle()
    {
      return paymentStatuses.STATUSES[this.selectedStatus].templateTitle
    },
    templateText()
    {
      return paymentStatuses.STATUSES[this.selectedStatus].templateText
    },
    templateExtraText()
    {
      return paymentStatuses.STATUSES[this.selectedStatus].templateExtraText
    },
    stateUser()
    {
      return this.$store.state.user
    },
  },
  mounted()
  {
    this.getPaymentRequestText()
  },
  methods: {
    async getPaymentRequestText()
    {
      let response = await api.getFromEndpoint(api.ENDPOINTS.USERACCOUNTS + "/" + this.stateUser.id + "/" + api.ENDPOINTS.PAYMENTREQUESTS + "/" + api.ENDPOINTS.STATUS + "/" + this.selectedStatus + "/" + api.ENDPOINTS.TEXT)
      if (response)
      {
        this.paymentRequestText = response
      }
      else
      {
        this.paymentRequestText = {text: "", userAccountId: this.stateUser.id, status: this.selectedStatus}
      }
    },
    async savePaymentRequestText()
    {
      this.loading = true
      if (this.paymentRequestText.id && this.paymentRequestText.id > 0)
      {
        this.paymentRequestText = await api.putToEndpoint(api.ENDPOINTS.PAYMENTREQUESTS + "/" + api.ENDPOINTS.TEXT + "/" + this.paymentRequestText.id, this.paymentRequestText)
      }
      else
      {
        this.paymentRequestText.userAccountId = this.stateUser.id
        this.paymentRequestText.status = this.selectedStatus
        this.paymentRequestText = await api.postToEndpoint(api.ENDPOINTS.PAYMENTREQUESTS + "/" + api.ENDPOINTS.TEXT, this.paymentRequestText)
      }
      if (this.paymentRequestText != null)
      {
        this.$store.commit('setSnackbar', {color: "success", text: "Ändringar sparade!"})
      }
      this.loading = false
    }
  }
}
</script>
