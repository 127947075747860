<template>
  <div>
    <website-admin-component :booking-object="bookingObject"></website-admin-component>
    <website-visit-logs-component></website-visit-logs-component>
  </div>
</template>

<script>

import WebsiteAdminComponent from "@/components/bookingobject/tabs/presentation/WebsiteAdminComponent";
import WebsiteVisitLogsComponent from "@/components/bookingobject/tabs/presentation/WebsiteVisitLogsComponent";

export default {
  components: {WebsiteVisitLogsComponent, WebsiteAdminComponent},
  props: {
    bookingObject: Object
  }
}
</script>
