<template>
  <v-dialog max-width="800px" persistent v-model="dialog">
    <v-card>
      <v-card-title>
        Bekräfta betalningspunkter från uthyrningsobjektet
        <v-spacer></v-spacer>
        <v-icon color="primary">mdi-credit-card-clock-outline</v-icon>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="mt-3">
        <p>Följande betalningsförfrågningar kommer att skapas från betalningspunkterna som finns satta på
          uthyrningsobjektet (klicka för att avmarkera)</p>
        <v-list flat subheader>
          <v-list-item-group multiple v-model="selectedPaymentRequestKeys">
                        <span>
                            <v-list-item
                                :disabled="$moment(request.dueDate).isBefore($moment(), 'd')"
                                :key="'request_' + index"
                                :value="request.key"
                                active-class="primary--text"
                                v-for="(request, index) in paymentRequests"
                            >
                                <template v-slot:default="{ active }">
                                    <v-list-item-content>
                                        <v-list-item-title v-if="request.name">
                                            {{ request.name }}
                                        </v-list-item-title>
                                      <v-list-item-title v-else>
                                            Betalningsförfrågan {{ index + 1 }}
                                        </v-list-item-title>
                                        <v-list-item-subtitle>
                                            <span
                                                class="font-weight-medium">Procentandel: </span> {{ request.percentage }}%
                                        </v-list-item-subtitle>
                                        <v-list-item-subtitle><span
                                            class="font-weight-medium">Belopp: </span> {{ request.amount }} SEK
                                        </v-list-item-subtitle>
                                        <v-list-item-subtitle><b>Förfallodatum: </b> {{ request.dueDate }}</v-list-item-subtitle>
                                        <v-list-item-subtitle
                                            v-if="request.scheduledDate"><b>Schemalagd: </b> {{ request.scheduledDate }}</v-list-item-subtitle>
                                        <v-list-item-subtitle v-if="$moment(request.dueDate).isBefore($moment(), 'd')">
                                            <v-alert
                                                border="left"
                                                colored-border
                                                dense
                                                class="mt-2"
                                                type="error">
                                                Ogiltig - Förfallodatum har passerat.
                                            </v-alert>
                                        </v-list-item-subtitle>
                                    </v-list-item-content>
                                    <v-list-item-action>
                                        <v-checkbox
                                            :disabled="$moment(request.dueDate).isBefore($moment(), 'd')"
                                            :input-value="active"
                                            color="primary"
                                        ></v-checkbox>
                                    </v-list-item-action>
                                </template>
                            </v-list-item>
                        </span>
          </v-list-item-group>
        </v-list>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-btn @click="cancel" text>Avbryt</v-btn>
        <v-spacer></v-spacer>
        <v-btn @click="confirm" color="primary" text>Bekräfta</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import paymentPointTypes from "../../../models/paymentPointTypes";
import paymentStatuses from "../../../models/paymentStatuses";

export default {
  data: () => ({
    pointsValid: true,
    paymentPointDialog: false,
    valuesAsPercentage: false,
    paymentPointStrings: paymentPointTypes.typesString,
    selectedPaymentRequestKeys: [],
    paymentRequests: [],
    editing: false,
  }),
  props: {
    bookingPeriod: Object,
    dialog: Boolean,
  },
  watch: {
    dialog(val) {
      if (val) {
        this.paymentPointsToPaymentRequests()
      }
    }
  },
  computed: {
    bookingObject() {
      return this.bookingPeriod.bookingObject
    },
    selectedPaymentRequests() {
      let result = []
      this.selectedPaymentRequestKeys.forEach((key) => {
        result.push(this.paymentRequests.find(req => req.key === key))
      })
      return result
    }
  },
  filters: {
    typeString(type, paymentPointStrings) {
      return paymentPointStrings[type]
    },
  },
  methods: {
    cancel() {
      this.$emit('cancel')
    },
    confirm() {
      this.$emit('confirm', this.selectedPaymentRequests)
    },
    paymentPointsToPaymentRequests() {
      this.paymentRequests = []
      this.selectedPaymentRequestKeys = []
      this.bookingObject.paymentPoints.forEach((point, index) => {
        point.key = 'request_' + index
        point.dueDate = this.getDateFromOffsetAndType(point.dueDateType, point.dueDateOffset)
        if (point.scheduleDateOffset) {
          let scheduledDate = this.getDateFromOffsetAndType(point.scheduleDateType, point.scheduleDateOffset, point.dueDate)
          if (!this.$moment(scheduledDate).isBefore(this.$moment(), 'd')) {
            point.scheduledDate = scheduledDate
          }
        }
        point.amount = this.getAmountFromPercentage(point.percentage)
        point.statusUpdatedAt = this.$moment().format('YYYY-MM-DD HH:mm')
        point.status = paymentStatuses.STATUSES.NOT_SENT.value
        point.bookingPeriodId = this.bookingPeriod.id
        point.id = null

        this.paymentRequests.push(point)
        if (this.$moment(point.dueDate).isSameOrAfter(this.$moment(), 'd')) {
          this.selectedPaymentRequestKeys.push(point.key)
        }
      })
    },
    getDateFromOffsetAndType(type, offset, dueDate) // get the date from offset and type - dueDate is only used for Type DUE_DATE
    {
      let date = null
      switch (type) {
        case paymentPointTypes.types.BOOKED_AT_DATE:
          date = this.bookingPeriod.bookedAt
          break
        case paymentPointTypes.types.CHECK_IN_DATE:
          date = this.bookingPeriod.calendarEvent.start
          break
        case paymentPointTypes.types.CHECK_OUT_DATE:
          date = this.bookingPeriod.calendarEvent.start
          break
        case paymentPointTypes.types.DUE_DATE:
          date = dueDate
          break
      }
      return this.offsetDate(date, offset)
    },
    offsetDate(date, offset) {
      return this.$moment(date).add(offset, "days").format("YYYY-MM-DD")
    },
    getAmountFromPercentage(percentage) {
      return Math.floor((parseInt(percentage) / 100) * parseInt(this.bookingPeriod.price))
    },
  }
}
</script>

<style scoped>

</style>
