<template>
    <v-timeline-item
            :color="eventLog.color"
            :icon="eventLog.icon"
            fill-dot
    >
        <v-alert :color="eventLog.color" dense outlined>
            <v-card-title style="word-break: break-word" class="text-subtitle-1">
                {{eventLog.title}}
            </v-card-title>
            <v-card-subtitle>{{$moment(eventLog.createdAt).fromNow()}}</v-card-subtitle>
            <v-card-text style="word-break: break-word" class="grey--text text--darken-3">
                {{eventLog.message}}
            </v-card-text>
            <v-card-actions v-if="showRouteBtn">
                <v-spacer></v-spacer>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn :color="eventLog.color" @click="goToRoute" class="mb-1" icon outlined small
                               v-on="on">
                            <v-icon small>mdi-open-in-app</v-icon>
                        </v-btn>
                    </template>
                    <span>Gå till händelsen</span>
                </v-tooltip>
            </v-card-actions>
        </v-alert>
    </v-timeline-item>
</template>

<script>
    export default {
        props: {
            eventLog: Object,
        },
        computed: {
            showRouteBtn() {
                return this.eventLog.route && this.eventLog.route.name !== this.$router.currentRoute.name
            }
        },
        methods: {
            goToRoute()
            {
                if (this.eventLog.route)
                {
                    this.$router.push(this.eventLog.route)
                }
                else
                {
                    return null
                }
            },
        }
    }
</script>

<style scoped>

</style>
