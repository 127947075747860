import { render, staticRenderFns } from "./AccountSubscriptionPriceItemComponent.vue?vue&type=template&id=b17e7fba&"
import script from "./AccountSubscriptionPriceItemComponent.vue?vue&type=script&lang=js&"
export * from "./AccountSubscriptionPriceItemComponent.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBanner } from 'vuetify/lib/components/VBanner';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VFadeTransition } from 'vuetify/lib/components/transitions';
import { VHover } from 'vuetify/lib/components/VHover';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VItem } from 'vuetify/lib/components/VItemGroup';
import { VOverlay } from 'vuetify/lib/components/VOverlay';
import { VScrollYTransition } from 'vuetify/lib/components/transitions';
installComponents(component, {VBanner,VBtn,VCard,VCardActions,VCardText,VDivider,VFadeTransition,VHover,VIcon,VItem,VOverlay,VScrollYTransition})
