<template>
  <v-card :disabled="loading" :loading="loading" flat>
    <v-icon v-if="prependIcon" color="primary" style="position: absolute; left: 0; padding-top: 10px;">
      mdi-clipboard-list-outline
    </v-icon>
    <v-expansion-panels :class="{'pl-8': prependIcon}" :flat="flat" :value="openedIndex">
      <v-expansion-panel :class="{'outlined': outlined}">
        <v-expansion-panel-header>
                <span>
                    <v-icon v-if="!prependIcon" color="primary" left>mdi-clipboard-list-outline</v-icon>
                    Dynamisk data
                </span>
        </v-expansion-panel-header>
        <v-expansion-panel-content :class="{'elevation-0 no-padding': true}">
          <v-divider></v-divider>
          <v-row justify="center">
            <v-col cols="12">
              <v-select
                  id="searchField"
                  v-model="filteredObjects"
                  :items="dynamicData"
                  append-icon="mdi-filter-variant"
                  class="mt-2 mx-2"
                  dense
                  filled
                  hide-details
                  item-text="name"
                  item-value="name"
                  multiple
                  placeholder="Filtrera"
              ></v-select>
            </v-col>
          </v-row>
          <div style="overflow-y: auto; max-height: 252px;">
            <div v-for="(item, index) in filteredDynamicData" :key="item.name + '_' + index">
              <v-subheader>{{ item.name }}</v-subheader>
              <div>
              <span v-for="data in item.data" :key="data.code">
                <v-list-item dense @click="showMenu($event, data, item)">
                  <v-list-item-content>
                    <v-list-item-subtitle>
                      <span>{{ data.name }}</span>
                    </v-list-item-subtitle>
                    <v-list-item-subtitle class="caption">{{ data.code }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </span>
              </div>
              <v-divider v-if="index < filteredDynamicData.length -1" class="mt-2"></v-divider>
            </div>
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-menu
        v-model="menu"
        :nudge-bottom="25"
        :nudge-left="50"
        :position-x="menuX"
        :position-y="menuY"
        offset-y
        transition="slide-y-transition"
    >
      <v-list v-if="selected">
        <v-list-item>
          <v-list-item-subtitle class="font-weight-bold">{{ selected.parent.name }} - {{ selected.name }}
          </v-list-item-subtitle>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item @click="copyToClipboard(selected.code)">
          <v-list-item-icon>
            <v-icon color="primary">mdi-clipboard-text-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Kopiera kod till urklipp</v-list-item-title>
        </v-list-item>
        <v-list-item @click="insertCode(selected.code)">
          <v-list-item-icon>
            <v-icon color="accent">mdi-keyboard-return</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Infoga kod i innehållet</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-card>
</template>

<script>

import api from "@/api/api";

export default {
  components: {},
  data: () => ({
    loading: false,
    filteredObjects: null,
    menuX: 0,
    menuY: 0,
    menu: false,
    selected: null,
    dynamicData: [],
  }),
  props: {
    openedIndex: Number, // 0 = closed, 1 = opened
    prependIcon: Boolean,
    flat: Boolean,
    outlined: Boolean,
  },
  computed: {
    filteredDynamicData()
    {
      if (!this.filteredObjects || this.filteredObjects.length === 0)
      {
        return this.dynamicData
      }
      return this.dynamicData.filter((item) => this.filteredObjects.includes(item.name))
    }
  },
  mounted()
  {
    this.getDynamicData()
  },
  methods: {
    async getDynamicData()
    {
      this.loading = true
      this.dynamicData = await api.getFromEndpoint(api.ENDPOINTS.DYNAMICDATA + "/" + api.ENDPOINTS.CONTAINERS)
      this.loading = false
    },
    copyToClipboard(text)
    {
      var tempInput = document.createElement("input");
      tempInput.style = "position: absolute; left: -1000px; top: -1000px";
      tempInput.value = text;
      document.body.appendChild(tempInput);
      tempInput.select();
      document.execCommand("copy");
      document.body.removeChild(tempInput);
      this.$store.commit('setSnackbar', {
        color: "primary",
        text: "Kopierade koden till urklipp!"
      })
    },
    insertCode(code)
    {
      this.$emit('itemInsert', code)
    },
    showMenu(e, data, parent)
    {
      e.preventDefault()
      this.menuX = e.clientX
      this.menuY = e.clientY
      this.menu = true
      if (this.selected && this.selected.code !== data.code)
      {
        this.$nextTick(() =>
        {
          // nextTick plus 5ms timeout to make sure menu keeps open when clicking another item
          window.setTimeout(() =>
          {
            this.menu = true
          }, 5)
        })
      }
      this.selected = data
      this.selected.parent = parent
    },
  },
}
</script>
