<template>
  <div>
    <v-subheader>Visningsalternativ</v-subheader>
    <div class="px-4 pb-6">
      <booking-period-status-select-component :value="bookingPeriodsDisplayOptions.value" @item-click="itemClick" chips
                                              multiple></booking-period-status-select-component>
      <v-select
          :items="viewTypes"
          @change="itemClick"
          label="Visa som"
          prepend-icon="mdi-view-compact-outline"
          v-model="bookingPeriodsDisplayOptions.value.viewType"
      ></v-select>
      <transition name="slide-y-transition">
        <v-list-item
            :input-value="bookingPeriodsDisplayOptions.value.onlyFuturePeriods"
            @click="setOnlyFuturePeriods"
            active-class="primary--text"
            v-if="bookingPeriodsDisplayOptions.value.viewType === 2"
        >
          <v-list-item-content>
            <v-list-item-title>Endast framtida perioder</v-list-item-title>
          </v-list-item-content>
          <v-list-item-action>
            <v-switch
                @click="setOnlyFuturePeriods"
                v-model="bookingPeriodsDisplayOptions.value.onlyFuturePeriods"
            ></v-switch>
          </v-list-item-action>
        </v-list-item>
      </transition>
    </div>
  </div>
</template>

<script>
import api from "../../api/api";
import BookingPeriodStatusSelectComponent from "./BookingPeriodStatusSelectComponent";

export default {
  components: {BookingPeriodStatusSelectComponent},
  data: () => ({
    viewTypes: [{text: "Kalender", value: 1}, {text: "Lista", value: 2}],
  }),
  props: {
    bookingPeriodsDisplayOptions: Object,
  },
  mounted()
  {
    if(!this.bookingPeriodsDisplayOptions.id)
    {
      this.itemClick()
    }
  },
  methods: {
    getImageUrl(item)
    {
      if (item.fileData && item.fileData.src)
      {
        return api.BASE_URL + item.fileData.src
      }
      return ""
    },
    itemClick(item)
    {
      this.$emit("item-click", item)
    },
    setOnlyFuturePeriods()
    {
      this.bookingPeriodsDisplayOptions.value.onlyFuturePeriods = !this.bookingPeriodsDisplayOptions.value.onlyFuturePeriods
      this.itemClick()
    }
  },
}
</script>

<style scoped>

</style>
