<template>
  <v-dialog max-width="800px" scrollable v-model="dialogProp">
    <v-card :loading="loading">
      <v-card-title>
        Välj mall för din nya webbplats
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <p class="mt-10 mb-5 text-center grey--text" v-if="loading">Hämtar data...</p>
        <v-container fluid v-else>
          <v-card
              class="mx-auto my-12"
              max-width="420"
              :key="website.id"
              v-for="website in templates"
          >
            <v-img
                v-if="website.templateData.imgSrc"
                :src="website.templateData.imgSrc"
            ></v-img>
            <v-card-title>{{ website.templateData.title }}</v-card-title>
            <v-card-text>
              <div class="mb-4 text-subtitle-1 font-weight-bold">
                {{ website.templateData.subtitle }}
              </div>
              <div>{{ website.templateData.description }}</div>
            </v-card-text>

            <v-divider class="mx-4"></v-divider>

            <v-card-title>Innehåller</v-card-title>

            <v-card-text>
              <v-chip-group
                  column
              >
                <v-chip
                    :key="feature"
                    v-for="feature in website.templateData.features"
                >
                  {{ feature }}
                </v-chip>
              </v-chip-group>
            </v-card-text>

            <v-card-actions class="justify-center">
              <v-btn
                  color="primary"
                  @click="submit(website.id)"
              >
                Välj denna mall
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-container>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-btn @click="dialogProp = false" text>Stäng</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import api from "../../../api/api";

export default {
  components: {},
  data: () => ({
    templates: [],
    loading: false,
  }),
  props: {
    dialog: Boolean,
  },
  computed: {
    dialogProp: {
      get: function () {
        return this.dialog;
      },
      set: function (newValue) {
        this.$emit('update:dialog', newValue)
      }
    },
    user() {
      return this.$store.state.user
    }
  },
  mounted() {
    this.getWebsiteTemplates()
  },
  methods: {
    async getWebsiteTemplates() {
      this.loading = true
      let serverResponse = await api.getFromEndpoint(`${api.ENDPOINTS.WEBSITES}/${api.ENDPOINTS.TEMPLATES}`)
      if (serverResponse) {
        this.templates = serverResponse
      }
      this.loading = false
    },
    submit(id) {
      this.$emit('submit', id, true)
      this.dialogProp = false
    },
    getImageUrl(item) {
      if (item.fileData && item.fileData.src) {
        return api.BASE_URL + item.fileData.src
      }
      return ""
    }
  }
}
</script>
