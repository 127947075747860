<template>
    <div v-if="currentTour">
        <v-tour :callbacks="tourCallbacks" :options="options" :steps="currentTour.steps" name="walkthroughTour">
            <template slot-scope="tour">
                <div :key="index" v-for="(step, index) of tour.steps">
                    <transition mode="out-in" name="scale-transition" origin="center center">
                        <v-step
                                :finish="tour.finish"
                                :highlight="tour.highlight"
                                :is-first="tour.isFirst"
                                class="white black--text elevation-7"
                                style="white-space: pre-wrap"
                                :is-last="tour.isLast"
                                :key="'S_'+index"
                                :labels="tour.labels"
                                :next-step="tour.nextStep"
                                :previous-step="tour.previousStep"
                                :skip="tour.skip"
                                :step="step"
                                :stop="tour.stop"
                                v-if="tour.currentStep === index"
                        >
                        </v-step>
                    </transition>
                </div>
            </template>
        </v-tour>
        <tour-admin-component :current-step="currentStep" :loading="loading"
                              :show-delete="currentTour.id && currentTour.id >= 0"
                              :steps.sync="currentTour.steps"
                              :tour-active="tourActive" @deleted="deleteTour"
                              @saved="saveTour"></tour-admin-component>
    </div>
</template>

<script>
    import TourAdminComponent from "./TourAdminComponent";
    import api from "../../api/api";

    export default {
        components: {TourAdminComponent},
        computed: {
            tourCallbacks()
            {
                return {
                    onSkip: this.skipCallback,
                    onFinish: this.finishCallback,
                    onStart: this.startCallback,
                    onPreviousStep: this.previousStepCallback,
                }
            },
            currentRoute()
            {
                return this.$store.state.currentRoute
            },
            tourActive()
            {
                return this.$store.state.tourActive
            },
            user()
            {
              return this.$store.state.user
            }
        },
        watch: {
            currentRoute: {
                handler()
                {
                    let tour = this.$store.state.tours.find(tour => tour.routeName === this.currentRoute)
                    if (tour && tour.id)
                    {
                        this.currentTour = tour
                    }
                    else
                    {
                        this.currentTour = {
                            routeName: this.currentRoute,
                            steps: [],
                            watched: true
                        }
                    }
                },
                immediate: true,
            },
            currentTour: {
                handler()
                {
                    if (!this.currentTour.watched && !this.$store.state.editTour && this.user.email.indexOf('devtest') < 0)
                    {
                        this.$nextTick(() =>
                        {
                            clearTimeout(this.timeout)
                            // we delay the tour a bit to make sure everything is loaded and animations have been played
                            this.timeout = window.setTimeout(() => this.$tours['walkthroughTour'].start(), 1000)
                        })
                    }
                },
                immediate: true,
                deep: true
            }
        },
        data: () => ({
            options: {
                highlight: true,
                labels: {
                    buttonSkip: 'Skippa',
                    buttonPrevious: 'Tillbaka',
                    buttonNext: 'Nästa',
                    buttonStop: 'Avsluta'
                }
            },
            currentStep: -1,
            currentTour: {
                routeName: "home",
                steps: [],
                watched: true
            },
            loading: false,
            timeout: null
        }),
        methods: {
            skipCallback()
            {
                this.$store.commit("setTourActive", false)
                if (!this.currentTour.watched)
                {
                    this.saveTourWatched()
                }
            },
            finishCallback()
            {
                this.$store.commit("setTourActive", false)
                if (!this.currentTour.watched)
                {
                    this.saveTourWatched()
                }
            },
            startCallback()
            {
                this.$store.commit("setTourActive", true)
                this.currentStep = 0
            },
            previousStepCallback()
            {
                this.currentStep--
            },
            nextStepCallback()
            {
                this.currentStep++
            },
            saveTourWatched()
            {
                this.currentTour.watched = true
                if (this.currentTour.id && !this.$store.state.editTour)
                {
                    api.putToEndpoint(api.ENDPOINTS.TOURS + "/" + this.currentTour.id + "/" + api.ENDPOINTS.WATCHED, {})
                }
            },
            async saveTour()
            {
                if (this.currentTour.steps.length > 0)
                {
                    let saveText = "Genomgång sparad för nuvarande vy (" + this.currentRoute + ")"
                    this.loading = true
                    if (this.currentTour.id)
                    {
                        saveText = "Genomgång uppdaterad för nuvarande vy (" + this.currentRoute + ")"
                        this.currentTour = await api.putToEndpoint(api.ENDPOINTS.TOURS + "/" + this.currentTour.id, this.currentTour)
                    }
                    else
                    {
                        await api.postToEndpoint(api.ENDPOINTS.TOUR, {
                            routeName: this.currentRoute,
                            steps: this.currentTour.steps,
                        })
                    }
                    let tourResponse = await api.getFromEndpoint(api.ENDPOINTS.TOURS)
                    if (tourResponse)
                    {
                        this.$store.commit('setTours', tourResponse)
                    }
                    this.loading = false
                    this.$store.commit('setSnackbar', {color: "success", text: saveText})
                }
            },
            async deleteTour()
            {
                if (this.currentTour.id)
                {
                    this.loading = true
                    await api.deleteReqToEndpoint(api.ENDPOINTS.TOURS + "/" + this.currentTour.id)
                    let saveText = "Genomgång borttagen för nuvarande vy (" + this.currentRoute + ")"
                    this.$store.commit('setSnackbar', {color: "success", text: saveText})
                }
                let tourResponse = await api.getFromEndpoint(api.ENDPOINTS.TOURS)
                if (tourResponse)
                {
                    this.$store.commit('setTours', tourResponse)
                }
                this.loading = false
            }
        }
    }
</script>
