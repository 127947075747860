<template>
  <v-card :disabled="loading" :loading="loading" flat>
    <v-icon v-if="prependIcon" color="primary" style="position: absolute; left: 0; padding-top: 10px;">mdi-history
    </v-icon>
    <v-expansion-panels :class="{'pl-8': prependIcon}" :flat="flat" :value="openedIndex">
      <v-expansion-panel :class="{'outlined': outlined}">
        <v-expansion-panel-header>
                <span>
                    <v-icon v-if="!prependIcon" color="primary" left>mdi-history</v-icon>
                    Historik
                </span>
        </v-expansion-panel-header>
        <v-expansion-panel-content :class="{'elevation-0 no-padding': true}">
          <v-divider></v-divider>
          <v-list
              v-if="this.revisions && this.revisions.length > 0"
              class="pa-0"
              dense
              max-height="300px"
              style="overflow-y: auto;"
              three-line>
            <span v-for="(item, index) in revisions" :key="item.id">
                <v-list-item
                    @click="itemClick(item)"
                >
                  <v-list-item-content>
                    <v-list-item-subtitle v-if="item.id >= 0">
                      <span>{{getFormattedDate(item.createdAt)}}</span>
                    </v-list-item-subtitle>
                    <v-list-item-subtitle v-else>
                      <span>Nuvarande</span>
                    </v-list-item-subtitle>
                    <v-list-item-subtitle class="caption" v-html="item.revisedContent"></v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-divider v-if="index < revisions.length -1"></v-divider>
              </span>
          </v-list>
          <no-data-component v-else class="my-8"
                             icon="mdi-keyboard-off-outline"
                             text="Det finns ingen historik för detta dokument"></no-data-component>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-card>
</template>

<script>
import api from "@/api/api";
import NoDataComponent from "@/components/global/NoDataComponent";

export default {
  components: {NoDataComponent},
  data: () => ({
    loading: false,
    revisions: [],
    currentRevision: null
  }),
  props: {
    dynamicDocument: Object,
    openedIndex: Number, // 0 = closed, 1 = opened
    prependIcon: Boolean,
    flat: Boolean,
    outlined: Boolean,
  },
  async mounted()
  {
    let serverResponse = await this.getRevisionsForDynamicDocument()
    if (serverResponse)
    {
      Object.assign(this.dynamicDocument, serverResponse)
    }
  },
  methods: {
    itemClick(revision)
    {
      // Will emit on
      this.$emit('itemClick', revision.revisedContent)
    },
    async getRevisionsForDynamicDocument()
    {
      this.loading = true
      this.revisions = await api.getFromEndpoint(api.ENDPOINTS.DYNAMICDOCUMENTS + "/" + this.dynamicDocument.id + "/" + api.ENDPOINTS.REVISIONS)
      this.loading = false
      if(this.revisions.length > 0)
      {
        this.setCurrentRevision()
      }
    },
    getFormattedDate(date)
    {
      return this.$moment(date).fromNow()
    },
    setCurrentRevision() { // isn't actually a revision that is stored in the db. This is just meant as convenience when user is clicking through the history
      this.currentRevision = {
        createdAt: this.$moment(),
        id: -1,
        revisedContent: this.dynamicDocument.content
      }
      this.revisions.unshift(this.currentRevision)
    }
  },
}
</script>
<style>
.no-padding .v-expansion-panel-content__wrap {
  padding: 0;
}
</style>
