import paymentIntervals from "@/models/accountSubscriptionPaymentIntervals";

const PRICES =  [
    {
        name: "Betala per år",
        price: "1 550kr",
        priceInt: 1550,
        seatPriceInt: 540,
        paymentInterval: paymentIntervals.INTERVALS.YEARLY,
        row1: "540kr / år per extra uthyrningsobjekt",
        description: "Genom att betala för ett helt år i förväg så blir priset endast 129kr per månad. " +
            "Dessutom blir priset per extra uthyrningsobjekt 45kr per månad istället för 55 och så slipper du behöva bry dig om att förnya din prenumeration lika ofta. Enkelt och smidigt!",
        id: process.env.NODE_ENV === 'production' ? "price_1JilFPEb6D3cJUjntLeXKDGO" : "price_1IGnfnEb6D3cJUjnbjjIxLOo",
        bannerText: "Rekommenderat val"
    },
    {
        name: "Betala per månad",
        price: "199kr",
        priceInt: 199,
        seatPriceInt: 55,
        paymentInterval: paymentIntervals.INTERVALS.MONTHLY,
        row1: "55kr / månad per extra uthyrningsobjekt",
        description: "Vill du så kan du betala för endast en månad i taget." +
            " Du får då en större flexibilitet om du vill avbryta din prenumeration men betalar en högre månadskostnad.",
        id: process.env.NODE_ENV === 'production' ? "price_1JilFPEb6D3cJUjnhQ3AeUut" : "price_1IGneaEb6D3cJUjnEqp4vJ1F"
    },
]

export default {
    PRICES
}
