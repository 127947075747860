// keeps track of which component is used for inputs when administrating a section
const COMPONENTS = {
    TEXT_PARAGRAPH: "ParagraphSectionAdminComponent",
    TEXT_HEADER: "HeaderSectionAdminComponent",
    IMAGE: "ImageSectionAdminComponent",
    IMAGE_SLIDESHOW: "SlideshowSectionAdminComponent",
    VIDEO_EMBED: "",
    ATTRIBUTE_SHOWCASE: "AttributesSectionAdminComponent",
    EXTERNAL_BOOKER: "ExternalBookerSectionAdminComponent",
    EMPTY_SECTION: "EmptySectionAdminComponent",
    GOOGLE_MAPS_EMBED: "GoogleMapsSectionAdminComponent",
}

export default {
    COMPONENTS
}
