<template>
  <v-list-item @click="goToRoute">
    <v-list-item-icon>
      <v-icon :color="eventLog.color">{{ eventLog.icon }}</v-icon>
    </v-list-item-icon>
    <v-list-item-content>
      <v-list-item-title>
        {{ eventLog.title }}
      </v-list-item-title>
      <v-list-item-subtitle class="grey--text text--darken-1">
        {{ $moment(eventLog.createdAt).fromNow() }}
      </v-list-item-subtitle>
      <v-list-item-subtitle>
        {{ eventLog.message }}
      </v-list-item-subtitle>
    </v-list-item-content>
    <v-list-item-action v-if="showRouteBtn">
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn :color="eventLog.color" @click="goToRoute" class="mb-1" icon outlined small
                 v-on="on">
            <v-icon small>mdi-open-in-app</v-icon>
          </v-btn>
        </template>
        <span>Gå till händelsen</span>
      </v-tooltip>
    </v-list-item-action>
  </v-list-item>
</template>

<script>
export default {
  props: {
    eventLog: Object,
  },
  computed: {
    showRouteBtn()
    {
      return this.eventLog.route && this.eventLog.route.name !== this.$router.currentRoute.name
    }
  },
  methods: {
    goToRoute()
    {
      if (this.eventLog.route)
      {
        this.$router.push(this.eventLog.route)
      }
      else
      {
        return null
      }
    },
  }
}
</script>
