<template>
  <v-list two-line>
    <no-data-component v-if="customers.length <= 0" :text="noDataText" :icon="noDataIcon"
                       :pulse="noDataPulse"></no-data-component>
    <v-list-item
        v-for="item in customers"
        :key="item.id"
        :dense="dense"
    >
      <v-list-item-avatar>
        <initials-avatar-component :firstname="item.firstname" :imgSrc="getImageUrl(item)"
                                   class="mr-2"
                                   :size="30"
                                   :lastname="item.lastname"
        ></initials-avatar-component>
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title>{{ `${item.firstname} ${item.lastname}` }}</v-list-item-title>
        <v-list-item-subtitle>
          <v-tooltip bottom v-if="item.email.length > 0">
            <template v-slot:activator="{ on }">
              <v-btn icon v-on="on" color="primary"
                     :disabled="disableMessageButton"
                     @click="$emit('customerMailClick', item.email)">
                <v-icon color="primary">mdi-message-text-outline</v-icon>
              </v-btn>
            </template>
            <span>{{ item.email }}</span>
          </v-tooltip>
          <v-tooltip bottom v-if="item.phone.length > 0">
            <template v-slot:activator="{ on }">
              <v-btn icon v-on="on" color="primary" @click.stop=""
                     :href="`tel:${item.phone}`">
                <v-icon color="primary">mdi-phone</v-icon>
              </v-btn>
            </template>
            <span>{{ item.phone }}</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn icon :to="{name: 'customers', params:{'id': item.id}}" v-on="on" color="accent">
                <v-icon color="accent">mdi-account-edit-outline</v-icon>
              </v-btn>
            </template>
            <span>Gå till kunddetaljer</span>
          </v-tooltip>
        </v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item-icon v-if="showFavorite">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on"
                   :disabled="favoritesDisabled"
                   @click.stop="setCustomerAsMainContact(item)"
                   :color="item.mainContact ? 'primary':'grey'" icon>
              <v-icon>{{ item.mainContact ? "mdi-heart" : "mdi-heart-outline" }}</v-icon>
            </v-btn>
          </template>
          <span>Markera som huvudkund</span>
        </v-tooltip>
      </v-list-item-icon>
    </v-list-item>
  </v-list>
</template>

<script>
import api from "../../api/api"
import NoDataComponent from "../../components/global/NoDataComponent";
import InitialsAvatarComponent from "@/components/global/InitialsAvatarComponent";

export default {
  components: {NoDataComponent, InitialsAvatarComponent},
  data: () => ({}),
  props: {
    customers: Array,
    noDataText: String,
    noDataIcon: String,
    noDataPulse: Boolean,
    showFavorite: Boolean,
    favoritesDisabled: Boolean,
    readOnly: Boolean,
    fetchAsync: Boolean,
    bookingPeriod: Object,
    dense: Boolean,
    disableMessageButton: {
      type: Boolean,
      default: false
    }
  },
  async mounted() {
    if (this.fetchAsync && this.bookingPeriod && this.bookingPeriod.id > 0) {
      let serverResponseCustomers = await api.getFromEndpoint(api.ENDPOINTS.BOOKINGPERIODS + "/" + this.bookingPeriod.id + "/" + api.ENDPOINTS.CUSTOMERS)
      if (serverResponseCustomers) {
        this.customers = serverResponseCustomers
      }
    }
  },
  methods: {
    selectCustomer(customer) {
      this.$emit("selectCustomer", customer)
    },
    getImageUrl(item) {
      if (item.fileData && item.fileData.src) {
        return api.BASE_URL + item.fileData.src
      }
      return ""
    },
    setCustomerAsMainContact(customer) {
      for (let i = 0; i < this.customers.length; i++) {
        this.customers[i].mainContact = false
      }
      customer.mainContact = true
    },
  }
}
</script>
