<template>
  <div class="outlined">
    <ckeditor v-model="content" :config="editorConfig" :editor="editor"
              @ready="editorReady"></ckeditor>
  </div>
</template>

<script>
import CKEditor from "@ckeditor/ckeditor5-vue2"
import '@ckeditor/ckeditor5-build-decoupled-document/build/translations/sv';
import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';

export default {
  components: {
    ckeditor: CKEditor.component
  },
  data: () => ({
    editor: DecoupledEditor,
    ckeditorInstance: null,
  }),
  props: {
    value: String,
    placeholder: String
  },
  computed: {
    editorConfig()
    {
      return {
        placeholder: this.placeholder,
        toolbar: {
          items: ["undo", "redo", "alignment:left", "alignment:right", "alignment:center", "alignment:justify", "indent", "outdent", "|", "heading", "fontSize", "fontFamily", "fontColor", "fontBackgroundColor", "|", "bold", "italic", "strikethrough", "underline", "blockQuote", "|", "mediaEmbed", "link", "|", "numberedList", "bulletedList", "insertTable"],
          shouldNotGroupWhenFull: true
        },
        language: 'sv'
      }
    },
    content: {
      get()
      {
        return this.value
      },
      set(val)
      {
        this.$emit('update:value', val)
      }
    },
  },
  methods: {
    editorReady(editor)
    {
      // Insert the toolbar before the editable area.
      editor.ui.getEditableElement().parentElement.insertBefore(
          editor.ui.view.toolbar.element,
          editor.ui.getEditableElement()
      );
      this.ckeditorInstance = editor
    },
    insertTextAtCursorPosition(text)
    {
      this.ckeditorInstance.model.change( writer => {
        const insertPosition = this.ckeditorInstance.model.document.selection.getFirstPosition();
        writer.insertText( text, {}, insertPosition );
      } );
    }
  }
}
</script>

