<template>
  <v-container grid-list-xl>
    <v-layout row wrap>
      <v-flex xs12>
        <v-card
            :loading="loading"
            class="elevation-6"
            color="white"
        >
          <v-card-title class="secondary">
            <v-icon
                dark
                left
            >
              mdi-settings-outline
            </v-icon>
            <span class="title textfont-weight-light white--text">Kontoinställningar</span>
          </v-card-title>
          <v-tabs
              v-model="tab"
              background-color="transparent"
              color="accent"
              grow
              show-arrows
          >
            <v-tab>
              Översikt
            </v-tab>
            <v-tab>
              Prenumeration <!-- TODO show alert-icon if subscription is close to ending -->
            </v-tab>
            <v-tab>
              Notifikationer
            </v-tab>
          </v-tabs>
          <v-divider></v-divider>
          <v-card-text>
            <v-tabs-items v-model="tab" class="my-8 px-4">
              <v-form
                  ref="form"
                  v-model="validForm"

                  @submit.prevent
              >
                <v-tab-item>
                  <user-account-avatar-admin-component v-model="user"
                                                       @saved="saveUser"></user-account-avatar-admin-component>
                  <user-account-admin-component ref="accountDetails" v-model="user"
                                                @saved="saveUser"></user-account-admin-component>
                </v-tab-item>
                <v-tab-item eager>
                  <user-account-subscription-admin-component></user-account-subscription-admin-component>
                </v-tab-item>
                <v-tab-item eager>
                  <notification-settings-admin-component></notification-settings-admin-component>
                </v-tab-item>
              </v-form>
            </v-tabs-items>
          </v-card-text>
          <v-card-actions v-if="showActions">
            <reset-password-button :email="user.email" color="accent"></reset-password-button>
            <v-spacer></v-spacer>
            <v-btn :loading="saving" color="primary"
                   @click="saveUser()">Spara
              <v-icon right>save</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import UserAccountAdminComponent from "../../components/useraccount/UserAccountAdminComponent";
import NotificationSettingsAdminComponent from "../../components/useraccount/NotificationSettingsAdminComponent";
import UserAccountAvatarAdminComponent from "../../components/useraccount/UserAccountAvatarAdminComponent";
import api from "../../api/api";
import ResetPasswordButton from "../../components/global/ResetPasswordButton";
import {validateForm} from "../../util/functions";
import UserAccountSubscriptionAdminComponent
  from "@/components/useraccount/accountsubscription/UserAccountSubscriptionAdminComponent";

export default {
  components: {
    UserAccountSubscriptionAdminComponent,
    ResetPasswordButton,
    UserAccountAvatarAdminComponent, NotificationSettingsAdminComponent, UserAccountAdminComponent
  },
  data: () => ({
    loading: false,
    saving: false,
    validForm: true,
    tab: 0,
    user: {
      id: 0,
      firstname: "",
      active: 1,
      lastname: "",
      email: "",
      phone: "",
      fileData: null,
      address: {
        id: 0,
        address1: "",
        address2: "",
        address3: "",
        postcode: "",
        city: "",
        country: "Sverige",
      },
    },
  }),
  computed: {
    stateUser() {
      return this.$store.state.user
    },
    showActions() {
      return this.tab === 0
    }
  },
  async mounted() {
    // first we set from store
    Object.assign(this.user, this.stateUser);

    // then we get latest data from backend
    this.getCurrentUser()
  },
  methods: {
    async getCurrentUser() {
      this.loading = true
      let serverResponse = await api.getFromEndpoint(api.ENDPOINTS.AUTH)
      if (serverResponse) {
        Object.assign(this.user, serverResponse);
      }
      this.loading = false
    },
    async saveUser() {
      let form = this.$refs.accountDetails.$refs.form
      if (validateForm(form)) {
        this.saving = true
        this.user = await api.putToEndpoint(api.ENDPOINTS.USERACCOUNTS + "/" + this.stateUser.id, this.user)
        if (this.user != null) {
          this.$store.commit('updateUser', this.user)
          this.$store.commit('setSnackbar', {color: "success", text: "Användarkonto uppdaterad"})
        }
        this.saving = false
      }
    }
  }

}
</script>
