<template>
  <v-dialog v-model="dialogProp" eager max-width="650px" persistent scrollable>
    <v-card :disabled="loading" :loading="loading">
      <v-card-title class="error--text">
        Bekräfta radering av konto
        <v-spacer></v-spacer>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pt-4">
        <h3 class="font-weight-medium">Är du säker på att du vill avsluta ditt konto? Notera att all din data och uppgifter kommer att raderas.
          <br><b>Denna åtgärd går inte att ångra!</b>
        </h3>
        <p class="mt-2">För att avsluta ditt konto, vänligen skriv <i>AVSLUTA KONTO</i> i textrutan nedan och klicka sedan på knappen längst
          ned till höger</p>
        <v-text-field
            class="mt-6"
            @input="toUpper"
            v-model="validation"
            :success="validationSuccess"
            :append-icon="validationSuccess ? 'mdi-check' : null"
            :rules="[v => v === 'AVSLUTA KONTO' || 'Du måste skriva AVSLUTA KONTO för att avsluta ditt konto']"
            solo
        outlined></v-text-field>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-btn text @click="cancel">Avbryt</v-btn>
        <v-spacer></v-spacer>
        <v-btn
            color="error"
            :disabled="!validationSuccess"
            @click="submit">
          Avsluta konto
          <v-icon class="ml-2">mdi-account-cancel-outline</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

import api from "@/api/api";

export default {
  components: {},
  data: () => ({
    loading: false,
    validation: "",
  }),
  props: {
    dialog: Boolean,
  },
  computed: {
    dialogProp: {
      get: function ()
      {
        return this.dialog;
      },
      set: function (newValue)
      {
        this.validation = ""
        this.$emit('update:dialog', newValue)
      }
    },
    validationSuccess()
    {
      return this.validation === "AVSLUTA KONTO"
    }
  },
  methods: {
    async submit()
    {
      this.loading = true
      let serverResponse = await api.deleteReqToEndpoint(api.ENDPOINTS.USERACCOUNTS + "/" + this.$store.state.user.id)
      if (serverResponse)
      {
        this.$store.commit('setSnackbar', {
          color: "primary",
          text: "Ditt konto har nu avslutats. Sidan laddar strax om..."
        })
        this.$store.commit('setAppIsLoading', true)
        window.setTimeout(() => {
          this.$store.commit('updateUser', null)
          api.deleteReqToEndpoint(api.ENDPOINTS.AUTH)
          location.href = "/"
        }, 3000)
      }
      else
      {
        this.loading = false
      }
    },
    cancel()
    {
      this.dialogProp = false
    },
    toUpper() {
      this.validation = this.validation.toUpperCase();
    }
  }
}
</script>
