<template>
  <v-menu transition="slide-y-transition" max-width="400px" right offset-x nudge-right="10px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn fab
             v-bind="attrs"
             v-on="on"
             outlined x-small color="info"
      >
        <v-icon small>mdi-help</v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-card-title avatar class="secondary white--text">
        <span class="headline">Hjälp - Hemsida</span>
        <v-spacer></v-spacer>
        <v-icon dark>mdi-help-circle-outline</v-icon>
      </v-card-title>
      <v-card-text>
        <v-subheader class="pl-0 mt-3 font-weight-bold">Vad är detta?</v-subheader>
        <p>Din webbplats är uppbyggd av ett antal <b>sektioner</b>. Varje sektion är av en specifik typ. Det kan vara
          allt från en rubrik eller textstycke till ett bildspel.</p>
        <v-subheader class="pl-0 mt-3 font-weight-bold">Ordning</v-subheader>
        <p>Sektionerna visas efter varandra. Genom att <b>dra och släppa</b> sektionerna så ändrar du på ordningen på
          din webbplats. Prova kombinera olika typer av sektioner och ordna de tills du känner dig nöjd. Klicka sedan på
          <b>Spara</b> längst upp till höger.</p>
        <v-subheader class="pl-0 mt-3 font-weight-bold">Publicera</v-subheader>
        <p>När din webbplats är redo för världen att se så trycker du på <b>Publicera</b> längst upp till höger. Då
          kommer din webbplats att finnas tillgänglig på den webadress som du har angett och som står längst upp till
          vänster.</p>
        <div class="text-center mt-8 grey--text">
          <h5>Behöver du mer hjälp?</h5>
          <p class="grey--text"><a href="mailto:support@uthyrd.se">Kontakta oss</a> så hjälper vi dig!</p>
        </div>
      </v-card-text>
    </v-card>
  </v-menu>
</template>

<script>

export default {
  components: {},
  data: () => ({}),
}
</script>
