<template>
    <span>
        <v-list-item
                v-bind="itemAttrs"
                v-on="itemEvents"
        >
            <v-list-item-icon v-if="multiple">
                <v-simple-checkbox v-model="file.selected" @click.stop.prevent></v-simple-checkbox>
            </v-list-item-icon>
          <v-list-item-icon v-else>
                <v-icon color="primary">{{fileTypes.properties[fileData.filetype].icon}}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
                <v-list-item-title>
                    {{file.name}}
                </v-list-item-title>
                <v-list-item-subtitle><b>Filtyp: </b> {{fileData.extension}}</v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-avatar tile v-if="fileData.filetype === fileTypes.IMAGE">
                <v-img :src="getFileUrl(file.fileData)"></v-img>
            </v-list-item-avatar>
        </v-list-item>
        <v-menu
                :position-x="menuX"
                :position-y="menuY"
                offset-y top transition="slide-y-reverse-transition" v-if="showActions"
                v-model="actionsMenu">
            <v-list class="py-0">
                <v-list-item>
                    <v-list-item-title class="font-weight-bold">{{file.name}}</v-list-item-title>
                     <v-list-item-icon>
                        <v-icon color="primary">{{fileTypes.properties[fileData.filetype].icon}}</v-icon>
                    </v-list-item-icon>
                </v-list-item>
                <v-divider></v-divider>
                <v-list-item
                        :download="file.name"
                        :href="getFileUrl(file.fileData)"
                        color="accent"
                        tag="a" target="_blank"
                >
                    <v-list-item-title>Öppna fil</v-list-item-title>
                    <v-list-item-action>
                        <v-icon color="accent">mdi-file-eye-outline</v-icon>
                    </v-list-item-action>
                </v-list-item>
                <v-list-item
                        @click="editFile" color="primary">
                    <v-list-item-title>Redigera filnamn</v-list-item-title>
                    <v-list-item-action>
                        <v-icon color="primary">mdi-file-edit-outline</v-icon>
                    </v-list-item-action>
                </v-list-item
                ><v-list-item @click="confirmFileDelete" color="error">
                    <v-list-item-title>Ta bort</v-list-item-title>
                    <v-list-item-action>
                        <v-icon color="error">mdi-delete-outline</v-icon>
                    </v-list-item-action>
                </v-list-item>
            </v-list>
        </v-menu>
        <v-dialog max-width="400px" v-model="editingFile">
            <v-card>
                <v-card-title>Redigerar filnamn</v-card-title>
                <v-card-text>
                        <v-text-field
                                autocomplete="off"
                                autofocus
                                clearable
                                :rules="[v => !!v || 'Du måste ange ett namn']"
                                hide-details
                                label="Namn"
                                outlined
                                v-model="fileName"
                        ></v-text-field>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-btn @click="editingFile = false" text>Avbryt</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn @click="fileUpdated" color="primary" text>Spara</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        </span>
</template>

<script>
import filetypes from "../../../models/filetypes";
import api from "../../../api/api";

export default {
        components: {},
        data: () => ({
            fileTypes: filetypes.TYPES,
            actionsMenu: false,
            editingFile: false,
            menuX: null,
            menuY: null,
            fileName: "",
        }),
        props: {
            file: Object,
            showActions: Boolean,
            multiple: Boolean,
        },
        computed: {
            fileData()
            {
                return this.file.fileData
            },
            itemAttrs()
            {
                return {
                    download: this.file.name,
                    href: this.showActions ? null : this.getFileUrl(this.file.fileData),
                    tag: this.showActions ? 'li' : 'a',
                    target: '_blank'
                }
            },
            itemEvents()
            {
                if (this.showActions)
                {
                    return {
                        click: this.showActionsMenu
                    }
                }
                return {}
            }
        },
        methods: {
            getFileUrl(file)
            {
                return api.BASE_URL + file.src
            },
            editFile()
            {
                this.fileName = this.file.name
                this.editingFile = true
            },
            showActionsMenu(event)
            {
                event.preventDefault()
                this.actionsMenu = false
                this.menuX = event.clientX
                this.menuY = event.clientY
                this.$nextTick(() =>
                {
                    this.actionsMenu = true
                })
            },
            fileUpdated()
            {
                if (this.fileName && this.fileName.length > 0)
                {
                    this.$emit("fileUpdated", this.file, this.fileName)
                }
            },
            confirmFileDelete()
            {
                this.$store.commit('setDialog', {
                    active: true,
                    closeBtnText: "Avbryt",
                    actionBtnText: "Ta bort",
                    title: "Bekräfta borttagning",
                    text: "Är du säker på att du vill ta bort " + this.file.name + "?",
                    actionClick: () =>
                    {
                        this.fileDeleted()
                    }
                })
            },
            fileDeleted()
            {
                this.$emit("fileDeleted", this.file)
            }
        }
    }
</script>
<style scoped>
    .v-list-item__action, .v-list-item__avatar, .v-list-item__icon {
        display: inline;
    }
</style>
