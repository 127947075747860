<template>
  <div>
    <v-dialog v-model="dialogProp" max-width="800px">
      <v-card>
        <v-card-title>
          Välj dynamiskt dokument
          <v-spacer></v-spacer>
          <v-icon color="primary">mdi-file-document-outline</v-icon>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="mt-3">
          <dynamic-documents-list-component @itemClick="selectDynamicDocument"></dynamic-documents-list-component>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn text @click="dialogProp = false">Avbryt</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

import DynamicDocumentsListComponent from "@/components/dynamicdocument/DynamicDocumentsListComponent";

export default {
  components: {
    DynamicDocumentsListComponent
  },
  props: {
    dialog: Boolean,
  },
  computed: {
    dialogProp: {
      get: function ()
      {
        return this.dialog;
      },
      set: function (newValue)
      {
        this.$emit('update:dialog', newValue)
      }
    },
  },
  methods: {
    selectDynamicDocument(dynamicDocument)
    {
      this.$emit('select', dynamicDocument)
    },
  }
}
</script>
