<template>
    <v-dialog v-model="dialogIsOpen" persistent max-width="600px">
        <v-card>
            <v-card-title avatar class="secondary white--text">
                <span class="headline">{{titleText}}</span>
            </v-card-title>
            <v-card-text>
                <v-container grid-list-xl>
                    <v-layout row wrap>
                        <v-flex xs12>
                            <v-form
                                    @submit.prevent
                                    ref="form"
                                    v-model="validForm"

                            >
                                <v-text-field
                                        v-model="attributeGroup.name"
                                        :counter="100"
                                        label="Namn"
                                        :rules="[v => !!v || 'Du måste ange ett namn']"
                                        required
                                        autofocus
                                        autocomplete="off"
                                        @keyup.enter="saveAttributeGroup"
                                ></v-text-field>
                            </v-form>
                        </v-flex>
                    </v-layout>
                </v-container>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn :loading="loading" text @click="closeDialog">Avbryt</v-btn>
                <v-btn color="primary" :loading="loading" text
                       @click="saveAttributeGroup">Spara
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>

import api from "../../api/api"

export default {
        data: () => ({
            validForm: true,
            loading: false,
            attributeGroup: {
                id: 0,
                name: "",
                active: 1,
            }
        }),
        watch:{
            async dialogIsOpen(){
                if (this.attributeGroupId > 0 && this.dialogIsOpen)
                {
                    let serverResponse = await api.getFromEndpoint(api.ENDPOINTS.ATTRIBUTEGROUPS + "/" + this.attributeGroupId)
                    if (serverResponse)
                    {
                        Object.assign(this.attributeGroup, serverResponse);
                    }
                }
                else {
                    this.resetAttributeGroupForm()
                }
            }
        },
        props: {
            attributeGroupId: Number,
            dialogIsOpen: Boolean
        },
        computed: {
            titleText()
            {
                if (this.attributeGroupId > 0)
                {
                    return `Redigerar ${this.attributeGroup.name}`
                }
                return "Ny attributgrupp"
            }
        },
        methods: {
            resetAttributeGroupForm()
            {
                Object.assign(this.attributeGroup,
                    {
                        id: 0,
                        name: "",
                        hasValue: 0,
                        icon: "",
                        active: 1,
                        attributeGroup: {
                            id: 0,
                            name: "",
                            active: 1
                        }
                    }
                )
            },
            closeDialog()
            {
                this.$emit('update:dialogIsOpen', false)
            },
            async saveAttributeGroup()
            {
                this.$refs.form.validate()
                if (this.validForm)
                {
                    this.$store.commit('setAppIsLoading', true)
                    let saveText = `${this.attributeGroup.name} skapad`
                    this.loading = true
                    if (this.attributeGroup.id > 0)
                    {
                        saveText = `${this.attributeGroup.name} uppdaterad`
                        this.attributeGroup = await api.putToEndpoint(api.ENDPOINTS.ATTRIBUTEGROUPS + "/" + this.attributeGroup.id, this.attributeGroup)
                    }
                    else
                    {
                        this.attributeGroup = await api.postToEndpoint(api.ENDPOINTS.ATTRIBUTEGROUP, this.attributeGroup)
                    }
                    this.loading = false
                    this.$store.commit('setAppIsLoading', false)
                    if (this.attributeGroup != null)
                    {
                        this.$store.commit('setSnackbar', {color: "success", text: saveText})
                        this.resetAttributeGroupForm()
                        this.$emit('saved', this.attributeGroup)
                        this.closeDialog()
                    }
                }
            },
        }
    }
</script>

<style scoped>

</style>
