<template>
  <v-card flat :disabled="loading" :loading="loading">
    <booking-component v-if="!loading" flat
                       :booking-object="bookingObject"
                       :user-account="userAccount"
                       :external-booker-settings="data.externalBookerSettings"
                       :uuid="website.bookingObjectUuid"
                       :error="error"
    ></booking-component>
  </v-card>
</template>

<script>

import BookingComponent from "@/components/externalbooking/BookingComponent";
import Axios from "axios";
import api from "@/api/api";

export default {
  components: {BookingComponent},
  props: {
    section: Object,
    website: Object,
  },
  data: () => ({
    bookingObject: null,
    userAccount: null,
    loading: false,
    error: {},
  }),
  computed: {
    data: {
      get()
      {
        return this.section.data
      }
    },
  },
  async mounted()
  {
    this.loading = true
    let uuid = this.website.bookingObjectUuid
    await this.getBookingObjectFromUuid(uuid)
    await this.getUserAccountFromUuid(uuid)
    this.loading = false
  },
  methods: {
    async getBookingObjectFromUuid(uuid)
    {
      try
      {
        let response = await Axios.get(api.BASE_URL + api.API + api.ENDPOINTS.BOOKINGOBJECTS + "/" + api.ENDPOINTS.UUID + "/" + uuid,
            {
              headers: {
                [api.HEADERS.ContentType]: [api.HEADERS.ApplicationJson]
              }
            })
        let data = response.data
        if (data)
        {
          this.bookingObject = data
        }
      }
      catch (error)
      {
        // Treat network errors without responses as 500s.
        const status = error.response ? error.response.status : 500
        if (status === 401 || status === 403 || status === 404)
        {
          // Unauthorised or forbidden
          this.error.msg = "Ogiltig URL"
        }
        else
        {
          this.error.msg = "Kunde inte kommunicera med servern. Vänligen kontrollera dina nätverksinställningar"
        }
      }
    },
    async getUserAccountFromUuid(uuid)
    {
      try
      {
        let userResponse = await Axios.get(api.BASE_URL + api.API + api.ENDPOINTS.BOOKINGOBJECTS + "/" + api.ENDPOINTS.UUID + "/" + uuid + "/" + api.ENDPOINTS.USERACCOUNT,
            {
              headers: {
                [api.HEADERS.ContentType]: [api.HEADERS.ApplicationJson]
              }
            })
        let userResponseData = userResponse.data
        if (userResponseData)
        {
          this.userAccount = userResponseData
        }
      }
      catch (error)
      {
        // Treat network errors without responses as 500s.
        const status = error.response ? error.response.status : 500
        if (status === 401 || status === 403 || status === 404)
        {
          // Unauthorised or forbidden
          this.error.msg = "Ogiltig URL"
        }
        else
        {
          this.error.msg = "Kunde inte kommunicera med servern. Vänligen kontrollera dina nätverksinställningar"
        }
      }
    }
  }
}
</script>
