<template>
  <v-dialog persistent v-model="dialogProp" scrollable max-width="800px">
    <v-card :loading="loading">
      <v-card-title>
        {{ multiple ? 'Välj bilder' : 'Välj bild' }}
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <image-picker-component @imageSelected="imageSelected" :images="images"
                                :multiple="multiple"></image-picker-component>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="dialogProp = false">Stäng</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

import ImagePickerComponent from "@/components/global/ImagePickerComponent";

export default {
  components: {ImagePickerComponent},
  props: {
    images: Array,
    loading: Boolean,
    dialog: Boolean,
    closeOnClick: Boolean,
    multiple: Boolean,
  },
  computed: {
    dialogProp: {
      get: function ()
      {
        return this.dialog;
      },
      set: function (newValue)
      {
        this.$emit('update:dialog', newValue)
      }
    },
  },
  methods: {
    imageSelected(selectedImage)
    {
      this.$emit("imageSelected", selectedImage)
      if (this.closeOnClick)
      {
        this.dialogProp = false
      }
    },
  }
}
</script>
