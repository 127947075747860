<template>
  <v-card flat v-if="section && section.data" class="text-center">
    <v-text-field
        outlined
        v-model="section.data.value"
        prepend-icon="mdi-map-search"
        @keyup.enter="keyupEnter"
        hint="Sök efter en plats som ska visas"
        :rules="requiredRules"
        class="mt-8"
        label="Plats">
    </v-text-field>
    <google-maps-component height="220px" :value="section.data.value" width="100%"></google-maps-component>
    <v-subheader class="pl-0">Bredd</v-subheader>
    <v-slider
        v-model="section.data.width"
        :max="100"
        :min="10"
        step="5"
        thumb-label="always"
        :thumb-size="45"
    >
      <template v-slot:thumb-label="{ value }">
        {{ value }}%
      </template>
    </v-slider>
    <v-subheader class="pl-0">Höjd</v-subheader>
    <v-checkbox class="mb-12" label="Fast höjd" @change="heightEnabledChanged" v-model="heightEnabled"></v-checkbox>
    <v-slide-y-transition>
      <v-slider
          v-model="section.data.height"
          :max="2000"
          :min="100"
          step="5"
          thumb-label="always"
          :thumb-size="45"
          v-if="heightEnabled"
      >
        <template v-slot:thumb-label="{ value }">
          {{ value }}px
        </template>
      </v-slider>
    </v-slide-y-transition>
  </v-card>
</template>

<script>


import ImagePickerComponent from "@/components/global/ImagePickerComponent";
import FileUploadComponent from "@/components/global/file/FileUploadComponent";
import AvatarAdminComponent from "@/components/global/AvatarAdminComponent";
import GoogleMapsComponent from "@/components/global/GoogleMapsComponent";

export default {
  components: {AvatarAdminComponent, FileUploadComponent, ImagePickerComponent, GoogleMapsComponent},
  data: () =>
  {
    return {
      menu: false,
      heightEnabled: true,
      defaultHeight: 400,
      defaultWidth: 100,
      requiredRules: [
        v => !!v || 'Obligatoriskt fält'
      ]
    }
  },
  props: {
    value: Object,
    website: Object,
    bookingObject: Object
  },
  computed: {
    section: {
      get()
      {
        return this.value
      },
      set(val)
      {
        this.$emit('update:value', val)
      }
    },
  },
  async mounted()
  {
    this.handleInitData()
  },
  methods: {
    keyupEnter()
    {
      this.$emit("keyupEnter")
    },
    handleInitData()
    {
      if (!this.section.data)
      {
        this.section.data = {
          value: "",
          width: this.defaultWidth,
          height: this.defaultHeight
        }
      }
    },
    heightEnabledChanged() {
      if(!this.section.data.height)
      {
        this.section.data.height = this.defaultHeight
      }
      else
      {
        this.section.data.height = null
      }
    }
  }
}
</script>
