<template>
  <v-container v-if="!isEmbedded" fill-height fluid>
    <v-layout justify-center align-center>
      <v-flex lg6 xs12>
        <transition appear mode="out-in" name="slide-x-transition">
          <uthyrd-loader-component loading-text="Laddar in lediga perioder"
                                   v-if="loading"></uthyrd-loader-component>
          <div v-else>
            <booking-component
                :error.sync="error"
                :is-embedded="isEmbedded"
                :show-logo="!isEmbedded"
                :show-image="!isEmbedded"
                :flat="isEmbedded"
                :booking-object="bookingObject"
                :external-booker-settings="externalBookerSettings"
                :read-only="readOnly"
                :user-account="userAccount"
                :uuid="uuid">
            </booking-component>
          </div>
        </transition>
      </v-flex>
    </v-layout>
  </v-container>
  <div v-else>
    <transition appear mode="out-in" name="slide-x-transition">
      <uthyrd-loader-component loading-text="Laddar in lediga perioder"
                               v-if="loading"></uthyrd-loader-component>
      <div v-else>
        <booking-component
            :error.sync="error"
            :is-embedded="isEmbedded"
            :show-logo="!isEmbedded"
            :show-image="!isEmbedded"
            :flat="isEmbedded"
            :booking-object="bookingObject"
            :external-booker-settings="externalBookerSettings"
            :read-only="readOnly"
            :user-account="userAccount"
            :uuid="uuid">
        </booking-component>
      </div>
    </transition>
  </div>
</template>

<script>
import UthyrdLoaderComponent from "../../components/global/UthyrdLoaderComponent";
import {getQueryParamFromUrl} from "../../util/functions";
import Axios from "axios";
import api from "../../api/api";
import BookingComponent from "@/components/externalbooking/BookingComponent";

export default {
  components: {
    BookingComponent,
    UthyrdLoaderComponent
  },
  data: () => ({
    loading: false,
    bookingObject: null,
    readOnly: false,
    error: {},
    externalBookerSettings: {
      showImage: true,
      showDescription: true,
      title: "",
      subtitle: "",
      readOnly: false,
    },
    uuid: "",
    userAccount: null,
    isEmbedded: false,
  }),
  async mounted()
  {
    let uuid = getQueryParamFromUrl("uuid")
    this.uuid = uuid
    this.readOnly = getQueryParamFromUrl("readOnly") === 'true'
    this.isEmbedded = getQueryParamFromUrl("embedded") === 'true'
    if (!uuid)
    {
      this.error.msg = "Ogiltig URL"
      return
    }
    // await since we want loading to be true until both are completed
    this.loading = true
    await this.getBookingObjectFromUuid(uuid)
    await this.getExternalBookerSettings(uuid)
    await this.getUserAccountFromUuid(uuid)
    this.loading = false
  },
  methods: {
    async getExternalBookerSettings()
    {
      try
      {
        let response = await Axios.get(api.BASE_URL + api.API + api.ENDPOINTS.BOOKINGOBJECTS + "/" + this.bookingObject.id + "/" + api.ENDPOINTS.EXTERNALBOOKERSETTINGS,
            {
              headers: {
                [api.HEADERS.ContentType]: [api.HEADERS.ApplicationJson]
              }
            })
        let data = response.data
        if (data)
        {
          this.externalBookerSettings = data
        }
      }
      catch (error)
      {
        // Treat network errors without responses as 500s.
        const status = error.response ? error.response.status : 500
        if (status === 401 || status === 403 || status === 404)
        {
          // Unauthorised or forbidden
          this.error.msg = "Ogiltig URL"
        }
        else
        {
          this.error.msg = "Kunde inte kommunicera med servern. Vänligen kontrollera dina nätverksinställningar"
        }
      }
    },
    async getBookingObjectFromUuid(uuid)
    {
      try
      {
        let response = await Axios.get(api.BASE_URL + api.API + api.ENDPOINTS.BOOKINGOBJECTS + "/" + api.ENDPOINTS.UUID + "/" + uuid,
            {
              headers: {
                [api.HEADERS.ContentType]: [api.HEADERS.ApplicationJson]
              }
            })
        let data = response.data
        if (data)
        {
          this.bookingObject = data
        }
      }
      catch (error)
      {
        // Treat network errors without responses as 500s.
        const status = error.response ? error.response.status : 500
        if (status === 401 || status === 403 || status === 404)
        {
          // Unauthorised or forbidden
          this.error.msg = "Ogiltig URL"
        }
        else
        {
          this.error.msg = "Kunde inte kommunicera med servern. Vänligen kontrollera dina nätverksinställningar"
        }
      }
    },
    async getUserAccountFromUuid(uuid)
    {
      try
      {
        let userResponse = await Axios.get(api.BASE_URL + api.API + api.ENDPOINTS.BOOKINGOBJECTS + "/" + api.ENDPOINTS.UUID + "/" + uuid + "/" + api.ENDPOINTS.USERACCOUNT,
            {
              headers: {
                [api.HEADERS.ContentType]: [api.HEADERS.ApplicationJson]
              }
            })
        let userResponseData = userResponse.data
        if (userResponseData)
        {
          this.userAccount = userResponseData
        }
      }
      catch (error)
      {
        // Treat network errors without responses as 500s.
        const status = error.response ? error.response.status : 500
        if (status === 401 || status === 403 || status === 404)
        {
          // Unauthorised or forbidden
          this.error.msg = "Ogiltig URL"
        }
        else
        {
          this.error.msg = "Kunde inte kommunicera med servern. Vänligen kontrollera dina nätverksinställningar"
        }
      }
    },
  }
}
</script>
