<template>
  <v-list-item two-line>
    <v-list-item-content>
      <v-list-item-title> {{ websiteVisitLog.createdAt | calendarDate($moment) }}</v-list-item-title>
      <v-list-item-subtitle> {{ websiteVisitLog.ipAddress }} </v-list-item-subtitle>
      <v-list-item-subtitle class="grey--text caption"> {{ websiteVisitLog.userAgent }} </v-list-item-subtitle>
    </v-list-item-content>
    <v-list-item-icon>
        <v-icon :color="deviceTypeIconColor">{{ deviceTypeIcon }}</v-icon>
    </v-list-item-icon>
  </v-list-item>
</template>

<script>

import deviceType from "@/models/deviceType";

export default {
  components: {},
  data: () => ({}),
  props: {
    websiteVisitLog: Object,
  },
  filters: {
    calendarDate(val, moment)
    {
      return moment(val).calendar()
    }
  },
  computed: {
    deviceTypeIcon() {
      switch (this.websiteVisitLog.deviceType)
      {
        case deviceType.types.MOBILE:
          return "mdi-cellphone"
        case deviceType.types.TABLET:
            return "mdi-tablet"
        default:
          return "mdi-monitor"
      }
    },
    deviceTypeIconColor() {
      switch (this.websiteVisitLog.deviceType)
      {
        case deviceType.types.MOBILE:
          return "info"
        case deviceType.types.TABLET:
            return "accent"
        default:
          return "primary"
      }
    }
  }
}
</script>
