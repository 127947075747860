<template>
    <transition appear name="fade-transition">
        <div class="animatable text-center" style="position: relative; margin-top:10%">
            <div id="loader">
                <logo-component class="align-middle" color="#837fb6" height="256px" hide-text pulse
                                width="256px"></logo-component>
            </div>
            <p class="title text-xs-center ml-2 mt-n6 primary--text" v-if="loadingText && loadingText.length > 0">
                {{loadingText}}</p>
        </div>
    </transition>
</template>

<script>
import LogoComponent from "./LogoComponent";

export default {
        components: {LogoComponent},
        props: {loadingText: String},
    }
</script>
