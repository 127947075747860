<template>
  <v-card :disabled="loading" :loading="loading" flat v-if="section && section.data" class="text-center">
    <v-slide-x-transition mode="out-in">
      <v-card v-if="fileUpload">
        <file-upload-component flat @fileUploaded="imagesUploaded" :maxfiles="25"
                               acceptedFileTypes="image/*" :max-filesize="10"></file-upload-component>
        <v-card-actions>
          <v-btn color="primary" @click="cancel">Avbryt</v-btn>
        </v-card-actions>
      </v-card>
      <v-menu close-on-content-click v-else v-model="menu"
              transition="slide-y-reverse-transition">
        <template v-slot:activator="{on}">
          <avatar-admin-component v-on="on" tile :avatar-src="selectedImage"
                                  :size="256"
                                  @avatarClick="menu = true"
          ></avatar-admin-component>
        </template>
        <v-list class="pa-0">
          <v-list-item @click="fileUpload = true">
            <v-list-item-title>Ladda upp nya bilder</v-list-item-title>
            <v-list-item-action>
              <v-icon color="accent-darken">mdi-cloud-upload-outline</v-icon>
            </v-list-item-action>
          </v-list-item>
          <v-list-item @click="filePicker = true">
            <v-list-item-title>Välj bilder från uthyrningsobjekt</v-list-item-title>
            <v-list-item-action>
              <v-icon color="primary">mdi-file-cabinet</v-icon>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-slide-x-transition>
    <v-alert type="error" dense border="right" elevation="1" colored-border transition="slide-x-transition"
             :value="(!section.data.bookingObjectFiles || section.data.bookingObjectFiles.length === 0) && !filePicker && !fileUpload">
      <span
          class="text-left grey--text text--darken-2">Du måste välja minst en bild för att kunna spara sektionen</span>
    </v-alert>
    <v-subheader class="pl-0">Ordning & bildtexter</v-subheader>
    <v-list>
      <p class="grey--text" key="empty_txt"
         v-if="!section.data.bookingObjectFiles || section.data.bookingObjectFiles.length === 0">
        Du måste ladda upp minst en bild för att ändra ordning och text</p>
      <draggable handle=".drag-item" v-model="section.data.bookingObjectFiles">
        <transition-group name="fade-transition">
          <v-list-item :key="'imagelist_' + index" v-for="(image, index) in section.data.bookingObjectFiles">
            <v-list-item-icon class="drag-item" style="cursor: grab;">
              <v-btn icon color="error" @click="removeImageFromList(image, index)">
                <v-icon>
                  mdi-close-circle
                </v-icon>
              </v-btn>
              <v-icon color="grey">
                mdi-drag-horizontal
              </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-text-field clearable single-line hide-details outlined v-model="image.name">
              </v-text-field>
            </v-list-item-content>
            <v-list-item-avatar tile>
              <v-img :src="getImageUrl(image.fileData)"></v-img>
            </v-list-item-avatar>
          </v-list-item>
        </transition-group>
      </draggable>
    </v-list>
    <v-subheader class="pl-0">Visningsalternativ</v-subheader>
    <v-checkbox label="Visa bildtexter" persistent-hint hint="Visar namnet på filen som går att ändra i listan ovan"
                v-model="section.data.showCaptions"></v-checkbox>
    <v-checkbox hide-details label="Växla bilder automatiskt" v-model="section.data.autoplay"></v-checkbox>
    <v-slide-y-transition>
      <v-text-field
          outlined
          dense
          class="mt-3"
          v-if="section.data.autoplay"
          v-model="section.data.delay"
          prepend-icon="mdi-timer"
          @keyup.enter="keyupEnter"
          type="number"
          hint="Fördröjning i sekunder mellan varje bild"
          :rules="requiredRules"
          style="width: 90%"
          suffix="sek."
          label="Fördröjning">
      </v-text-field>
    </v-slide-y-transition>
    <v-checkbox label="Visa knappar för att bläddra" v-model="section.data.showPrevNext"></v-checkbox>
    <v-checkbox label="Visa bildavgränsare" v-model="section.data.showDelimiters"></v-checkbox>
    <v-subheader class="pl-0">Bredd</v-subheader>
    <v-slider
        v-model="section.data.width"
        :max="100"
        :min="10"
        step="5"
        thumb-label="always"
        :thumb-size="45"
    >
      <template v-slot:thumb-label="{ value }">
        {{ value }}%
      </template>
    </v-slider>
    <v-subheader class="pl-0">Höjd</v-subheader>
    <v-checkbox class="mb-12" label="Fast höjd" @change="heightEnabledChanged" v-model="heightEnabled"></v-checkbox>
    <v-slide-y-transition>
      <v-slider
          v-model="section.data.height"
          :max="2000"
          :min="100"
          step="5"
          thumb-label="always"
          :thumb-size="45"
          v-if="heightEnabled"
      >
        <template v-slot:thumb-label="{ value }">
          {{ value }}px
        </template>
      </v-slider>
    </v-slide-y-transition>
    <file-picker-dialog v-model="section.data.bookingObjectFiles"
                        ref="filepicker"
                        :dialog.sync="filePicker" multiple
                        :files="bookingObjectImages"></file-picker-dialog>
  </v-card>
</template>

<script>

import filetypes from "@/models/filetypes";
import FileUploadComponent from "@/components/global/file/FileUploadComponent";
import api from "@/api/api";
import AvatarAdminComponent from "@/components/global/AvatarAdminComponent";
import draggable from 'vuedraggable'
import FilePickerDialog from "@/components/global/file/FilePickerDialog";

export default {
  components: {FilePickerDialog, AvatarAdminComponent, FileUploadComponent, draggable},
  data: () =>
  {
    return {
      menu: false,
      fileUpload: false,
      filePicker: false,
      loading: false,
      heightEnabled: true,
      defaultDelay: 6,
      defaultHeight: 400,
      defaultWidth: 100,
      requiredRules: [
        v => !!v || 'Obligatoriskt fält'
      ],
      uploadTimer: null,
      fileIndex: null
    }
  },
  props: {
    value: Object,
    website: Object,
    bookingObject: Object
  },
  computed: {
    section: {
      get()
      {
        return this.value
      },
      set(val)
      {
        this.$emit('update:value', val)
      }
    },
    bookingObjectProp: {
      get()
      {
        return this.bookingObject
      },
      set(val)
      {
        this.$emit('update:bookingObject', val)
      }
    },
    bookingObjectImages() // filtered array with only images
    {
      return this.bookingObjectProp.files.filter(file => file.fileData.filetype === filetypes.TYPES.IMAGE)
    },
    selectedImage()
    {
      if (this.section.data.bookingObjectFiles && this.section.data.bookingObjectFiles.length > 0 && this.section.data.bookingObjectFiles[0].fileData.src)
      {
        return this.getImageUrl(this.section.data.bookingObjectFiles[0].fileData)
      }
      return ""
    },
  },
  watch: {
    filePicker(val)
    {
      if (val)
      {
        let selectedIds = []
        this.section.data.bookingObjectFiles.forEach((file) =>
        {
          file.selected = true;
          selectedIds.push(file.id)
        })
        this.$refs.filepicker.mapSelected(selectedIds)
      }
    }
  },
  async mounted()
  {
    this.handleInitData()
  },
  methods: {
    keyupEnter()
    {
      this.$emit("keyupEnter")
    },
    cancel()
    {
      this.filePicker = false
      this.fileUpload = false
      this.menu = false
    },
    handleInitData()
    {
      if (!this.section.data)
      {
        this.section.data = {
          bookingObjectFiles: [],
          autoplay: true,
          showPrevNext: false,
          showDelimiters: true,
          showCaptions: true,
          width: this.defaultWidth,
          height: this.defaultHeight,
          delay: this.defaultDelay
        }
      }
    },
    async imagesUploaded(response, file)
    {
      response.forEach((fileData) =>
      {
        let bookingObjectFile = {
          name: file.name,
          fileData: fileData,
          bookingObjectId: this.bookingObject.id
        }
        if (!this.bookingObject.files)
        {
          this.bookingObject.files = []
        }

        this.bookingObject.files.push(bookingObjectFile)

        this.saveBookingObjectFiles()
      })
    },
    async saveBookingObjectFiles() // when an image is uploaded we update the BookingObject (timeout since multi-file upload triggers for each file)
    {
      if (!this.fileIndex) // fileIndex is used to keep track of files to add to the secition data
      {
        this.fileIndex = this.bookingObject.files.length
      }

      clearTimeout(this.uploadTimer)
      this.uploadTimer = setTimeout(async () =>
      {
        this.loading = true
        this.bookingObjectProp = await api.putToEndpoint(api.ENDPOINTS.BOOKINGOBJECTS + "/" + this.bookingObjectProp.id, this.bookingObjectProp)
        if (this.bookingObjectProp)
        {
          this.$emit('showSnackbar', "success", "Bilderna har laddats upp och sparats i uthyrningsobjektet")
          this.cancel()
          this.$nextTick(() =>
          {
            this.section.data.bookingObjectFiles.push(...this.bookingObjectProp.files.filter((file, index) => index >= this.fileIndex))
            this.fileIndex = null
          })
        }
        this.loading = false
      }, 600)
    },
    getImageUrl(fileData)
    {
      return api.BASE_URL + fileData.src
    },
    removeImageFromList(image, index)
    {
      image.selected = false
      this.section.data.bookingObjectFiles.splice(index, 1)
    },
    heightEnabledChanged()
    {
      if (!this.section.data.height)
      {
        this.section.data.height = this.defaultHeight
      }
      else
      {
        this.section.data.height = null
      }
    }
  }
}
</script>
