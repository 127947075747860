<template>
    <v-container fill-height fluid>
        <v-layout align-center justify-center>
            <v-flex lg6 md10 xs12>
                <v-slide-y-transition appear mode="out-in">
                    <v-stepper class="my-5" v-model="step">
                        <v-stepper-header>
                            <v-stepper-step :complete="step > 1" step="1">Välkommen</v-stepper-step>
                            <v-divider></v-divider>
                            <v-stepper-step :complete="step > 2" step="2">Kontoinställningar</v-stepper-step>
                            <v-divider></v-divider>
                            <v-stepper-step :complete="step > 3" step="3">Uthyrningsobjekt</v-stepper-step>
                            <v-divider></v-divider>
                            <v-stepper-step :complete="step > 4" step="4">Uthyrdmailadress</v-stepper-step>
                        </v-stepper-header>
                        <v-stepper-items>
                            <v-stepper-content class="pa-0" step="1">
                                <v-card>
                                    <v-parallax
                                            :src="require('../../assets/mountains.jpg')"
                                            class="dimmed-image"
                                            height="350"
                                    >
                                        <v-row
                                                align="center"
                                                justify="center"
                                        >
                                            <v-col class="text-center" cols="12">
                                                <h1 data-cy="welcomeHeader" class="display-1 font-weight-thin mb-4">Välkommen till Uthyrd!</h1>
                                                <h4 class="subheading">Full koll på det du hyr ut - allt samlat på ett
                                                    ställe och på dina villkor</h4>
                                            </v-col>
                                        </v-row>
                                    </v-parallax>
                                    <v-card-text>
                                        <intro-component></intro-component>
                                    </v-card-text>
                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn
                                                @click="nextStep"
                                                color="accent"
                                                data-cy="step1NextBtn"
                                        >
                                            Kom igång
                                            <v-icon class="ml-2">mdi-airplane-takeoff</v-icon>
                                        </v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-stepper-content>
                            <v-stepper-content class="pa-0" step="2">
                                <user-setup-component @previousStep="previousStep" @nextStep="nextStep"></user-setup-component>
                            </v-stepper-content>
                            <v-stepper-content class="pa-0" step="3">
                                <booking-object-setup-component @previousStep="previousStep" @nextStep="nextStep"></booking-object-setup-component>
                            </v-stepper-content>
                            <v-stepper-content class="pa-0" step="4">
                                <app-email-component :current-step="step" @previousStep="previousStep" @nextStep="nextStep"></app-email-component>
                            </v-stepper-content>
                        </v-stepper-items>
                    </v-stepper>
                </v-slide-y-transition>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
    import IntroComponent from "../../components/setupguide/IntroComponent";
    import UserSetupComponent from "../../components/setupguide/UserSetupComponent";
    import BookingObjectSetupComponent from "../../components/setupguide/BookingObjectSetupComponent";
    import AppEmailComponent from "../../components/setupguide/AppEmailComponent";

    export default {
        components: {AppEmailComponent, BookingObjectSetupComponent, UserSetupComponent, IntroComponent},
        data: () => ({
            step: 1,
        }),
        computed: {
            user()
            {
                return this.$store.state.user
            },
        },
        methods: {
            previousStep()
            {
                this.step--
            },
            nextStep()
            {
                this.step++
            },
        }
    }
</script>
