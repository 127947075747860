<template>
    <div>
        <v-subheader>Bifogade filer</v-subheader>
        <v-list dense subheader :color="light ? 'accent darken-1':'white'">
            <transition-group name="slide-x-transition" mode="out-in">
                <message-attachment-item-component v-for="(attachment, index) in attachments"
                                                   :key="attachment.name + '_' + index"
                                                   :light="light"
                                                   :attachment="attachment" @attachmentUpdated="attachmentUpdated"
                                                   @attachmentDeleted="attachmentDeleted"
                                                   :show-actions="showActions"></message-attachment-item-component>
            </transition-group>
        </v-list>
    </div>
</template>

<script>
    import MessageAttachmentItemComponent from "./MessageAttachmentItemComponent";

    export default {
        components: {MessageAttachmentItemComponent},
        data: () => ({}),
        props: {
            attachments: Array,
            showActions: Boolean,
            light: Boolean
        },
        methods: {
            attachmentDeleted(deletedAttachment)
            {
                let index = this.attachments.findIndex((attachment) => attachment.fileData.filename === deletedAttachment.fileData.filename)
                this.attachments.splice(index, 1)
                // TODO - if attachment.id > 0 delete from db aswell
            },
            attachmentUpdated(updatedAttachment, attachmentName)
            {
                let attachm = this.attachments.find((attachment) => attachment.fileData.filename === updatedAttachment.fileData.filename)
                attachm.name = attachmentName
                // TODO - if attachment.id > 0 update in db aswell
            }
        }
    }
</script>
