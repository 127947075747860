<template>
  <v-card flat :loading="loading" :disabled="loading">
    <div
        id="pdfContainer"
        v-if="!renderAsImg"
        style="width: 100%; height:calc(99vh - 50px);"
    ></div>
  </v-card>
</template>

<script>
import api from "../../api/api";
import Pdfobject from 'pdfobject'

export default {
  data: () => ({
    pdfSrc: null,
    imgSrc: null,
    loading: false,
  }),
  props: {
    bookingPeriodId: Number,
    dynamicDocumentId: Number,
    content: String,
    renderAsImg: Boolean,
  },
  computed: {
    idProvided() {
      return this.dynamicDocumentId && this.dynamicDocumentId > 0
    }
  },
  methods: {
    refreshRender() {
      if(!this.renderAsImg)
      {
        this.getPdfSrc()
      }
      else
      {
        this.getImgSrc()
      }
    },
    async getPdfSrc()
    {
      this.loading = true
      let bookingPeriodIdQuery = this.bookingPeriodId ? '?bookingPeriodId=' + this.bookingPeriodId : ''

      if(this.idProvided)
      {
        // this is just an url
        this.pdfSrc = api.BASE_URL + api.API + api.ENDPOINTS.DYNAMICDOCUMENTS + "/" + this.dynamicDocumentId + "/" + api.ENDPOINTS.PDF + bookingPeriodIdQuery
        Pdfobject.embed(this.pdfSrc, '#pdfContainer')
      }
      else
      {
        // Since we use POST this returns the PDF as a base64 encoded string
        let document = {active: true, name: "preview", content: this.content}
        this.pdfSrc = await api.postToEndpoint(api.ENDPOINTS.DYNAMICDOCUMENT + "/" + api.ENDPOINTS.PDF + bookingPeriodIdQuery, document)
        Pdfobject.embed('data:application/pdf;base64,' + this.pdfSrc, '#pdfContainer')
      }
      this.loading = false
    },
    async getImgSrc()
    {
      // TODO
    },
  }
}
</script>
