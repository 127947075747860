<template>
  <div>
    <v-navigation-drawer
        v-model="dialogProp"
        fixed
        temporary
        :permanent="dialogProp"
        width="28%"
        style="min-width: 400px;"
        :hide-overlay="!!editing"
    >
      <v-card :loading="loading" flat :disabled="loading">
        <v-card-title>
          {{ title }}
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="py-4">
          <v-form ref="form" @submit.prevent>
            <section-type-autocomplete-component
                prepend-icon="mdi-format-list-bulleted-type"
                label="Välj typ"
                placeholder="Exempelvis textfält, rubrik, bildspel, osv"
                solo
                required
                v-if="sectionObj"
                @change="section.data = null"
                :value.sync="sectionObj.websiteSectionType"
                clearable
                :disabled="!!editing"
            ></section-type-autocomplete-component>
            <v-slide-y-transition>
              <div v-if="sectionObj && sectionObj.websiteSectionType">
                <v-slide-x-transition mode="out-in">
                  <component @showSnackbar="showSnackbar" @keyupEnter="submit" :website="website"
                             :value.sync="sectionObj" :booking-object.sync="bookingObjectProp"
                             :is="typeInputComponent"></component>
                </v-slide-x-transition>
              </div>
            </v-slide-y-transition>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn @click="dialogProp = false" v-if="!editing" text>Avbryt</v-btn>
          <v-btn color="error" v-if="editing" @click="confirmDelete" text>Ta bort</v-btn>
          <v-spacer></v-spacer>
          <v-btn @click="submit"
                 color="primary"
                 text>Spara
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-navigation-drawer>
  </div>
</template>

<script>

import SectionTypeAutocompleteComponent from "@/components/website/section/SectionTypeAutocompleteComponent";
import sectionTypeOptions from "@/models/website/sectionTypeOptions";
import ParagraphSectionAdminComponent from "@/components/website/section/ParagraphSectionAdminComponent";
import HeaderSectionAdminComponent from "@/components/website/section/HeaderSectionAdminComponent";
import ImageSectionAdminComponent from "@/components/website/section/ImageSectionAdminComponent";
import EmptySectionAdminComponent from "@/components/website/section/EmptySectionAdminComponent";
import SlideshowSectionAdminComponent from "@/components/website/section/SlideshowSectionAdminComponent";
import AttributesSectionAdminComponent from "@/components/website/section/AttributesSectionAdminComponent";
import ExternalBookerSectionAdminComponent from "@/components/website/section/ExternalBookerSectionAdminComponent";
import GoogleMapsSectionAdminComponent from "@/components/website/section/GoogleMapsSectionAdminComponent";

export default {
  components: {
    SectionTypeAutocompleteComponent,
    ParagraphSectionAdminComponent,
    HeaderSectionAdminComponent,
    ImageSectionAdminComponent,
    EmptySectionAdminComponent,
    SlideshowSectionAdminComponent,
    AttributesSectionAdminComponent,
    ExternalBookerSectionAdminComponent,
    GoogleMapsSectionAdminComponent
  },
  data: () => ({
    loading: false,
    requiredRules: [
      v => !!v || 'Obligatoriskt fält'
    ],
    typeOptionsComponents: sectionTypeOptions.COMPONENTS,
  }),
  props: {
    dialog: Boolean,
    website: Object,
    section: Object,
    bookingObject: Object
  },
  computed: {
    dialogProp: {
      get: function ()
      {
        return this.dialog;
      },
      set: function (newValue)
      {
        this.$emit('update:dialog', newValue)
      }
    },
    bookingObjectProp: {
      get: function ()
      {
        return this.bookingObject;
      },
      set: function (newValue)
      {
        this.$emit('update:bookingObject', newValue)
      }
    },
    sectionObj: {
      get: function ()
      {
        return this.section;
      },
      set: function (newValue)
      {
        this.$emit('update:section', newValue)
      }
    },
    editing()
    {
      return this.sectionObj && (this.sectionObj.id || this.sectionObj.g_id)
    },
    title()
    {
      return this.editing ? 'Redigerar sektion' : 'Skapar ny sektion'
    },
    user()
    {
      return this.$store.state.user
    },
    typeInputComponent()
    {
      return this.sectionObj && this.sectionObj.websiteSectionType ? this.typeOptionsComponents[this.sectionObj.websiteSectionType.type] : null
    },
  },
  watch:
      {
        dialogProp()
        {
          if (!this.sectionObj)
          {
            this.sectionObj = this.initSection()
          }
        }
      },
  methods: {
    async submit()
    {
      if (this.$refs.form.validate())
      {
        if (this.editing)
        {
          this.updateSection()
        }
        else
        {
          this.createSection()
        }
      }
    },
    updateSection()
    {
      this.loading = true
      this.dialogProp = false
      this.loading = false
      this.$emit('change')
    },
    findSectionIndex(sectionToFind)
    {
      if (sectionToFind.g_id)
      {
        return this.website.sections.findIndex((section) => section.g_id === sectionToFind.g_id)
      }
      return this.website.sections.findIndex((section) => section.id === sectionToFind.id)
    },
    confirmDelete()
    {
      this.showConfirmDialog("Bekräfta borttagning", "Är du säker på att du vill ta bort sektionen?", "Ta bort", this.deleteSection, true)
    },
    createSection()
    {
      this.loading = true
      this.sectionObj.g_id = Math.floor(Math.random() * 999999) // used to keep track of unsaved sections
      this.website.sections.push(this.sectionObj)
      this.showSnackbar("primary", "Sektion tillagd!")
      this.dialogProp = false
      this.$emit('created', this.sectionObj)
      this.loading = false
      this.$emit('change')
    },
    initSection()
    {
      return Object.assign({}, {
        sortOrder: this.website.sections.length + 1,
        columnWidth: 1,
        data: null,
        websiteSectionType: null,
        websiteId: this.website.id,
      })
    },
    deleteSection()
    {
      this.showConfirmDialog("", "", "", null, false)
      let index = this.findSectionIndex(this.sectionObj)
      if (index >= 0)
      {
        this.showSnackbar("primary", "Sektion bortagen!")
        this.dialogProp = false
        this.$emit('change')
        this.website.sections.splice(index, 1)
      }
    },
    showSnackbar(color, text)
    {
      this.$emit('snackbar', {
        color: color,
        text: text,
        timeout: 6000,
        active: true
      })
    },
    showConfirmDialog(title, text, btnText, action, active)
    {
      this.$emit('confirm', {
        title: title,
        text: text,
        btnText: btnText,
        action: action,
        active: active
      })
    }
  }
}
</script>
