<template>
  <div v-if="shown">
    <v-autocomplete
        :items="customers"
        v-model="customerValue"
        @keyup.enter="keyupEnter"
        :required="required"
        :rules="rules"
        :solo="solo"
        :outlined="outlined"
        :prepend-icon="prepend"
        :label="label"
        return-object
        :item-text="getFullname"
        item-value="id"
        :hide-details="hideDetails"
        no-data-text="Din sökning gav inga resultat"
        :disabled="readOnly"
        :loading="loading"
        :hide-no-data="loading"
        @change="change"
        @click:clear="clear"
        :multiple="multiple"
        small-chips
        class="customer-select-input"
        :clearable="clearable"
    >
      <template v-slot:selection="data">
        <v-chip
            v-bind="data.attrs"
            label
            outlined
        >
          <initials-avatar-component :firstname="data.item.firstname" :imgSrc="getImageUrl(data.item)"
                                     class="mr-2"
                                     :size="15"
                                     :lastname="data.item.lastname"
          ></initials-avatar-component>
          {{ getFullname(data.item) }}
        </v-chip>
      </template>
      <template slot="prepend-item">
        <v-btn
            color="primary"
            outlined
            block
            class="mb-2"
            @click="newCustomer"
        >
          Skapa ny kund
          <v-icon right dark>mdi-account-plus-outline</v-icon>
        </v-btn>
      </template>
      <template v-slot:item="data">
        <template>
          <v-list-item-avatar>
            <initials-avatar-component :firstname="data.item.firstname" :imgSrc="getImageUrl(data.item)"
                                       class="mr-2"
                                       :size="30"
                                       :lastname="data.item.lastname"
            ></initials-avatar-component>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>
              {{ getFullname(data.item) }}
            </v-list-item-title>
            <v-list-item-subtitle>
              {{ data.item.email }}
              <br>
              {{ data.item.phone }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </template>
      </template>
    </v-autocomplete>
    <v-dialog v-model="dialogIsOpen" persistent max-width="600px">
      <customer-details-card-component @cancel="closeDialog" @saved="customerSaved" :shown="dialogIsOpen"
                                       cancel-btn></customer-details-card-component>
    </v-dialog>
  </div>
</template>

<script>
import api from "../../api/api";
import CustomerDetailsCardComponent from "./CustomerDetailsCardComponent";
import InitialsAvatarComponent from "@/components/global/InitialsAvatarComponent";

export default {
  components: {CustomerDetailsCardComponent, InitialsAvatarComponent},
  data: () =>
  {
    return {
      customers: [],
      loading: false,
      dialogIsOpen: false,
      shown: true
    }
  },
  props: {
    valueObject: Object,
    readOnly: Boolean,
    required: Boolean,
    multiple: Boolean,
    fetchAsync: Boolean,
    showWhenEmpty: Boolean,
    clearable: Boolean,
    solo: Boolean,
    outlined: Boolean,
    hideDetails: Boolean,
    prepend: {
      type: String,
      default: null
    },
  },
  computed: {
    rules()
    {
      return this.required ? [v => !!v || 'Du måste välja minst en kund'] : []
    },
    customerValue: {
      get()
      {
        return this.multiple ? this.valueObject.customers : this.valueObject.customer
      },
      set(val)
      {
        if (this.multiple)
        {
          this.valueObject.customers = val
        }
        else
        {
          this.valueObject.customer = val
        }
      }
    },
    label() {
      if(this.readOnly)
      {
        return this.multiple ? 'Kunder' : 'Kund'
      }
      return this.multiple ? 'Välj kunder' : 'Välj kund'
    }
  },
  watch: {
    valueObject: {
      deep: true,
      handler()
      {
        if (!this.showWhenEmpty)
        {
          if (this.multiple)
          {
            if (this.valueObject.customers.length <= 0 && this.readOnly)
            {
              this.shown = false
            }
          }
          else
          {
            if (this.valueObject.customer && this.readOnly)
            {
              this.shown = false
            }
          }
        }
      }
    }
  },
  async mounted()
  {
    this.loading = true
    if(!this.readOnly)
    {
      await this.getCustomers()
    }
    await this.doFetchAsync()
    this.loading = false
  },
  methods: {
    keyupEnter()
    {
      this.$emit("keyupEnter")
    },
    change(data)
    {
      if (this.multiple && this.valueObject.customers.length <= 1) // if this is the only contact, set it as the mainContact
      {
        if (data[0])
        {
          data[0].mainContact = true
        }
        else
        {
          data.mainContact = true
        }
      }
      this.$emit("change", data)
    },
    getImageUrl(item)
    {
      if (item.fileData && item.fileData.src)
      {
        return api.BASE_URL + item.fileData.src
      }
      return ""
    },
    async getCustomers()
    {
      let serverResponse = await api.getFromEndpoint(api.ENDPOINTS.USERACCOUNTS + "/" + this.$store.state.user.id + "/" + api.ENDPOINTS.CUSTOMERS)
      if (serverResponse)
      {
        this.customers = serverResponse
      }
    },
    getFullname(customer)
    {
      return customer.firstname + " " + customer.lastname
    },
    newCustomer()
    {
      this.dialogIsOpen = true
    },
    closeDialog()
    {
      this.dialogIsOpen = false
    },
    async customerSaved(customer)
    {
      await this.getCustomers()
      if (this.multiple)
      {
        this.valueObject.customers.push(customer)
      }
      else
      {
        this.valueObject.customer = customer
      }
      this.change(customer)
      this.closeDialog()
    },
    async doFetchAsync()
    {
      if (this.fetchAsync && this.valueObject && this.valueObject.id > 0) // fetchAsync is used to get customers connected to a BookingPeriod
      {
        let serverResponseCustomers = await api.getFromEndpoint(api.ENDPOINTS.BOOKINGPERIODS + "/" + this.valueObject.id + "/" + api.ENDPOINTS.CUSTOMERS)
        if (serverResponseCustomers)
        {
          this.customerValue = serverResponseCustomers
          if(this.readOnly)
          {
            this.customers = serverResponseCustomers
          }
        }
      }
    },
    clear()
    {
      this.customerValue = null
      this.$emit("change", this.customerValue)
    }
  }
}
</script>
<style>
.customer-select-input .v-chip--select.v-chip.v-chip--disabled.v-chip--no-color.theme--light.v-size--small {
  color: rgba(0, 0, 0, 0.9);
}

.customer-select-input .v-chip--disabled {
  opacity: 0.9;
}
</style>
