<template>
  <v-text-field
      v-model="value.socialSecurityNumber"
      v-mask="mask"
      :counter="50"
      :placeholder="placeholder"
      :prepend-icon="prependIcon"
      :readonly="readOnly"
      :rules="ssnRules"
      :solo="solo"
      autocomplete="off"
      label="Personnummer"
      @keyup.enter="keyupEnter"
  ></v-text-field>
</template>

<script>
import {mask} from 'vue-the-mask'
import {pidPattern} from "@/util/functions";

export default {
  directives: {
    mask,
  },
  data: () => ({
    // TODO - set these according to locale
    mask: '########-####',
  }),
  computed: {
    placeholder()
    {
      return this.solo ? "Personnummer (YYYYMMDD-XXXX)" : "YYYYMMDD-XXXX"
    },
    ssnRules()
    {
      return [
        v => !v || pidPattern.test(v) || 'Ogiltigt personnummer angivet!',
      ]
    }
  },
  props: {
    value: Object,
    readOnly: Boolean,
    solo: Boolean,
    prependIcon: String,
  },
  methods: {
    keyupEnter()
    {
      this.$emit("keyupEnter")
    }
  }
}
</script>

<style scoped>

</style>
