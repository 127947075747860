<template>
    <FullCalendar :height="calendarHeight"
                  @unselect="unselect" @select="select" :selectable="selectable" @dateClick="dateClick"
                  @eventClick="eventClick" @eventMouseEnter="eventMouseEnter" @eventMouseLeave="eventMouseLeave"
                  :header="header"
                  :button-text="buttonText" locale="sv" :week-numbers="true" week-label="V"
                  :defaultView="defaultView" :plugins="calendarPlugins"
                  :unselect-cancel="unselectCancel"
                  :events="events"
                  :editable="editable"
                  :unselect-auto="unselectAuto"
                  :default-date="defaultDate"
                  ref="fullCalendar"
                  :first-day="1"
                  :dates-render="datesRender"
                  :display-event-time="false"
    />
</template>

<script>
    import FullCalendar from '@fullcalendar/vue'
    import dayGridPlugin from '@fullcalendar/daygrid'
    import interaction from '@fullcalendar/interaction'

    export default {
        components: {
            FullCalendar
        },
        data: () => ({
            calendarPlugins: [dayGridPlugin, interaction],
            header: {
                left: 'title',
                center: '',
                right: 'today prev,next'
            },
            buttonText: {
                today: 'Idag',
                week: 'Vecka',
                month: 'Månad',
            },
            eventTimeFormat: { // like '14:30'
                hour: '2-digit',
                minute: '2-digit',
                meridiem: false
            }
        }),
        computed: {
            calendarHeight()
            {
                return this.height ? this.height : "auto"
            }
        },
        props: {
            defaultView: String,
            selectable: Boolean,
            selectMirror: Boolean,
            unselectCancel: String,
            events: [Array, Function, String],
            editable: Boolean,
            height: String,
            unselectAuto: Boolean,
            defaultDate: String,
            datesRender: Function
        },
        methods: {
            dateClick(data)
            {
                this.$emit("dateClick", data)
            },
            eventClick(data)
            {
                this.$emit("eventClick", data)
            },
            select(selection)
            {
                this.$emit("select", selection)
            },
            unselect(jsEvent, view)
            {
                this.$emit("unselect", {jsEvent: jsEvent, view: view})
            },
            refetchEvents()
            {
                this.$refs.fullCalendar.getApi().refetchEvents()
            },
            goToDate(date)
            {
                this.$refs.fullCalendar.getApi().gotoDate(date)
            },
            setCalendarSelection(start, end)
            {
                this.$refs.fullCalendar.getApi().gotoDate(start)
                this.$refs.fullCalendar.getApi().select(start, end)
            },
            eventMouseEnter(mouseEnterInfo) // hover
            {
                // make sure we highlight every connected html element
                document.querySelectorAll('a[href$="' + mouseEnterInfo.el.href + '"]').forEach((element) =>
                {
                    element.style.opacity = 0.8
                })
            },
            eventMouseLeave(mouseLeaveInfo) // unhover
            {
                // make sure we unhighlight every connected html element
                document.querySelectorAll('a[href$="' + mouseLeaveInfo.el.href + '"]').forEach((element) =>
                {
                    element.style.opacity = 1
                })
            },
        }
    }
</script>

<style lang='scss'>

    @import '~@fullcalendar/core/main.css';
    @import '~@fullcalendar/daygrid/main.css';

    // -------- Custom styling -------------

    .fc-toolbar h2 {
        font-size: 1.25em;
        margin: 0;
        color: #494949;
    }

    .fc-button-primary:disabled {
        color: #fff;
        background-color: #bcbcbc;
        border: none;
    }

    .fc-button-primary {
        color: #fff;
        background-color: #8380B6;
        border: none;
    }

    .fc-button-primary:hover:enabled, .fc-button-primary:active {
        color: #fff;
        background-color: #9f9cd2 !important;
        border: none;
        outline: none !important;
    }

    .fc-prev-button {
        margin-right: 2px;
    }

    .fc-button:focus {
        outline: none;
        -webkit-box-shadow: none;
        box-shadow: none;
    }

    th.fc-day-header.fc-widget-header {
        background-color: #8380B6;
        color: white;
    }

    .fc-unthemed td.fc-today {
        background-color: rgba(231, 231, 231, 0.3);
    }

    .fc-day.fc-widget-content {
        transition: all 0.1s;
    }

    .fc-day.fc-widget-content:hover {
        background-color: rgba(118, 109, 182, 0.16);
        cursor: pointer;
    }

    /*Allow pointer-events through*/
    .fc-slats, /*horizontals*/
    .fc-content-skeleton, /*day numbers*/
    .fc-bgevent-skeleton /*events container*/
    {
        pointer-events: none
    }

    /*Turn pointer events back on*/
    .fc-bgevent,
    .fc-event-container {
        pointer-events: auto; /*events*/
    }

    .fc-highlight {
        background-color: #bb87b0;
    }

    .fc-event {
        border: none;
        padding: 5px;
    }

    .fc-title {
        color: white;
    }

    td.fc-event-container:hover {
        transition: opacity 0.1s;
        opacity: 0.8;
    }

    span.fc-time {
        color: white;
    }

    .fc-button .fc-icon {
      vertical-align: bottom;
    }

</style>
