<template>
  <component :is="componentName" :website="website" :section="section"></component>
</template>

<script>
import sectionRenderComponents from "@/models/website/sectionRenderComponents";
import ParagraphSectionComponent from "@/components/website/render/ParagraphSectionComponent";
import HeaderSectionComponent from "@/components/website/render/HeaderSectionComponent";
import ImageSectionComponent from "@/components/website/render/ImageSectionComponent";
import EmptySectionComponent from "@/components/website/render/EmptySectionComponent";
import SlideshowSectionComponent from "@/components/website/render/SlideshowSectionComponent";
import AttributesSectionComponent from "@/components/website/render/AttributesSectionComponent";
import ExternalBookerSectionComponent from "@/components/website/render/ExternalBookerSectionComponent";
import GoogleMapsSectionComponent from "@/components/website/render/GoogleMapsSectionComponent";

export default {
  components: {
    ParagraphSectionComponent,
    HeaderSectionComponent,
    ImageSectionComponent,
    EmptySectionComponent,
    SlideshowSectionComponent,
    AttributesSectionComponent,
    ExternalBookerSectionComponent,
    GoogleMapsSectionComponent
  },
  data: () =>
  {
    return {
      renderComponents: sectionRenderComponents.COMPONENTS
    }
  },
  computed: {
    componentName()
    {
      return this.renderComponents[this.section.websiteSectionType.type]
    }
  },
  props: {
    section: Object,
    website: Object,
    readOnly: Boolean
  }
}
</script>

<style scoped>

</style>
