<template>
  <div>
    <div class="pa-4 calendar-container">
      <calendar-component ref="calendarComponent" :dates-render="datesRender"
                          :default-view="defaultView" :display-options="displayOptions"
                          :editable="false"
                          :events="getBookingPeriodsEvents"
                          :selectable="true"
                          :unselect-auto="true"
                          unselect-cancel=".v-menu__content *"
                          @eventClick="eventClick"
                          @select="calendarRangeSelected"
                          @unselect="calendarRangeUnselected"
      ></calendar-component>
    </div>
    <v-menu
        v-model="popoverShown"
        :close-on-click="false"
        :close-on-content-click="false"
        :left="menu.left"
        :min-width="$vuetify.breakpoint.smAndUp ? '500px':'100px'"
        :position-x="menu.x"
        :position-y="menu.y"
        :top="menu.top"
        absolute
        class="mb-3"
        offset-y
        transition="slide-y-transition"

    >
      <v-card :loading="popoverLoadingData" :disabled="popoverLoadingData">
        <v-card-text>
          <v-form ref="form" @submit.prevent>
            <div v-if="bookingPeriod.id && bookingPeriod.id > 0">
              <span class="text-h6">{{ bookingPeriod.name }}</span>
              <v-tooltip v-if="duePaymentRequestCount > 0" bottom>
                <template v-slot:activator="{ on }">
                  <v-chip v-on="on" class="float-right" color="error" label small>
                    <v-icon small>mdi-credit-card-clock-outline</v-icon>
                  </v-chip>
                </template>
                <span>{{ duePaymentRequestText }}</span>
              </v-tooltip>
              <v-tooltip v-if="!noPrice" :disabled="currentBalanceIsZero" bottom>
                <template v-slot:activator="{ on }">
                  <v-chip v-on="on" :color="balanceColor" :input-value="currentBalanceIsZero" class="float-right mr-2"
                          filter label outlined
                          small>
                    <v-icon class="mr-2" v-if="!currentBalanceIsZero" small>mdi-scale-balance</v-icon>
                    <span>{{ currentBalanceIsZero ? 'Betald' : bookingPeriod.currentBalance }}</span>
                  </v-chip>
                </template>
                <span>Kvar att betala</span>
              </v-tooltip>
              <v-tooltip :disabled="noPrice" bottom>
                <template v-slot:activator="{ on }">
                  <v-chip v-on="on" class="float-right mr-2" label outlined small>
                    <v-icon class="mr-2" small>mdi-tag-outline</v-icon>
                    <span v-if="noPrice">Inget pris satt</span>
                    <span v-else>{{ bookingPeriod.priceSeperated }} SEK</span>
                  </v-chip>
                </template>
                <span>Pris</span>
              </v-tooltip>
              <booking-period-status-card-component class="mt-2" :booking-period="bookingPeriod">
              </booking-period-status-card-component>
            </div>
            <booking-object-select-component :read-only="popoverReadOnly"
                                             :value="bookingPeriod"
                                             required
                                             @change="() => {checkDateCollision(); setStartEndDefaults(); checkAndSetEndAfterStart()}"></booking-object-select-component>
          </v-form>
          <customer-autocomplete-component
              v-if="bookingPeriod.status > bookingPeriodStatuses.OPEN.value"
              :value-object="bookingPeriod"
              multiple
              read-only
              fetch-async
              show-when-empty
          ></customer-autocomplete-component>
          <booking-period-start-end-component :booking-period="bookingPeriod"
                                              :read-only="bookingPeriod.id && bookingPeriod.id > 0"
                                              @changed="checkDateCollision(); checkAndSetEndAfterStart()"></booking-period-start-end-component>
          <div class="text-body-2 mt-6" v-if="bookingPeriod.notes && bookingPeriod.notes.length > 0">
            <v-subheader class="pl-0">Anteckningar:</v-subheader>
            <p style="max-width: 600px">{{ bookingPeriod.notes }}</p>
          </div>
        </v-card-text>
        <colliding-dates-component :booking-period="bookingPeriod"
                                   :colliding-dates="collidingDates"></colliding-dates-component>
        <v-card-actions class="mt-4">
          <div class="flex-grow-1">
          </div>
          <v-btn text @click="calendarRangeUnselected">Avbryt</v-btn>
          <v-btn color="primary" text @click="navigateToPeriod">{{ popoverActionBtnText }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
import CalendarComponent from "../calendar/CalendarComponent";
import calendarViews from "../../models/calendarViews";
import BookingObjectSelectComponent from "../bookingobject/BookingObjectSelectComponent";
import api from "../../api/api"
import BookingPeriodStartEndComponent from "./BookingPeriodStartEndComponent";
import CollidingDatesComponent from "./CollidingDatesComponent";
import paymentStatuses from "../../models/paymentStatuses";
import bookingPeriodStatuses from "../../models/bookingPeriodStatuses";
import {validateForm} from "@/util/functions";
import CustomerAutocompleteComponent from "@/components/customer/CustomerAutocompleteComponent";
import BookingPeriodStatusCardComponent from "@/components/bookingperiod/BookingPeriodStatusCardComponent";

export default {
  components: {
    BookingPeriodStatusCardComponent,
    CustomerAutocompleteComponent,
    CollidingDatesComponent,
    BookingPeriodStartEndComponent, BookingObjectSelectComponent, CalendarComponent
  },
  props: {
    displayOptions: Object,
    datesRender: Function
  },
  data: () => ({
    calendarViews: calendarViews,
    defaultView: calendarViews.views.dayGridMonth,
    bookingPeriod: {
      name: "",
      active: 1,
      price: 0,
      currentBalance: 0,
      notes: "",
      customers: null,
      paymentRequests: null,
      calendarEvent: {
        start: "",
        end: "",
      },
      bookingObject: null,
      status: 1,
      other: ""
    },
    popoverShown: false,
    menu: {
      x: 0,
      y: 0,
      top: false,
      left: false
    },
    popoverActionBtnText: "",
    popoverLoadingData: false,
    popoverReadOnly: false,
    collidingDates: [],
    bookingPeriodStatuses: bookingPeriodStatuses.STATUSES_OBJ
  }),
  computed: {
    noPrice()
    {
      return Number.parseInt(this.bookingPeriod.price) === 0
    },
    currentBalanceIsZero()
    {
      return this.bookingPeriod.currentBalance === 0
    },
    balanceColor()
    {
      return this.currentBalanceIsZero ? 'success' : 'error'
    },
    duePaymentRequestCount()
    {
      if (this.bookingPeriod.paymentRequests)
      {
        return this.bookingPeriod.paymentRequests.filter(req => req.status === paymentStatuses.STATUSES.PAYMENT_DUE.value).length
      }
      return 0
    },
    duePaymentRequestText()
    {
      if (this.duePaymentRequestCount === 1)
      {
        return `Denna period har ${this.duePaymentRequestCount} förfallen betalningsförfrågning!`
      }
      return `Denna period har ${this.duePaymentRequestCount} förfallna betalningsförfrågningar!`
    }
  },
  methods: {
    showPopover(jsEvent)
    {
      this.menu.left = jsEvent.clientX > (window.innerWidth / 2)
      this.menu.top = jsEvent.clientY > (window.innerHeight / 2)
      this.menu.x = jsEvent.clientX
      this.menu.y = jsEvent.clientY

      this.popoverShown = true
    },
    calendarRangeSelected(data)
    {
      // add the default check-in and check-out
      let defaultCheckIn = "16:00"
      let defaultCheckOut = "10:00"
      data.startStr += " " + defaultCheckIn
      // set endStr one day back to allow the check-out time
      let endDate = new Date(data.endStr)
      endDate.setDate(endDate.getDate() - 1);
      data.endStr = endDate.toISOString().substring(0, 10)
      data.endStr += " " + defaultCheckOut

      this.popoverActionBtnText = "Skapa ny period"
      this.bookingPeriod = {
        name: "",
        active: 1,
        notes: "",
        customers: null,
        calendarEvent: {
          start: data.startStr,
          end: data.endStr,
        },
        bookingObject: null,
        status: 1,
        other: ""
      }
      this.popoverReadOnly = false
      this.showPopover(data.jsEvent)
    },
    calendarRangeUnselected()
    {
      this.popoverShown = false
      this.collidingDates = []
    },
    async eventClick(eventClickInfo)
    {
      this.popoverActionBtnText = "Gå till period"
      this.popoverReadOnly = true
      eventClickInfo.jsEvent.preventDefault(); // don't let the browser navigate

      let calendarEventWithPeriods = eventClickInfo.event
      Object.assign(this.bookingPeriod, calendarEventWithPeriods.extendedProps.bookingPeriods[0]);
      Object.assign(this.bookingPeriod.calendarEvent, {
        start: new Date(calendarEventWithPeriods.start).toISOString(),
        end: new Date(calendarEventWithPeriods.end).toISOString(),
        link: calendarEventWithPeriods.link
      });

      await this.getBookingPeriodCustomers()
      this.showPopover(eventClickInfo.jsEvent)
    },
    async getBookingPeriodsEvents(fetchInfo, successCallback)
    {
      let serverResponse = await api.getFromEndpoint(api.ENDPOINTS.USERACCOUNTS + "/" + this.$store.state.user.id + "/" + api.ENDPOINTS.BOOKINGPERIODS + '/' + api.ENDPOINTS.CALENDAREVENTS + '?start=' + fetchInfo.startStr + '&end=' + fetchInfo.endStr)
      if (serverResponse)
      {
        successCallback(serverResponse)
      }
      else
      {
        this.$store.commit('setSnackbar', {color: "error", text: "Kunde inte hämta in uthyrningsperioder"})
      }
    },
    refetchEvents()
    {
      this.$refs.calendarComponent.refetchEvents()
    },
    goToDate(date)
    {
      this.$refs.calendarComponent.goToDate(date)
    },
    async checkDateCollision()
    {
      this.collidingDates = []
      let serverResponse = await api.getFromEndpoint(api.ENDPOINTS.BOOKINGPERIOD + "/" + this.bookingPeriod.bookingObject.id + "/" + api.ENDPOINTS.CALENDAREVENTS + '?start=' + this.bookingPeriod.calendarEvent.start + '&end=' + this.bookingPeriod.calendarEvent.end)
      if (serverResponse && serverResponse.length > 0)
      {
        this.collidingDates = serverResponse
      }
    },
    // make sure end is set after start and correct if necessary
    checkAndSetEndAfterStart()
    {
      if (!this.$moment(this.bookingPeriod.calendarEvent.end).isAfter(this.$moment(this.bookingPeriod.calendarEvent.start)))
      {
        // end is before start -> set end to start + 1 hour
        this.bookingPeriod.calendarEvent.end = this.$moment(this.bookingPeriod.calendarEvent.start).add(1, "hours").format("YYYY-MM-DD HH:mm")
        this.$store.commit('setSnackbar', {
          color: "secondary",
          text: "Utcheckning måste vara efter incheckning. Ändrade automatiskt utcheckningen till en timme efter incheckning.",
          timeout: 7000
        })
      }
    },
    setStartEndDefaults()
    {
      this.bookingPeriod.calendarEvent.start = this.$moment(this.bookingPeriod.calendarEvent.start).format("YYYY-MM-DD") + " " + this.bookingPeriod.bookingObject.defaultCheckInTime
      this.bookingPeriod.calendarEvent.end = this.$moment(this.bookingPeriod.calendarEvent.end).format("YYYY-MM-DD") + " " + this.bookingPeriod.bookingObject.defaultCheckOutTime
    },
    navigateToPeriod()
    {
      if (validateForm(this.$refs.form))
      {
        if (this.bookingPeriod.id && this.bookingPeriod.id > 0)
        {
          this.$router.push({name: 'bookingPeriodDetails', params: {id: this.bookingPeriod.id}})
        }
        else
        {
          this.$router.push({
            name: 'newBookingPeriod',
            params: {
              start: this.bookingPeriod.calendarEvent.start,
              end: this.bookingPeriod.calendarEvent.end,
              bookingObject: this.bookingPeriod.bookingObject
            }
          })
        }
      }
    },
    async getBookingPeriodCustomers()
    {
      let serverResponseCustomers = await api.getFromEndpoint(api.ENDPOINTS.BOOKINGPERIODS + "/" + this.bookingPeriod.id + "/" + api.ENDPOINTS.CUSTOMERS)
      if (serverResponseCustomers)
      {
        this.bookingPeriod.customers = serverResponseCustomers
      }
    }
    // TODO - event resizing and dragging (set editable to true)
  }
}
</script>

<style scoped>
.calendar-container {
  transition: all 0.3s;
  min-height: 500px;
}
</style>
