<template>
  <div>
    <v-container fill-height grid-list-xl>
      <v-card :flat="flat" :outlined="flat" width="100%">
        <v-toolbar color="secondary" dark>
          <v-toolbar-title class="text-center">Dynamiska dokument</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-menu v-model="addNew" bottom left nudge-bottom="5" offset-y transition="slide-y-transition">
            <template v-slot:activator="{ on }">
              <v-btn v-on="on"
                     color="primary"
                     fab
                     small
              >
                <v-icon>{{ addNew ? 'mdi-close' : 'mdi-plus' }}</v-icon>
              </v-btn>
            </template>
            <v-list class="pa-0">
              <v-list-item @click="templateSelectDialog = true">
                <v-list-item-title>Skapa ny från mall</v-list-item-title>
                <v-list-item-action>
                  <v-icon color="primary">mdi-content-duplicate</v-icon>
                </v-list-item-action>
              </v-list-item>
              <v-divider></v-divider>
              <v-list-item @click="newDynamicDocument(null)">
                <v-list-item-title>Skapa ny från grunden</v-list-item-title>
                <v-list-item-action>
                  <v-icon color="accent">mdi-newspaper-plus</v-icon>
                </v-list-item-action>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-toolbar>
        <dynamic-documents-list-component admin-view show-updated-at
                                          @itemClick="editDynamicDocument"></dynamic-documents-list-component>
      </v-card>
    </v-container>
    <dynamic-document-template-picker-dialog-component :dialog.sync="templateSelectDialog"
                                                       @submit="newDynamicDocument"></dynamic-document-template-picker-dialog-component>
  </div>
</template>

<script>
import DynamicDocumentTemplatePickerDialogComponent
  from "../../components/dynamicdocument/DynamicDocumentTemplatePickerDialogComponent";
import DynamicDocumentsListComponent from "@/components/dynamicdocument/DynamicDocumentsListComponent";

export default {
  components: {DynamicDocumentsListComponent, DynamicDocumentTemplatePickerDialogComponent},
  props: {
    flat: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    loading: true,
    addNew: false,
    templateSelectDialog: false
  }),
  methods: {
    newDynamicDocument(templateId)
    {
      let route = {
        name: 'newDynamicDocument'
      }
      if (templateId && templateId >= 0)
      {
        route.query = {
          'templateId': templateId
        }
      }
      this.$router.push(route)
    },
    editDynamicDocument(item)
    {
      this.$router.push({
        name: 'editDynamicDocument', params: {id: item.id}
      })
    },
    getFormattedDate(date)
    {
      return this.$moment(date).calendar()
    },
  },
  computed: {}
}
</script>

<style scoped>

</style>
