<template>
  <v-menu
      v-model="active"
      :position-x="xPos"
      :position-y="yPos"
      absolute
      offset-y
      transition="slide-y-transition"
      min-width="400"
  >
    <v-list v-if="website">
      <v-list-item two-line>
        <v-list-item-title>
          {{ generatedHref }}
        </v-list-item-title>
        <v-list-item-action v-if="website.published">
          <v-chip label style="width: 125px" outlined>Publicerad</v-chip>
        </v-list-item-action>
      </v-list-item>
      <v-divider></v-divider>
      <v-list-item @click="editWebsite">
        <v-list-item-title>Redigera</v-list-item-title>
        <v-list-item-icon>
          <v-icon color="primary">mdi-table-edit</v-icon>
        </v-list-item-icon>
      </v-list-item>
      <v-list-item @click="duplicateWebsite">
        <v-list-item-title>Duplicera</v-list-item-title>
        <v-list-item-icon>
          <v-icon color="secondary">mdi-plus-circle-multiple-outline</v-icon>
        </v-list-item-icon>
      </v-list-item>
      <v-list-item @click="confirmChangePublishStatus">
        <v-list-item-title>{{ website.published ? 'Avpublicera' : 'Publicera' }}</v-list-item-title>
        <v-list-item-icon>
          <v-icon :color="website.published ? 'accent' : 'success'">
            {{ website.published ? 'mdi-eye-off-outline' : 'mdi-eye-outline' }}
          </v-icon>
        </v-list-item-icon>
      </v-list-item>
      <v-list-item @click="confirmDelete">
        <v-list-item-title>Radera</v-list-item-title>
        <v-list-item-icon>
          <v-icon color="error">mdi-delete-forever-outline</v-icon>
        </v-list-item-icon>
      </v-list-item>
      <div v-if="website.published">
        <v-divider></v-divider>
        <v-list>
          <v-list-item :href="smsHref">
            <v-list-item-icon>
              <v-icon color="primary">mdi-cellphone-message</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Dela via sms</v-list-item-title>
          </v-list-item>
          <v-list-item :href="mailToHref">
            <v-list-item-icon>
              <v-icon color="accent">mdi-email-send-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Dela via e-post</v-list-item-title>
          </v-list-item>
        </v-list>
      </div>
    </v-list>
  </v-menu>
</template>

<script>

import api from "@/api/api";

export default {
  components: {},
  data: () => ({}),
  props: {
    website: Object,
    bookingObject: Object,
    xPos: Number,
    yPos: Number,
    value: Boolean
  },
  computed: {
    active: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('update:value', val)
      }
    },
    mailToHref() {
      return encodeURI(`mailto:?&subject=Boka ${this.bookingObject.name}!&body=Hej! \nAnvänd denna länk för att boka: ${this.generatedHref}`)
    },
    smsHref() {
      return encodeURI(`sms:?&body=Hej!\nBoka ${this.bookingObject.name} med följande länk: ${this.generatedHref}`)
    },
    generatedHref() {
      return `${api.BASE_URL_FRONTEND}boka/${this.website.url}`
    }
  },
  methods: {
    editWebsite() {
      this.$emit('edit')
    },
    duplicateWebsite() {
      this.$emit('duplicate')
    },
    confirmChangePublishStatus() {
      let actionTxt = this.website.published ? 'avpublicera' : 'publicera'
      let titleTxt = this.website.published ? 'avpublicering' : 'publicering'

      this.$store.commit('setDialog', {
        active: true,
        closeBtnText: "Avbryt",
        actionBtnText: actionTxt,
        title: "Bekräfta " + titleTxt,
        text: `Är du säker på att du vill ${actionTxt} denna webbplats? Notera att vid publicering så kommer den nuvarande publicerade webbplatsen att avpubliceras`,
        actionClick: this.changePublishStatus
      })
    },
    changePublishStatus() {
      this.$emit('changePublished')
    },
    confirmDelete() {
      this.$store.commit('setDialog', {
        active: true,
        closeBtnText: "Avbryt",
        actionBtnText: "Ta bort",
        title: "Bekräfta borttagning",
        text: `Är du säker på att du vill radera denna webbplats? Detta går inte att ångra. Notera att om webbplatsen
         är publicerad så kommer den att avpubliceras och en annan eller ny webbplats måste publiceras`,
        actionClick: this.delete,
      })
    },
    delete() {
      this.$emit('delete')
    }
  }
}
</script>
