<template>
    <v-container fill-height fluid>
        <v-layout align-center justify-center>
            <v-flex md6 sm8 xs12>
                <v-card class="elevation-10 pb-3" v-show="loaded">
                    <v-img :src="require('../../assets/404.jpg')"></v-img>
                    <v-card-title class="primary--text">
                        <h2>Hoppsan!</h2>
                    </v-card-title>
                    <v-card-text>
                        Kunde inte hitta den begärda sidan. Länken du följde ledde dig till en sida som
                        inte
                        finns
                    </v-card-text>
                    <v-card-actions>
                        <v-btn class="ml-2" color="secondary" v-bind="btnProps" v-if="showHomeBtn">Gå till hem</v-btn>
                    </v-card-actions>
                </v-card>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
    export default {
        name: "PageNotFoundComponent",
        data: () => ({
            loaded: false
        }),
        mounted()
        {
            this.loaded = true
        },
        computed: {
            btnProps()
            {
                if (this.routerless)
                {
                    return {
                        href: "/"
                    }
                }
                return {
                    to: "/"
                }
            }
        },
        props: {
            routerless: Boolean,
            showHomeBtn: {
                type: Boolean,
                default: true
            }
        }
    }
</script>

<style scoped>
    .img-notFound {
        position: absolute;
        right: 8%;
        top: 10%;
        width: 130px;
    }
</style>
