const STATUSES = [{
    text: "Avbokad", value: 0, color: "error", icon: "mdi-calendar-remove"
}, {
    text: "Ledig", value: 1, color: "success", icon: "mdi-calendar-search"
}, {
    text: "Förfrågad", value: 2, color: "info", icon: "mdi-calendar-question"
}, {
    text: "Uthyrd", value: 3, color: "accent", icon: "mdi-calendar-check"
}, {
    text: "Avslutad", value: 4, color: "grey", icon: "mdi-calendar-remove"
}];

const STATUSES_OBJ = {
    CANCELLED: {
        text: "Avbokad", value: 0, color: "error", icon: "mdi-calendar-remove"
    }, OPEN: {
        text: "Ledig", value: 1, color: "success", icon: "mdi-calendar-search"
    }, REQUESTED: {
        text: "Förfrågad", value: 2, color: "info", icon: "mdi-calendar-question"
    }, BOOKED: {
        text: "Uthyrd", value: 3, color: "accent", icon: "mdi-calendar-check"
    }, COMPLETED: {
        text: "Avslutad", value: 4, color: "grey", icon: "mdi-calendar-remove"
    }
};

export default {
    STATUSES, STATUSES_OBJ
}
