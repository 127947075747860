<template>
  <v-card
      flat
      :disabled="loading"
      :loading="loading"
  >
    <v-card-title>
      <v-icon
          large
          left
          color="primary"
      >
        mdi-currency-usd
      </v-icon>
      <span class="title textfont-weight-light primary--text">{{ title }}</span>
    </v-card-title>
    <v-sheet
        class="v-sheet--offset mx-auto"
        max-width="calc(100% - 32px)"
    >
      <v-sparkline
          :labels="incomeStatistics.labels"
          :value="incomeStatistics.values"
          color="primary"
          line-width="2"
          padding="16"
          auto-draw
      ></v-sparkline>
    </v-sheet>
    <v-card-text class="display-1 text-center font-weight-bold primary--text">
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <div v-on="on">{{ incomeStatistics.totalYearlyIncome }}</div>
        </template>
        <span>Total inkomst över hela året</span>
      </v-tooltip>
    </v-card-text>
    <v-card-actions>
      <v-list-item class="grow">
        <v-list-item-content>
          <v-list-item-title>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon color="primary" class="mr-1" v-on="on">mdi-tag-outline</v-icon>
                <span>{{ incomeStatistics.averagePricePerPeriod }}</span>
              </template>
              <span>Genomsnittligt pris per uthyrd period</span>
            </v-tooltip>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-card-actions>
  </v-card>
</template>

<script>
import api from "../../api/api";

export default {
  name: "IncomeStatisticsComponent",
  data: () => ({
    loading: false,
    incomeStatistics: {
      labels: [],
      values: [],
      totalYearlyIncome: "Laddar...",
      averagePricePerPeriod: "0 SEK",
    }
  }),
  computed: {
    title()
    {
      return "Inkomster - " + this.$moment().year()
    }
  },
  mounted()
  {
    this.getIncomeStatistics()
  },
  methods: {
    async getIncomeStatistics()
    {
      this.loading = true
      let serverResponse = await api.getFromEndpoint(api.ENDPOINTS.USERACCOUNTS + "/" + this.$store.state.user.id + "/" + api.ENDPOINTS.STATISTICS + "/" + api.ENDPOINTS.INCOME)
      if (serverResponse)
      {
        this.incomeStatistics = serverResponse
        this.setMonthLabelToString(serverResponse)
      }
      this.loading = false
    },
    setMonthLabelToString()
    {
      this.incomeStatistics.labels = this.incomeStatistics.labels.map((label) =>
      {
        return this.$moment().month(label).format("MMM")
      })
    }
  }
}
</script>

<style scoped>

</style>
