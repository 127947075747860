<template>
    <div>
        <v-icon color="primary" style="position: absolute; left: 0; padding-top: 10px;" v-if="prependIcon">mdi-map-marker</v-icon>
        <v-expansion-panels :value="openedIndex" :class="{'pl-8': prependIcon}" :flat="flat" >
            <v-expansion-panel :class="{'outlined': outlined}">
                <v-expansion-panel-header>
                <span>
                    <v-icon color="primary" left v-if="!prependIcon">mdi-map-marker</v-icon>
                    Adress
                </span>
                </v-expansion-panel-header>
                <v-expansion-panel-content :class="{'elevation-0': true}">
                    <div v-if="readOnly">
                        <v-list two-line>
                            <v-list-item v-if="address.address1.length > 0">
                                <v-list-item-icon>
                                    <v-icon color="primary">mdi-format-list-numbered</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-subtitle>Adressrad 1</v-list-item-subtitle>
                                    <v-list-item-title>{{address.address1}}</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item v-if="address.address2.length > 0">
                                <v-list-item-icon>
                                    <v-icon color="primary">mdi-format-list-numbered</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-subtitle>Adressrad 2</v-list-item-subtitle>
                                    <v-list-item-title>{{address.address2}}</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item v-if="address.address3.length > 0">
                                <v-list-item-icon>
                                    <v-icon color="primary">mdi-format-list-numbered</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-subtitle>Adressrad 3</v-list-item-subtitle>
                                    <v-list-item-title>{{address.address3}}</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-divider inset></v-divider>
                            <v-list-item v-if="address.postcode.length > 0">
                                <v-list-item-icon>
                                    <v-icon color="primary">mdi-mailbox-outline</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-subtitle>Postnummer</v-list-item-subtitle>
                                    <v-list-item-title>{{address.postcode}}</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item v-if="address.city.length > 0">
                                <v-list-item-icon>
                                    <v-icon color="primary">mdi-city</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-subtitle>Stad</v-list-item-subtitle>
                                    <v-list-item-title>{{address.city}}</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item v-if="address.country.length > 0">
                                <v-list-item-icon>
                                    <v-icon color="primary">mdi-flag</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-subtitle>Land</v-list-item-subtitle>
                                    <v-list-item-title>{{address.country}}</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                    </div>
                    <div v-else>
                        <v-text-field
                                :counter="100"
                                @keyup.enter="saveObject"
                                autocomplete="address-line1"
                                label="Adressrad 1"
                                @keyup="$emit('keyup')"
                                prepend-icon="mdi-format-list-numbered"
                                v-model="address.address1"
                                data-cy="address1Field"
                        ></v-text-field>
                        <v-text-field
                                :counter="100"
                                @keyup.enter="saveObject"
                                autocomplete="address-line2"
                                label="Adressrad 2"
                                @keyup="$emit('keyup')"
                                prepend-icon="mdi-format-list-numbered"
                                v-model="address.address2"
                                data-cy="address2Field"
                        ></v-text-field>
                        <v-text-field
                                :counter="100"
                                @keyup.enter="saveObject"
                                autocomplete="off"
                                @keyup="$emit('keyup')"
                                label="Adressrad 3"
                                prepend-icon="mdi-format-list-numbered"
                                v-model="address.address3"
                                data-cy="address3Field"
                        ></v-text-field>
                        <v-text-field
                                :counter="100"
                                @keyup.enter="saveObject"
                                autocomplete="postal-code"
                                label="Postnummer"
                                @keyup="$emit('keyup')"
                                prepend-icon="mdi-mailbox-outline"
                                v-model="address.postcode"
                                data-cy="postcodeField"
                        ></v-text-field>
                        <v-text-field
                                :counter="100"
                                @keyup.enter="saveObject"
                                autocomplete="address-level2"
                                label="Stad"
                                @keyup="$emit('keyup')"
                                prepend-icon="mdi-city"
                                v-model="address.city"
                                data-cy="cityField"
                        ></v-text-field>
                        <v-text-field
                                :counter="100"
                                @keyup.enter="saveObject"
                                autocomplete="country"
                                label="Land"
                                @keyup="$emit('keyup')"
                                prepend-icon="mdi-flag"
                                v-model="address.country"
                                data-cy="countryField"
                        ></v-text-field>
                    </div>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
    </div>
</template>

<script>
    export default {
        methods: {
            saveObject()
            {
                // Will emit on keyUpEnter - used in admin forms to save an object with the address
                this.$emit('keyUpEnter', this.address)
            }
        },
        props: {
            address: Object,
            openedIndex: Number, // 0 = closed, 1 = opened
            readOnly: Boolean,
            prependIcon: Boolean,
            flat: Boolean,
            outlined: Boolean,
        }
    }
</script>
