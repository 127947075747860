<template>
  <div>
    <v-container grid-list-xl>
      <v-layout class="pb-8" row wrap>
        <v-flex xs12 class="pt-0 mb-3">
          <payment-help-component></payment-help-component>
        </v-flex>
        <v-flex xs12>
          <v-text-field
              :rules="[v => Number.parseInt(v) >= 0 || 'Du måste ange ett standardpris']"
              @keyup.enter="saveBookingObject"
              prepend-icon="mdi-credit-card-outline"
              autocomplete="off"
              class="mb-3"
              label="Standardpris per period"
              @keyup="unsaved = true"
              min="0"
              outlined
              required
              suffix="SEK"
              type="number"
              hint="Här anges standardpriset per period"
              v-model="bookingObject.defaultPricePerPeriod"
          ></v-text-field> <!-- TODO - i18n for currency -->
          <v-textarea
              auto-grow
              label="Betalningsinformation"
              outlined
              rows="3"
              prepend-icon="mdi-smart-card-outline"
              v-model="bookingObject.paymentInfo"
              hint="Ange betalningsinformation här som sedan kan användas i dynamiska dokument eller e-postutskick"
          ></v-textarea>
          <v-subheader>
            Betalningspunkter
          </v-subheader>
          <v-simple-table fixed-header height="300px">
            <template v-slot:default>
              <thead>
              <tr>
                <th class="text-left">Namn</th>
                <th class="text-left">Förfallotyp</th>
                <th class="text-right">Förskjutning - förfallo</th>
                <th class="text-left">Förfrågningstyp</th>
                <th class="text-right">Förskjutning - förfrågning</th>
                <th class="text-right">Procentandel</th>
                <th class="text-right">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn
                          :class="{'highlight-pulse': isPaymentPointsEmpty && bookingObject.defaultPricePerPeriod != 0}"
                          @click="newPaymentPoint"
                          color="primary" outlined small
                          :disabled="bookingObject.defaultPricePerPeriod == 0"
                          v-on="on">
                        <v-icon>add</v-icon>
                      </v-btn>
                    </template>
                    <span>Lägg till en betalningspunkt</span>
                  </v-tooltip>
                </th>
              </tr>
              </thead>
              <tbody>
              <tr class="no-hover" v-if="isPaymentPointsEmpty">
                <td colspan="7">
                  <no-data-component class="mt-8"
                                     text="Inga betalningspunkter inlagda. Vi rekommenderar att du lägger till standard betalningspunkter för handpenning och
                      slutbetalning med knappen nedan eller använd plus-knappen ovan till höger för att lägga till dina egna betalningspunkter"></no-data-component>
                  <div class="text-center">
                    <p></p>
                    <v-btn
                        text
                        color="primary"
                        @click="createStandardPaymentPoints"
                        :disabled="bookingObject.defaultPricePerPeriod == 0"
                    >
                      Skapa standard betalningspunkter
                    </v-btn>
                  </div>
                </td>
              </tr>
              <tr :key="'paymentpoint_' + index + item.dueDateType + item.percentage"
                  @click="selectPaymentPoint(item, true, index)" class="clickable"
                  v-for="(item, index) in paymentPoints">
                <td class="font-weight-medium">{{ item.name }}</td>
                <td>{{ item.dueDateType | typeString(paymentPointStrings) }}</td>
                <td class="text-right">{{ item.dueDateOffset }}</td>
                <td>{{ item.scheduleDateType | typeString(paymentPointStrings) }}</td>
                <td class="text-right">{{ item.scheduleDateOffset }}</td>
                <td class="text-right">{{ `${item.percentage}%` }}
                </td>
                <td></td> <!-- empty for new button -->
              </tr>
              </tbody>
            </template>
          </v-simple-table>
          <v-divider></v-divider>
          <p class="subtitle-1 mt-3">
            <transition name="slide-x-transition">
              <v-chip :color="sumColor" :input-value="totalAmountIsValid" filter label
                      v-if="!isPaymentPointsEmpty"><b>Totalt:</b> <span
                  class="ml-1">{{ totalAmount }} / {{ bookingObject.defaultPricePerPeriod }}</span>
              </v-chip>
            </transition>
          </p>
          <v-alert
              :value="!isPaymentPointsEmpty && !totalAmountIsValid"
              border="left"
              colored-border
              dense
              elevation="2"
              icon="mdi-arrow-up"
              transition="slide-y-transition"
              type="error">
            Den totala summan av betalningspunkterna måste matcha priset per period
            <v-icon class="float-right" color="error" v-if="$vuetify.breakpoint.mdAndUp">mdi-alert</v-icon>
          </v-alert>
        </v-flex>
      </v-layout>
    </v-container>
    <v-dialog max-width="800px" persistent v-model="paymentPointDialog">
      <v-card>
        <v-card-title>
          {{ dialogTitle }}
          <v-spacer></v-spacer>
          <v-icon color="primary">mdi-credit-card-clock-outline</v-icon>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="mt-3">
          <v-form ref="paymentForm" v-if="selectedPaymentPoint" v-model="pointsValid">
            <v-text-field
                autocomplete="off"
                label="Namn"
                placeholder="Namn"
                solo
                persistent-hint
                hint="(Frivilligt) Exempelvis: Handpenning"
                v-model="selectedPaymentPoint.name"
            ></v-text-field>
            <v-subheader class="pl-0 ml-n2">
              <v-icon color="primary">mdi-currency-usd</v-icon>
              Belopp
            </v-subheader>
            <v-text-field
                :rules="[v => !!v && v > 0 || 'Beloppet måste vara över 0 - Ändra procentsatsen i fältet nedan']"
                autocomplete="off"
                min="1"
                readonly
                style="pointer-events: none;"
                required
                tabindex="-1"
                filled
                suffix="SEK"
                rounded
                type="number"
                v-model="selectedPaymentPoint.amount"
            ></v-text-field> <!-- TODO - i18n for currency -->
            <v-text-field
                :rules="[v => !!v && v > 0 && v <= 100 || 'Du måste ange en procentsats mellan 1-100%']"
                autocomplete="off"
                class="mb-3"
                hint="Procentuellt belopp av standardpriset per period"
                label="Procentsats"
                max="100"
                min="1"
                persistent-hint
                required
                suffix="%"
                type="number"
                v-model="selectedPaymentPoint.percentage"
            ></v-text-field>
            <v-divider></v-divider>
            <v-list-item @click="selectedPaymentPoint.scheduleEnabled = !selectedPaymentPoint.scheduleEnabled"
                         class="pl-0 mt-4">
              <v-list-item-title>
                <v-icon left color="primary">mdi-calendar-question</v-icon>
                Förfrågningsdatum
              </v-list-item-title>
              <v-list-item-action>
                <v-switch readonly v-model="selectedPaymentPoint.scheduleEnabled"></v-switch>
              </v-list-item-action>
            </v-list-item>
            <v-card flat :disabled="!selectedPaymentPoint.scheduleEnabled">
              <v-select :items="scheduleDateTypes" class="mb-3"
                        hint="Vilket ursprungsdatum som automatiskt utskick av betalningsförfrågan ska utgå ifrån"
                        label="Ursprungsdatum" persistent-hint
                        v-model="selectedPaymentPoint.scheduleDateType"></v-select>
              <v-text-field
                  :rules="scheduleDateOffsetRule"
                  autocomplete="off"
                  :disabled="!selectedPaymentPoint.scheduleEnabled"
                  class="mb-3"
                  hint="Ange antal dagar före (negativt värde) eller efter ursprungsdatumet som betalningsförfrågan skall skickas till kund."
                  label="Förskjutning"
                  persistent-hint
                  required
                  suffix="Dagar"
                  type="number"
                  v-model="selectedPaymentPoint.scheduleDateOffset"
              ></v-text-field>
            </v-card>
            <v-subheader class="pl-0">
              <v-icon class="mr-1" color="primary">mdi-calendar-clock</v-icon>
              Förfallodatum
            </v-subheader>
            <v-select :items="dueDateTypes" class="mb-3"
                      hint="Vilket ursprungsdatum som förfallodatum på betalningspunkten ska utgå ifrån"
                      label="Ursprungsdatum" persistent-hint
                      v-model="selectedPaymentPoint.dueDateType"></v-select>
            <v-text-field
                :rules="dueDateOffsetRule"
                autocomplete="off"
                class="mb-6"
                hint="Ange antal dagar före (negativt värde) eller efter ursprungsdatumet som förfallodatum på betalningspunkten ska förskjutas."
                label="Förskjutning"
                persistent-hint
                required
                suffix="Dagar"
                type="number"
                v-model="selectedPaymentPoint.dueDateOffset"
            ></v-text-field>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn @click="confirmDeletePaymentPoint" color="error" text v-if="editing">Ta bort</v-btn>
          <v-btn @click="paymentPointDialog = false" text v-if="!editing">Stäng</v-btn>
          <v-spacer></v-spacer>
          <v-btn @click="addPaymentPoint" color="primary" text>Ok</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import paymentPointTypes from "../../../models/paymentPointTypes";
import NoDataComponent from "../../global/NoDataComponent";
import PaymentHelpComponent from "@/components/bookingobject/payment/PaymentHelpComponent";

export default {
  components: {PaymentHelpComponent, NoDataComponent},
  data: () => ({
    pointsValid: true,
    paymentPointDialog: false,
    selectedPaymentPoint: null,
    dueDateTypes: paymentPointTypes.dueDateTypesArray,
    scheduleDateTypes: paymentPointTypes.scheduleDateTypesArray,
    paymentPointStrings: paymentPointTypes.typesString,
    editing: false,
  }),
  props: {
    bookingObject: Object,
  },
  filters: {
    typeString(type, paymentPointStrings)
    {
      return paymentPointStrings[type]
    },
  },
  watch: {
    selectedPaymentPoint: {
      handler(val)
      {
        if (val.percentage && parseInt(val.percentage) > 0 && parseInt(val.percentage) <= 100)
        {
          let amount = this.getAmountFromPercentage(val.percentage)
          if (amount > 0)
          {
            this.selectedPaymentPoint.amount = amount
          }
        }
        // if schedule is enabled we set values to default or to the stored values if they are set
        if (val.scheduleEnabled)
        {
          val.scheduleDateOffset = val.scheduleDateOffset ? val.scheduleDateOffset.toString() : "-5"
          val.scheduleDateType = val.scheduleDateType ? val.scheduleDateType : paymentPointTypes.types.DUE_DATE
        }
        else if (!val.scheduleEnabled) // otherwise just null them
        {
          val.scheduleDateType = null
          val.scheduleDateOffset = null
        }
      },
      deep: true
    }
  },
  computed: {
    sumColor()
    {
      return this.totalAmountIsValid ? "success" : "error"
    },
    isPaymentPointsEmpty()
    {
      return this.paymentPoints == null || this.paymentPoints.length === 0
    },
    dialogTitle()
    {
      return this.editing ? "Redigerar betalningspunkt" : "Ny betalningspunkt"
    },
    dueDateOffsetRule()
    {
      return [
        v => !!v || 'Du måste ange ett värde! (Tips: 0 dagar är ett giltigt värde)',
      ]
    },
    scheduleDateOffsetRule()
    {
      return [
        v => !this.selectedPaymentPoint.scheduleEnabled || !!v || 'Du måste ange ett värde! (Tips: 0 dagar är ett giltigt värde)',
      ]
    },
    paymentPoints: {
      get()
      {
        return this.bookingObject.paymentPoints
      },
      set(val)
      {
        this.bookingObject.paymentPoints = val
      }
    },
    totalAmount()
    {
      let total = 0
      this.paymentPoints.forEach((p) =>
      {
        total += parseInt(this.getAmountFromPercentage(p.percentage))
      })
      return total
    },
    totalAmountIsValid()
    {
      return this.totalAmount === parseInt(this.bookingObject.defaultPricePerPeriod)
    },
    unsaved: {
      get()
      {
        return this.unsavedChanges
      },
      set(val)
      {
        this.$emit('update:unsavedChanges', val)
      }
    },
  },
  methods: {
    getAmountFromPercentage(percentage)
    {
      return Math.floor((parseInt(percentage) / 100) * parseInt(this.bookingObject.defaultPricePerPeriod))
    },
    saveBookingObject()
    {
      this.$emit('saveBookingObject')
    },
    selectPaymentPoint(item, edit, index)
    {
      item.index = index
      if (edit)
      {
        this.$set(item, 'amount', this.getAmountFromPercentage(item.percentage))
        this.$set(item, 'dueDateOffset', item.dueDateOffset.toString()) // need to do this for validation to work
        this.$set(item, 'scheduleEnabled', !!item.scheduleDateOffset) // enable if there is a date set
      }
      this.selectedPaymentPoint = item
      this.editing = edit
      this.paymentPointDialog = true
    },
    createStandardPaymentPoints() {
      let deposit = {
        amount: this.getAmountFromPercentage(10),
        name: "Handpenning",
        percentage: 10,
        dueDateType: paymentPointTypes.types.BOOKED_AT_DATE,
        dueDateOffset: 10,
        scheduleEnabled: true,
        scheduleDateOffset: -5,
        scheduleDateType: paymentPointTypes.types.DUE_DATE
      }
      let finalPayment = {
        amount: this.getAmountFromPercentage(90),
        name: "Slutbetalning",
        percentage: 90,
        dueDateType: paymentPointTypes.types.CHECK_IN_DATE,
        dueDateOffset: -30,
        scheduleEnabled: true,
        scheduleDateOffset: -5,
        scheduleDateType: paymentPointTypes.types.DUE_DATE
      }
      this.unsaved = true

      this.paymentPoints.push(deposit)
      this.paymentPoints.push(finalPayment)
      if(this.$route.params.id > 0)
      {
        this.saveBookingObject()
      }

    },
    newPaymentPoint()
    {
      this.selectPaymentPoint(Object.assign({}, {
        amount: this.getAmountFromPercentage(1),
        percentage: 1,
        dueDateType: paymentPointTypes.types.BOOKED_AT_DATE,
        dueDateOffset: "0",
        scheduleEnabled: false,
      }), false)
      this.paymentPointDialog = true
    },
    addPaymentPoint()
    {
      let form = this.$refs.paymentForm
      if (form.validate())
      {
        this.unsaved = true

        if (!this.editing)
        {
          this.paymentPoints.push(this.selectedPaymentPoint)
        }
        this.paymentPointDialog = false
      }
    },
    confirmDeletePaymentPoint()
    {
      let index = this.selectedPaymentPoint.index
      this.$store.commit('setDialog', {
        active: true,
        closeBtnText: "Avbryt",
        actionBtnText: "Ta bort",
        title: "Bekräfta borttagning",
        text: `Är du säker på att du vill ta bort den här betalningspunkten?`,
        actionClick: () =>
        {
          this.deletePaymentPoint(index)
        }
      })
    },
    deletePaymentPoint(index)
    {
      this.paymentPointDialog = false
      this.paymentPoints.splice(index, 1)
      this.unsaved = true
    },
  }
}
</script>

<style scoped>
tr.no-hover {
  background: none !important;
}
</style>
