import { render, staticRenderFns } from "./BookingPeriodEconomyTabComponent.vue?vue&type=template&id=4c6aa981&scoped=true&"
import script from "./BookingPeriodEconomyTabComponent.vue?vue&type=script&lang=js&"
export * from "./BookingPeriodEconomyTabComponent.vue?vue&type=script&lang=js&"
import style0 from "./BookingPeriodEconomyTabComponent.vue?vue&type=style&index=0&id=4c6aa981&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4c6aa981",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VScaleTransition } from 'vuetify/lib/components/transitions';
import { VSlideXTransition } from 'vuetify/lib/components/transitions';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VAlert,VBtn,VCard,VContainer,VFlex,VIcon,VLayout,VScaleTransition,VSlideXTransition,VTextField,VTooltip})
