<template>
    <div v-if="$vuetify.breakpoint.smAndUp">
        <v-subheader class="pl-0">
            <v-icon color="primary" class="mr-2">mdi-calendar-clock</v-icon>
            Incheckning
            <v-spacer></v-spacer>
            Utcheckning
        </v-subheader>
        <div>
            <v-slide-x-transition mode="out-in">
                <v-text-field
                        placeholder="Incheckning"
                        type="time"
                        v-if="editingStart"
                        v-model="startTime"
                        style="max-width: 150px;"
                        class="float-left ma-0 pa-0"
                        hide-details
                >
                    <v-icon slot="prepend" @click="editingStart = false" color="grey">mdi-close</v-icon>
                    <v-icon slot="append" @click="saveStartTime" color="success">mdi-check</v-icon>
                </v-text-field>
                <v-btn :disabled="readOnly" v-else outlined small color="primary" @click="editStartTime">{{startStr}}</v-btn>
            </v-slide-x-transition>
            <v-slide-x-transition mode="out-in">
                <v-text-field
                        placeholder="Utcheckning"
                        type="time"
                        v-if="editingEnd"
                        v-model="endTime"
                        style="max-width: 150px;"
                        class="float-right pr-1 ma-0 pa-0"
                        hide-details
                >
                    <v-icon slot="prepend" @click="editingEnd = false" color="grey">mdi-close</v-icon>
                    <v-icon slot="append" @click="saveEndTime" color="success">mdi-check</v-icon>
                </v-text-field>
                <v-btn :disabled="readOnly" v-else outlined small color="primary" class="float-right pr-1" @click="editEndTime"><b>{{endStr}}</b></v-btn>
            </v-slide-x-transition>
        </div>
    </div>
    <div v-else>
        <v-subheader class="pl-0">
            <v-icon color="primary" class="mr-2">mdi-calendar-clock</v-icon>
            <p>Incheckning</p>
        </v-subheader>
        <v-slide-x-transition mode="out-in">
            <v-text-field
                    placeholder="Incheckning"
                    type="time"
                    v-if="editingStart"
                    v-model="startTime"
                    style="max-width: 150px;"
                    class="ma-0 pa-0"
                    hide-details
            >
                <v-icon slot="prepend" @click="editingStart = false" color="grey">mdi-close</v-icon>
                <v-icon slot="append" @click="saveStartTime" color="success">mdi-check</v-icon>
            </v-text-field>
            <v-btn :disabled="readOnly" v-else outlined small color="primary" @click="editStartTime">
                {{startStr}}
            </v-btn>
        </v-slide-x-transition>
        <v-subheader class="pl-0 mt-4">
            <v-icon color="primary" class="mr-2">mdi-calendar-clock</v-icon>
            <p>Utcheckning</p>
        </v-subheader>
        <v-slide-x-transition mode="out-in">
            <v-text-field
                    placeholder="Utcheckning"
                    type="time"
                    v-if="editingEnd"
                    v-model="endTime"
                    style="max-width: 150px;"
                    class="ma-0 pa-0"
                    hide-details
            >
                <v-icon slot="prepend" @click="editingEnd = false" color="grey">mdi-close</v-icon>
                <v-icon slot="append" @click="saveEndTime" color="success">mdi-check</v-icon>
            </v-text-field>
            <v-btn :disabled="readOnly" v-else outlined small color="primary"
                   @click="editEndTime"><b>{{endStr}}</b></v-btn>
        </v-slide-x-transition>
    </div>
</template>

<script>
    import moment from "moment";
    export default {
        data: () => ({
            editingStart: false,
            editingEnd: false,
            startTime: "",
            endTime: "",
        }),
        props: {
            bookingPeriod: Object,
            readOnly: Boolean
        },
        computed: {
            startStr()
            {

                return this.bookingPeriod.calendarEvent.start && this.bookingPeriod.calendarEvent.start.length > 0 ?
                    moment(this.bookingPeriod.calendarEvent.start).format("LLL")
                    : ""
            },
            endStr()
            {
                return this.bookingPeriod.calendarEvent.end && this.bookingPeriod.calendarEvent.end.length > 0 ?
                    moment(this.bookingPeriod.calendarEvent.end).format("LLL")
                    : ""
            },
        },
        methods: {
            editStartTime()
            {
                this.startTime = moment(this.bookingPeriod.calendarEvent.start).format("HH:mm")
                this.editingStart = true
            },
            saveStartTime()
            {
                this.editingStart = false
                if(this.startTime.length >= 5)
                {
                    this.bookingPeriod.calendarEvent.start = this.$moment(this.bookingPeriod.calendarEvent.start).format("YYYY-MM-DD") + " " + this.startTime
                    this.$emit("changed")
                }
            },
            editEndTime()
            {
                this.endTime = moment(this.bookingPeriod.calendarEvent.end).format("HH:mm")
                this.editingEnd = true
            },
            saveEndTime()
            {
                this.editingEnd = false
                if(this.endTime.length >= 5)
                {
                    this.bookingPeriod.calendarEvent.end = this.$moment(this.bookingPeriod.calendarEvent.end).format("YYYY-MM-DD") + " " + this.endTime
                    this.$emit("changed")
                }
            }
        }
    }
</script>

<style scoped>
    .theme--light.v-btn.v-btn--disabled {
        color: #8380b6 !important;
    }
</style>
