<template>
  <v-dialog max-width="800px" persistent scrollable v-model="dialogProp">
    <v-card :loading="loading">
      <v-card-title>
        Välj mall
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <p class="mt-10 mb-5 text-center grey--text" v-if="loading">Hämtar data...</p>
        <v-container fluid v-else>
          <v-item-group value="id" v-model="selectedTemplateIndex">
            <v-row>
              <v-col
                  :key="template.id"
                  cols="12"
                  md="6"
                  v-for="template in dynamicDocumentTemplates"
              >
                <dynamic-document-template-item-component
                    :template="template"></dynamic-document-template-item-component>
              </v-col>
            </v-row>
          </v-item-group>
        </v-container>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-btn @click="dialogProp = false" text>Stäng</v-btn>
        <v-spacer></v-spacer>
        <v-btn :disabled="selectedTemplateIndex == null" @click="submit"
               color="primary"
               text>Välj mall
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import api from "../../api/api";
import DynamicDocumentTemplateItemComponent from "./DynamicDocumentTemplateItemComponent";

export default {
  components: {DynamicDocumentTemplateItemComponent},
  data: () => ({
    dynamicDocumentTemplates: [],
    loading: false,
    selectedTemplateIndex: null,
  }),
  props: {
    dialog: Boolean,
  },
  computed: {
    dialogProp: {
      get: function () {
        return this.dialog;
      },
      set: function (newValue) {
        this.$emit('update:dialog', newValue)
      }
    },
  },
  mounted() {
    this.getDynamicDocumentTemplates()
  },
  methods: {
    async getDynamicDocumentTemplates() {
      this.loading = true
      let serverResponse = await api.getFromEndpoint(api.ENDPOINTS.DYNAMICDOCUMENTS)
      if (serverResponse) {
        this.dynamicDocumentTemplates = serverResponse
      }
      this.loading = false
    },
    submit() {
      this.$emit('submit', this.dynamicDocumentTemplates[this.selectedTemplateIndex].id)
      this.dialogProp = false
    }
  }
}
</script>
