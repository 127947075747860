<template>
  <v-card
      :disabled="loading"
      :loading="loading"
      flat
  >
    <v-card-title class="font-weight-light">
      <v-icon
          left
          color="accent"
      >
        mdi-calendar-outline
      </v-icon>
      <span>Kommande uthyrningsperioder</span>
      <v-spacer></v-spacer>
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" outlined color="primary" small icon @click="goToBookingPeriods">
            <v-icon small>mdi-open-in-new</v-icon>
          </v-btn>
        </template>
        <span>Visa alla uthyrningsperioder</span>
      </v-tooltip>
    </v-card-title>
    <v-card-text>
      <booking-periods-list-component :booking-periods="bookingPeriods"
                                      view-only-list></booking-periods-list-component>
    </v-card-text>
  </v-card>
</template>

<script>
import BookingPeriodsListComponent from "../bookingperiod/BookingPeriodsListComponent";
import api from "../../api/api";
import bookingPeriodStatuses from "../../models/bookingPeriodStatuses";

export default {
  components: {BookingPeriodsListComponent},
  data: () => ({
    bookingPeriods: [],
    loading: false,
  }),
  mounted()
  {
    this.getBookingPeriods()
  },
  props: {
    count: {
      type: Number,
      default: null
    }
  },
  computed: {
    periodsCount: {
      get()
      {
        return this.count
      },
      set(val) {
        this.$emit('update:count', val)
      }
    }
  },
  methods: {
    goToBookingPeriods()
    {
      this.$router.push({name: "bookingPeriods"})
    },
    async getBookingPeriods()
    {
      this.loading = true
      let serverResponse = await api.getFromEndpoint(api.ENDPOINTS.USERACCOUNTS + "/" + this.$store.state.user.id + "/" + api.ENDPOINTS.BOOKINGPERIODS +
          "?ignoreDisplayOptions=true&onlyFuturePeriods=true&includeCustomers=true&limit=10&excludeStatuses=" + bookingPeriodStatuses.STATUSES_OBJ.CANCELLED.value)
      if (serverResponse)
      {
        this.bookingPeriods = serverResponse
        this.periodsCount = serverResponse.length
      }
      this.loading = false
      let calComponent = this.$refs.calendarComponent
      if (calComponent)
      {
        calComponent.refetchEvents()
      }
    },
  }
}
</script>

<style scoped>

</style>
